import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RestApiResponse } from "@/types/common.api";
import { ScormVersion } from "@/constants/scorm";
import { ProjectForPublishScorm, PublishedScorm } from "@/types/scorm";

type ScormGetPublishedParams = {
  baseInfoId: string;
};

type ScormGetPublishedResponse = PublishedScorm;

type ScormGetVersionsParams = {
  baseInfoId: string;
};

type ScormGetVersionsResponse = ProjectForPublishScorm[];

type ScormPublishParams = {
  baseInfoId: string;
  projectId: string;
  scormVersion: ScormVersion;
};

type ScormRepublishParams = {
  baseInfoId: string;
  projectId: string;
};

type ScormUnpublishParams = {
  baseInfoId: string;
};

export const scormApi = createApi({
  reducerPath: "scorm",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/scorm",
  }),
  refetchOnFocus: false,
  refetchOnReconnect: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ["Scorm"],

  endpoints: (build) => ({
    getPublished: build.query<
      ScormGetPublishedResponse,
      ScormGetPublishedParams
    >({
      query: ({ baseInfoId }) => `/${baseInfoId}`,
      transformResponse: (res: RestApiResponse<ScormGetPublishedResponse>) => {
        if (!res.success || !res.data) {
          throw new Error("Failed to fetch published SCORM");
        }

        return res.data;
      },
      providesTags: ["Scorm"],
    }),

    getVersions: build.query<ScormGetVersionsResponse, ScormGetVersionsParams>({
      query: ({ baseInfoId }) => `/${baseInfoId}/versions`,
      transformResponse: (res: RestApiResponse<ScormGetVersionsResponse>) => {
        if (!res.success || !res.data) {
          throw new Error("Failed to fetch SCORM versions");
        }

        return res.data;
      },
      providesTags: ["Scorm"],
    }),

    publish: build.mutation<void, ScormPublishParams>({
      query: ({ baseInfoId, projectId, scormVersion }) => ({
        url: `/${baseInfoId}/publish`,
        method: "PUT",
        body: {
          projectId,
          scormVersion,
        },
      }),
      transformResponse: (res: RestApiResponse<void>) => {
        if (!res.success) {
          throw new Error("Failed to publish SCORM");
        }
      },
      invalidatesTags: ["Scorm"],
    }),

    republish: build.mutation<void, ScormRepublishParams>({
      query: ({ baseInfoId, projectId }) => ({
        url: `/${baseInfoId}/republish`,
        method: "PUT",
        body: {
          projectId,
        },
      }),
      transformResponse: (res: RestApiResponse<void>) => {
        if (!res.success) {
          throw new Error("Failed to republish SCORM");
        }
      },
      invalidatesTags: ["Scorm"],
    }),

    unpublish: build.mutation<void, ScormUnpublishParams>({
      query: ({ baseInfoId }) => ({
        url: `/${baseInfoId}/unpublish`,
        method: "PUT",
      }),
      transformResponse: (res: RestApiResponse<void>) => {
        if (!res.success) {
          throw new Error("Failed to unpublish SCORM");
        }
      },
      invalidatesTags: ["Scorm"],
    }),
  }),
});
