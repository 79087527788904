import { ProjectType, ScriptLengthPerLanguage } from "@/types/type";
import * as cheerio from 'cheerio'

export function removeByTagName(content: any, tagName: string) {
  // HTML 문자열을 파싱하여 DOM으로 변환
  const parser = new DOMParser();
  const doc = parser.parseFromString(content.toString(), 'text/html');

  // 내포된 tag 요소들을 모두 선택
  const tags = doc.querySelectorAll(tagName);
  tags.forEach((tag, index) => {
    tag.remove()
  });

  // 최종 변환된 HTML 문자열
  const finalHtmlString = doc.body.innerHTML;
  // console.log("!@#!@#!@#", finalHtmlString);
  return finalHtmlString
}


// 제스처 부분을 splitChar 로 변경하고 gestureIds 를 같이 전달
function replaceGesture(text: string) {
  const gestures = text.match(/<gesture([\sa-zA-Z0-9="'_-])*((\/>)|(>(\s|\n)*<\/gesture>)){1}/g);
  const gestureIds = [];
  const splitChar = `$(gesture)`;
  let retText = text;
  if (gestures) {
    for (const tag of gestures) {
      const idArea = tag.match(/gid=("|')+([a-zA-Z0-9_-])+("|')+/g);
      const strId = idArea ? idArea[0].match(/("|')+([a-zA-Z0-9_-])+("|')+/) : null;
      // console.log("!@#!@#!@# strId ", strId);
      if (strId) {
        gestureIds.push(strId[0].replace(/("|')/g, ""));
      }
      retText = retText.replace(tag, splitChar);    
    }
  }
  return {replacedText: retText, splitChar, gestureIds}
}


// 묵음 부분을 변경한다.
export function replaceSilent(text: string, duration: number = 0.1) {
  // <div id="0eecd05d-288f-4846-af79-0a902ffb48ae" class="pauseButton partialOpacity" data-wordtype="pause">0.5</div>
  const silentRex = /\<div[0-9a-zA-Z\s\=\"\-\_\n]+data-wordtype=\"pause\"[0-9a-zA-Z\s\=\"\-\_\n]*\>[0-9.]+\<\/div\>/;
  const silentValueRex = /\>[0-9.]+\</;
  let repText = text;
  let find = null;
  while ((find = repText.match(silentRex)) !== null) {
    const strData = find[0];
    const silent = strData.match(silentValueRex);
    if (silent) {
      const floatSilent = parseFloat(silent[0].replace(/[^0-9.]/g, ""));
      const silentCnt = Math.round(floatSilent / duration);
      let silentText = "";
      for (let idx = 0; idx < silentCnt; idx++) {
        silentText += "|.";
      }
      repText = repText.replace(strData, " "+silentText+" ");
    }
  }
  return repText;
}

// text escap 문자열 제거  <span id="${id}" class="partialOpacity pauseButton">0.5</span>
export function replaceText(text: string, dictionary:Map<string, string> | undefined | null = null) {
  // 문자열에서 제거하는 부분
  const regxs = [
    {reg: /\&nbsp;/g, to: " "},
    {reg: /\<\/p\>\<p\>/g, to: "</p> <p>"},
    {reg: /\<[0-9a-zA-Z\s\=\"\-\_\n]+\>|\<\/[0-9a-zA-Z\s\=\"\-\_\n]+\>|\<[0-9a-zA-Z\s\=\"\-\_\n]+\/>/g, to: ""}
  ];

  //묵음 문자열 처리
  let retText = replaceSilent(text);

  //제스처 문자열 처리
  // const { replacedText, splitChar, gestureIds }: {replacedText: string, splitChar: string, gestureIds: string[]} 
  // = replaceGesture(retText);
  // retText = replacedText;

  // 문자 변환
  if (dictionary) {
    for (const [key, value] of Object.entries(dictionary)) {
      if (value) {
        retText = retText.replaceAll(key.trim(), value);
      }
    }
  }

  //태그제거
  for (const row of regxs) {
    retText = retText.replace(row.reg, row.to);
  }

  //처리가 완료되면 제스처 대체문자를 제스처 태그로 다시 재변환
  // retText = retText.split(splitChar).reduce((acc, cur) => {
  //   if(gestureIds.length > 0) return acc += cur + `<gesture id="${gestureIds.shift()}" />`
  //   return acc += cur
  // }, "")

  return retText.trim();
}

export function pad(n:number|string, width:number) {
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

/**
 * @description 0 이상 0.5 미만의 소수점은 0으로 치환, 0.5 이상 1 미만의 소수점은 0.5로 치환
 * @returns 
 */
export function convertToNearestHalf(num: number) {
  const decimalPart = num - Math.floor(num); // 소수 부분을 얻습니다.
  
  // 소수 첫째 자리가 0 이상 5 미만인 경우 0으로, 5 이상인 경우 0.5로 변환합니다.
  if (decimalPart >= 0 && decimalPart < 0.5) {
    return Math.floor(num); // 0으로 변환
  } else if (decimalPart >= 0.5 && decimalPart < 1) {
    return Math.floor(num) + 0.5; // 0.5로 변환
  }
}


export const makeTextClean = (text: string): string => {
  let newText = replaceSilent(text);
  const regxs = [
    {reg: /\&nbsp;/g, to: " "},
    {reg: /\<[0-9a-zA-Z\s\=\"\-\_\n]+\>|\<\/[0-9a-zA-Z\s\=\"\-\_\n]+\>|\<[0-9a-zA-Z\s\=\"\-\_\n]+\/>/g, to: ""}
  ];

  for (const row of regxs) {
    newText = newText.replace(row.reg, row.to);
  }
  newText = newText.replace(/\|\./g, '');
  return newText;
}


export const getLanguage = (code: string, locale?: string) => {
  const locales = locale ? [locale] : ["en"];
  const intlLanguage = new Intl.DisplayNames(locales, { type: "language" });
  const intlRegion = new Intl.DisplayNames(locales, { type: "region" });
  const [codeLanguage, codeRegion] = code.split("-");

  const resultLanguage = (() => {
    try {
      return intlLanguage.of(codeLanguage);
    } catch {
      return codeLanguage;
    }
  })();
  const resultRegion = (() => {
    try {
      return intlRegion.of(codeRegion);
    } catch {
      return null;
    }
  })();

  return `${resultLanguage}${ resultRegion ? ` (${resultRegion})` : "" }`;
};

export const calculateScriptDuration = ({
  script = '',
  language = 'en',
  tempo = 1,
}: {
  script?: string
  language?: string
  tempo?: number
} = {}) => {
  let duration = 0

  const fixedTempo = typeof tempo === 'string' ? parseFloat(tempo) : tempo

  const regexArr = [
    /(?<=class="partialOpacity pauseButton">)(.*?)(?=\<\/span)/g,
    /<[^>]*>?/gm,
  ]

  const pauseArray = script.match(regexArr[0]) ?? []

  duration += (0.5 * pauseArray.length) / fixedTempo

  const filteredText = replaceText(script)

  const scriptLength = filteredText.length

  if (language === 'ko') {
    duration += Math.floor((scriptLength * 60) / 400)
  } else if (language === 'zh') {
    duration += Math.floor((scriptLength * 90) / 400)
  } else if (language === 'jp') {
    duration += Math.floor((scriptLength * 60) / 400)
  } else {
    duration += Math.floor((scriptLength * 30) / 400)
  }

  return duration
}

export const timeSelector = (project: ProjectType) => {
  if (!project) return 0;
  const scenes = project.scenes;
  const scripts: ScriptLengthPerLanguage = {
    ko: 0,
    en: 0,
    zh: 0,
    jp: 0,
  };
  let time = 0;

  for (const scene of scenes) {
    // const clips = scene.clips;
    const tempo = scene.tempo ? parseFloat(scene.tempo) : 1;
    for (const script of scene.scripts) {
      /**
       * script.tts.type === "audio"
       * 오디오 스크립트인 경우 영상 예상 길이에 duration 을 포함
       */
      if(script?.tts?.type === "audio") {
        const duration = script.tts.duration ?? 0;
        const trim: {
          start: number
          end: number
        }[] | undefined = script.tts.trim

        let calculatedDuration: number = duration

        /**
         * trim 이 존재하는 경우 trim 을 적용한 duration 을 계산
         */
        if (trim && trim.length > 0) {
          const minTime = 0
          const maxTime = duration

          calculatedDuration = [...trim]
            .sort((a, b) => a.start - b.start)
            .map((currentTrim, i, originalTrimList) => {
              const nextTrim = originalTrimList[i + 1]
              const nextStartTime = nextTrim ? nextTrim.start : maxTime

              const start = Math.max(currentTrim.start, minTime)
              const end = Math.max(
                Math.min(currentTrim.end, nextStartTime),
                start,
              )

              return {
                start,
                end,
              }
            })
            .reduce((acc, currentTrim) => {
              const { start, end } = currentTrim

              return acc + (end - start)
            }, 0)
        }

        time += Math.floor(calculatedDuration)

        continue;
      }

      const org = script?.org;
      if (!org) continue;

      const language = script.detectedLanguage as string ?? "en";

      const regexArr = [
        /(?<=class="partialOpacity pauseButton">)(.*?)(?=\<\/span)/g,
        /<[^>]*>?/gm,
      ];

      const pauseArray = org.match(regexArr[0]) ?? [];
      time += (0.5 * pauseArray.length) / tempo;

      let filteredText = replaceText(org);

      const scriptLength = filteredText.length;
      if (language in scripts) {
        scripts[language] = scripts[language] + scriptLength / tempo;
      } else {
        scripts.en = scripts.en + scriptLength / tempo;
      }
    }
  }

  Object.entries(scripts).forEach((entry) => {
    const [language, val] = entry;
    if (language === "ko") {
      time += Math.floor((val * 60) / 400);
    } else if (language === "zh") {
      time += Math.floor((val * 90) / 400);
    } else if (language === "jp") {
      time += Math.floor((val * 60) / 400);
    } else {
      time += Math.floor((val * 30) / 400);
    }
  });

  if (time < 1) time = 1;
  return time;
};

/**
 * gesture, pause 태그를 잠시 떼어내고 번역돌린후 다시 제자리에 끼워놓기 위한 텍스트 변환\
 * effect 태그도 추가함
 */ 
const splitChar = '++'
export const replaceTagOfScript = (script: string) => {
  const $ = cheerio.load(script)

  const matches: string[] = []

  $('gesture,.pauseButton[data-wordtype],multiplesendtag,effect').each(
    (_index, element) => {
      const matched = $.html(element)

      if (!matched) {
        return
      }

      matches.push(matched)
      $(element).replaceWith(` ${splitChar} `)
    },
  )

  const replacedScript = $('body').html()
  const removeReg =
    /(\<span contenteditable\=\"false\"\>|\<\/?span\>)|<div contenteditable=\"false\">|<\/div>/gm
  const spanRemovedScript = replacedScript?.replaceAll(removeReg, '') ?? ''

  return {
    script: spanRemovedScript,
    matches,
  }
}

export const restoreTagOfScript = (script: string, match: string) => {
  return script.replace(splitChar, match);
}