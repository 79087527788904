/**
 * 공통으로 사용되는 API 구현
 * 
 * @author Steven
 * @date 2023.07.17
 */
import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const commonApi = createApi({
  reducerPath: "common",
  refetchOnFocus: false,
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/common",
  }),
  tagTypes: ["common", "env"],
  endpoints: (builder) => ({
    // 코드 목록을 조회한다.
    getCodes: builder.query<RestApiResponse<any>, { groupCode: string; }>({
      query: ({ groupCode }) => {return `/codes/${groupCode}`;},
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getEnv: builder.query<string, { key: string }>({
      query: ({ key }) => {return `/env/${key}`;},
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return "";
        }
        return res.data.value || "";
      },
    }),
    getEnvList: builder.query<{[key: string]: string}, { keys: string[] }>({
      query: (args) => ({
        url: `/env/list`,
        method: 'POST',
        body: args
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return {};
        }
        return res.data || {};
      },
    })
  })
})

export const {
  useGetCodesQuery,
  useGetEnvQuery,
  useGetEnvListQuery,
} = commonApi;

export default commonApi;