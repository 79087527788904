import { DeltaStatic } from 'quill'

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RestApiResponse } from '@/types/common.api'
import { Gender } from '@/constants/gender'
import {
  Orientation,
  ScriptType,
  SolidBackgroundVideoGenerateState,
  TextScriptTempo,
} from '@/constants/tools/solid-background-video'

type SolidBackgroundVideoExportPreviewAudioParams = {
  script: string
  voice: {
    modelId: string
    voiceId: string
    language: string
    gender: Gender
    isOriginalVoice: boolean
    type: string
  }
  tempo: TextScriptTempo
  detectedLanguage: string
}

type SolidBackgroundVideoExportPreviewAudioResponse = {
  backendTaskId: string
}

type SolidBackgroundVideoExportingPreviewAudioParams = {
  backendTaskId: string
}

type SolidBackgroundVideoExportingPreviewAudioResponse = {
  state: 'error' | 'queue' | 'process' | 'complete'
  progress?: number
  audio?: {
    url: string
    duration: number
  }
}

type SolidBackgroundVideoCreateJobParams = {
  projectName: string
  voice: {
    modelId: string
    voiceId: string
    language: string
    gender: Gender
    isOriginalVoice: boolean
    type: string
  }
  orientation: Orientation
  backgroundColor: string | null
  modelPosition: {
    left: number
    top: number
    scaleX: number
    scaleY: number
  }

  scriptType: ScriptType

  textScriptDelta?: Partial<DeltaStatic>
  textScriptHtml?: string
  dictionary?: Record<string, string>
  tempo?: TextScriptTempo

  audioScriptUrl?: string
  audioScriptName?: string
  audioScriptFile?: File
}

type SolidBackgroundVideoCreateJobResponse = {
  jobId: string
}

type SolidBackgroundVideoUpdateStateJobParams = {
  jobId: string
  state: SolidBackgroundVideoGenerateState
  projectId?: string
  error?: string
}

type SolidBackgroundVideoUpdateStateJobResponse = void

type SolidBackgroundVideoExportJobParams = {
  jobId: string
}

type SolidBackgroundVideoExportJobResponse = void

type SolidBackgroundVideoGetJobParams = string

type SolidBackgroundVideoGetJobResponse = {
  jobId: string
  generateState: SolidBackgroundVideoGenerateState
  exportingProgress?: number
  exportedFileUrl?: string
  error?: string
}

export const solidBackgroundVideoApi = createApi({
  reducerPath: 'tools/solidBackgroundVideoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/tools/solid-background-video',
  }),
  endpoints: (build) => ({
    exportPreviewAudio: build.mutation<
      SolidBackgroundVideoExportPreviewAudioResponse,
      SolidBackgroundVideoExportPreviewAudioParams
    >({
      query: ({ script, voice, detectedLanguage, tempo }) => ({
        url: '/preview-audios/export',
        method: 'POST',
        body: {
          script,
          voice,
          detectedLanguage,
          tempo,
        },
      }),
      transformResponse: (
        res: RestApiResponse<SolidBackgroundVideoExportPreviewAudioResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to export preview audio')
        }

        return res.data
      },
    }),

    getExportingPreviewAudio: build.query<
      SolidBackgroundVideoExportingPreviewAudioResponse,
      SolidBackgroundVideoExportingPreviewAudioParams
    >({
      query: ({ backendTaskId }) => `/preview-audios/${backendTaskId}`,
      transformResponse: (
        res: RestApiResponse<SolidBackgroundVideoExportingPreviewAudioResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch exporting preview audio')
        }

        return res.data
      },
    }),

    createJob: build.mutation<
      SolidBackgroundVideoCreateJobResponse,
      SolidBackgroundVideoCreateJobParams
    >({
      query: ({
        projectName: projectTitle,
        voice,
        orientation,
        backgroundColor,
        modelPosition,
        scriptType,
        textScriptDelta,
        textScriptHtml,
        dictionary,
        tempo: speed,
        audioScriptUrl,
        audioScriptFile,
      }) => {
        const form = new FormData()

        if (audioScriptFile) {
          form.append('file', audioScriptFile)
        }

        form.append(
          'params',
          JSON.stringify({
            projectTitle,
            voice,
            orientation,
            backgroundColor,
            modelPosition,
            scriptType,
            textScriptDelta,
            textScriptHtml,
            dictionary,
            speed,
            audioScriptUrl,
          }),
        )

        return {
          url: '/jobs',
          method: 'POST',
          body: form,
        }
      },
      transformResponse: (
        res: RestApiResponse<SolidBackgroundVideoCreateJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to solid background video job')
        }

        return res.data
      },
    }),

    updateStateJob: build.mutation<
      SolidBackgroundVideoUpdateStateJobResponse,
      SolidBackgroundVideoUpdateStateJobParams
    >({
      query: ({ jobId, state, projectId, error }) => ({
        url: `/jobs/${jobId}`,
        method: 'PUT',
        body: {
          state,
          projectId,
          error,
        },
      }),
      transformResponse: (
        res: RestApiResponse<SolidBackgroundVideoUpdateStateJobResponse>,
      ) => {
        if (!res.success) {
          throw new Error('Failed to update solid background video job')
        }

        return res.data
      },
    }),

    exportJob: build.mutation<
      SolidBackgroundVideoExportJobResponse,
      SolidBackgroundVideoExportJobParams
    >({
      query: ({ jobId }) => ({
        url: `/jobs/${jobId}/export`,
        method: 'PUT',
      }),
      transformResponse: (
        res: RestApiResponse<SolidBackgroundVideoExportJobResponse>,
      ) => {
        if (!res.success) {
          throw new Error('Failed to export solid background video job')
        }

        return res.data
      },
    }),

    getJob: build.query<
      SolidBackgroundVideoGetJobResponse,
      SolidBackgroundVideoGetJobParams
    >({
      query: (jobId) => ({
        url: `/jobs/${jobId}`,
        method: 'GET',
      }),
      transformResponse: (
        res: RestApiResponse<SolidBackgroundVideoGetJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to get solid background video job')
        }

        return res.data
      },
    }),
  }),
})
