import { genId, genSceneId } from "@/lib/canvas-utils";
import type { Scene, Clip, Scripts } from "@/types/type";

export function checkDuplicatedClipId(scenes: Scene[]) {
  return scenes.reduce((acc, scene) => {
    const idSet = new Set(scene.clips.map((clip) => clip.id));

    if (idSet.size !== scene.clips.length) {
      return true;
    }

    return acc;
  }, false);
}

/**
 * 씬 리스트에서 씬 아이디가 없거나 중복된 씬 아이디를 가지는 경우를 정리함
 */
export function normalizeSceneId(scenes: Scene[]) {
  const newScenes: Scene[] = []

  for (const scene of scenes) {
    /**
     * 씬 아이디가 있고 중복되지 않으면 그대로 사용
     */
    if (!!scene.id && newScenes.every((newScene) => newScene.id !== scene.id)) {
      newScenes.push(scene)
    }
    /**
     * 씬 아이디가 없거나 중복되면 새로운 아이디 생성
     */
    else {
      newScenes.push({
        ...scene,
        id: genSceneId(newScenes),
      })
    }
  }

  return newScenes
}

export function normalizeClipId(scenes: Scene[]) {
  if (!checkDuplicatedClipId(scenes)) {
    return scenes;
  }

  const newScenes: Scene[] = scenes.map((scene) => {
    const changes: (Clip & { newId: string })[] = [];

    const changedClips = scene.clips.reduce((acc, clip, index) => {
      if (
        acc
          .filter((c, i) => i !== index)
          .map((c) => c.id)
          .includes(clip.id)
      ) {
        const newId = genId(clip.type, acc);

        if (clip.type === "aiModel") {
          changes.push({ ...clip, newId });
        }

        return acc.map((v, i) => {
          if (i === index) {
            return { ...clip, id: newId };
          }
          return v;
        });
      }

      return acc;
    }, scene.clips);

    const changedScripts = changes.reduce((acc: Scripts[], change) => {
      return acc.map((script) => {
        if (script.clipId === change.id) {
          return {
            ...script,
            clipId: change.newId,
            modelId: change.model.ai_name,
            clothId: change.model.emotion,
            gender: change.model.gender,
            modelSupportLanguage: change.model.language,
          }
        }

        return script;
      });
    }, scene.scripts);

    return {
      ...scene,
      clips: changedClips,
      scripts: changedScripts,
    };
  });

  return newScenes;
}

export function checkUnstableClipLayer(scenes: Scene[]) {
  return scenes.reduce((acc, scene) => {
    if (
      scene.clips
        .filter((clip) => clip.type !== "audio")
        .sort((a, b) => a.layer - b.layer)
        .some((clip, index) => clip.layer !== index + 1)
    ) {
      return true;
    }

    return acc;
  }, false);
}

export function normalizeClipLayer(scenes: Scene[]) {
  if (!checkUnstableClipLayer(scenes)) {
    return scenes;
  }

  const newScenes: Scene[] = scenes.map((scene) => {
    const size = Math.pow(10, scene.clips.length.toString().length);
    const layers = scene.clips
      .filter((clip) => clip.type !== "audio")
      .map((clip, index) => ({
        id: clip.id,
        layer: clip.layer * size + index,
      }))
      .sort((a, b) => a.layer - b.layer)
      .reduce((acc: { [id: string]: number }, item, index) => ({
        ...acc,
        [item.id]: index + 1,
      }), {});

    return {
      ...scene,
      clips: scene.clips.map((clip) => {
        if (clip.type === "audio") {
          return {
            ...clip,
            layer: 0,
          }
        }
        return {
          ...clip,
          layer: layers[clip.id],
        }
      }),
    };
  });

  return newScenes;
}

export function migrateClipTrim(clip: Clip) {
  if (Object.hasOwn(clip, "trimStartTime") && Object.hasOwn(clip, "trimEndTime")) {
    const { trimStartTime = 0, trimEndTime = 0, ...restClip } = clip;
    
    return {
      ...restClip,
      trim: [
        { start: trimStartTime, end: trimEndTime },
      ]
    }
  }

  return clip;
}

export function normalizeTextStyle(scenes: Scene[]): Scene[] {
  return scenes.map((scene) => {
    return {
      ...scene,
      clips: scene.clips.map((clip) => {
        if (clip.type !== "textImage" && clip.type !== "captions") {
          return clip;
        }

        const fontStyle = (() => {
          const prevFontStyle = clip.fontStyle as unknown;

          if (prevFontStyle === "italic") {
            return "italic";
          }
          return "normal";
        })();

        const fontWeight = (() => {
          const prevFontWeight = clip.fontWeight as unknown;

          if (
            prevFontWeight === "600" ||
            prevFontWeight === "bold" ||
            prevFontWeight === 700
          ) {
            return "700";
          }
          return "400";
        })();

        return {
          ...clip,
          fontStyle,
          fontWeight,
        }
      })
    }
  })
}