import { runtimeEnv } from "@/lib/runtime-utils";
import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {AutomationProps, ValueOf} from "@/types/type";
import {
  FetchFolderStatusDto,
  FolderStatusDto,
  GetProofreadJobDto,
  MutateSpeakerSceneDto,
  ProofreadJobDto,
  RequestProofreadingDto,
  RegenerateSpeakerSceneDto,
  AsyncJobDto, AsyncJobIdDto, ProofreadStepDto,
} from "@/types/translateVideo/dto";

export const automationApi = createApi({
  reducerPath: "automationApi",
  refetchOnFocus: false,

  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/automation`,
  }),
  tagTypes: ["automation"],

  endpoints: (builder) => ({
    createProject: builder.mutation<RestApiResponse<any>, AutomationProps>({
      query: (body) => ({
        url: "/create",
        method: "POST",
        body,
      }),
    }),
    getTemplatePost: builder.mutation<
      RestApiResponse<any>,
      {
        category: string;
        automation?: boolean;
        screenType?: string;
        orientation?: string;
        language?: string;
        useMyAvatar?: string;
      }
    >({
      query: ({
        category,
        automation,
        screenType,
        orientation,
        language,
        useMyAvatar,
      }) => ({
        url: `/templates`,
        method: "POST",
        body: {
          category,
          automation,
          screenType,
          orientation,
          language,
          useMyAvatar,
        },
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success) {
          return {
            success: false,
            error: res.error,
          };
        }
        return {
          success: true,
          data: res.data,
        };
      },
    }),
    createAutomationLog: builder.mutation<
      RestApiResponse<any>,
      {
        projectId: string;
        eventName: string;
        [key: string]: any;
      }
    >({
      query: (body) => ({
        url: "/automation-log",
        method: "POST",
        body,
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getCurrentlyActiveSeries: builder.query<RestApiResponse<any>, any>({
      query: (params) => ({
        url: "/videoSeries/count-active-series",
        method: "GET",
        params,
      }),
    }),
    tiktokAuthentication: builder.mutation({
      query: () => ({
        url: "/tiktok/authenticate",
        method: "GET",
      }),
    }),
    tiktokDisconnect: builder.mutation({
      query: () => ({
        url: "/tiktok/disconnect",
        method: "POST",
      }),
    }),
    tiktokGetToken: builder.mutation<RestApiResponse<any>, any>({
      query: (body) => ({
        url: "/tiktok/get-token",
        method: "POST",
        body,
      }),
    }),
    tiktokCreatorInfo: builder.mutation<RestApiResponse<any>, any>({
      query: () => ({
        url: "/tiktok/creator-info",
        method: "POST",
      }),
    }),
    tiktokPost: builder.mutation<RestApiResponse<any>, any>({
      query: (body) => ({
        url: "/tiktok/direct-post",
        method: "POST",
        body,
      }),
    }),
    tiktokGetPostStatus: builder.mutation<RestApiResponse<any>, any>({
      query: (body) => ({
        url: "/tiktok/get-post-status",
        method: "POST",
        body,
      }),
    }),
    extractFileImages: builder.mutation<
      RestApiResponse<any>,
      { azure: string; originalFileName: string }
    >({
      query: (body) => ({
        url: "/extract-file-images",
        method: "POST",
        body,
      }),
    }),
    googleAuthentication: builder.mutation({
      query: () => ({
        url: "/google/authenticate",
        method: "GET",
      }),
    }),
    googleDisconnect: builder.mutation({
      query: () => ({
        url: "/google/disconnect",
        method: "POST",
      }),
    }),
    googleGetToken: builder.mutation<RestApiResponse<any>, any>({
      query: (code) => ({
        url: `/google/get-token?code=${encodeURIComponent(code)}`,
        method: "GET",
      }),
    }),
    getOauth2LoginStatus: builder.query<RestApiResponse<any>, any>({
      query: () => ({
        url: "/get-oauth2-login-status",
        method: "GET",
      }),
    }),
    getMyGoogleChannelInfo: builder.query<RestApiResponse<any>, any>({
      query: () => ({
        url: "/google/get-my-channel",
        method: "GET",
      }),
    }),
    getGoogleCategoryList: builder.query<RestApiResponse<any>, any>({
      query: () => ({
        url: "/google/get-category-list",
        method: "GET",
      }),
    }),
    // 비디오 번역 관련
    /**
     * Srt file upload
     */
    uploadSrtFile: builder.mutation({
      query: (body) => ({
        url: "/translatevideo/upload-srt",
        method: "POST",
        body,
      }),
    }),
    /*
     * ProofreadingJob을 가져오는 GET API
     */
    getProofreadJob: builder.query<
      RestApiResponse<ProofreadJobDto | ProofreadStepDto>,
      GetProofreadJobDto
    >({
      query: (body) => ({
        url:
          "translatevideo/speaker-scenes?projectId=" +
          body.projectId +
          "&automationJobId=" +
          body.automationJobId,
        method: "GET",
      }),
    }),
    /*
     * ProofreadingJob을 업데이트하는 PUT API
     */
    updateSpeakerScenes: builder.mutation<
      RestApiResponse<any>,
      MutateSpeakerSceneDto
    >({
      query: (body) => ({
        url: "translatevideo/speaker-scenes",
        method: "PUT",
        body,
      }),
    }),
    /*
     * 선택된 SpeakerScene을 다시 발화시키는 비동기 POST API
     */
    regenerateSpeakerScenes: builder.mutation<
      RestApiResponse<AsyncJobIdDto>,
      RegenerateSpeakerSceneDto
    >({
      query: (body) => ({
        url: "translatevideo/speaker-scenes",
        method: "POST",
        body,
      }),
    }),
    /*
     * SpeakerScene 재발화 Job의 상태를 가져오는 GET API
     */
    fetchRegenerateJobStatus: builder.query<
      RestApiResponse<AsyncJobDto>,
      { jobId: string }
    >({
      query: (body) => ({
        url: "translatevideo/speaker-scenes?jobId=" + body.jobId,
        method: "GET",
      }),
    }),
    /*
     * 같은 folderId를 가진 프로젝트들의 완료 상태를 가져오는 GET API
     */
    fetchFolderStatus: builder.query<
      RestApiResponse<FolderStatusDto[]>,
      FetchFolderStatusDto
    >({
      query: (body) => ({
        url: "translatevideo/project?folderId=" + body.folderId,
        method: "GET",
      }),
    }),
    /*
     * Proofreading을 요청하고 export하는 POST API
     */
    requestProofreading: builder.mutation<
      RestApiResponse<any>,
      RequestProofreadingDto
    >({
      query: (body) => ({
        url: "/translatevideo/project",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useGetTemplatePostMutation,
  useCreateAutomationLogMutation,
  useGetCurrentlyActiveSeriesQuery,
  useTiktokAuthenticationMutation,
  useTiktokDisconnectMutation,
  useTiktokGetTokenMutation,
  useTiktokCreatorInfoMutation,
  useTiktokPostMutation,
  useTiktokGetPostStatusMutation,
  useExtractFileImagesMutation,
  useGoogleAuthenticationMutation,
  useGoogleDisconnectMutation,
  useGoogleGetTokenMutation,
  useGetOauth2LoginStatusQuery,
  useLazyGetOauth2LoginStatusQuery,
  useGetMyGoogleChannelInfoQuery,
  useLazyGetMyGoogleChannelInfoQuery,
  useGetGoogleCategoryListQuery,
  useLazyGetGoogleCategoryListQuery,
  useUploadSrtFileMutation,
  useGetProofreadJobQuery,
  useLazyGetProofreadJobQuery,
  useUpdateSpeakerScenesMutation,
  useLazyFetchRegenerateJobStatusQuery,
  useRegenerateSpeakerScenesMutation,
  useFetchFolderStatusQuery,
  useRequestProofreadingMutation,
} = automationApi;

export default automationApi;
