import { wrapper } from "@/redux/store";
import Cookies from "js-cookie";
import { SessionProvider, getSession } from "next-auth/react";
import type { AppProps } from 'next/app';
import { useCallback, useEffect, useState } from 'react';
import { Provider } from "react-redux";

import '@/public/css/antd.globals.css';
import "@/public/css/fonts.css";
import '@/public/css/swagger.css';
import "@/public/css/textScriptEditor.css";
import 'react-h5-audio-player/lib/styles.css';

import { AntdStyleRegistry, ThemeRegistry } from "@/components/registry";
import { GlobalStyles } from "@/styles/global";
import { ColorMode } from '@/types/propTypes';
import { Session } from 'next-auth';
import { appWithTranslation } from "next-i18next";
import { useRouter } from "next/router";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';
import { useSession } from "next-auth/react";
import { useCookies } from "react-cookie";
import { useAmplitude } from "@/hooks/useAmplitude";
import AmplitudeContext from "@/components/AmplitudeContext";
import * as amplitude from "@amplitude/analytics-browser";
import { getUserInfo } from "@/lib/api-account-utils";

// DataDog 사용시 useEnvList를 사용하기 위해서 (RTK Query용)
const DataDog = ({ envData }: { envData: any }) => {
  const { data: session } = useSession();
  const [ isReqDataDog, setIsReqDataDog ] = useState(false);
  


  useEffect(() => {
    if(!isReqDataDog && (envData.DATADOG_RUM_USE ?? "") === "true") {
      const initData = {
        //applicationId: 'bdb25970-b9e8-4694-923e-5fb7be111a38',
        //clientToken: 'pub8450d2d7963b33d2d183280086977500',
        applicationId: envData.DATADOG_APP_ID ?? "",
        clientToken: envData.DATADOG_TOKEN ?? "",
        site: envData.DATADOG_SITE ?? "",
        service: envData.DATADOG_SERVICE ?? "",
        env: envData.DATADOG_APP_ENV ?? "",
        // Specify a version number to identify the deployed version of your application in Datadog 
        version: envData.DATADOG_AISTUDIO_VERSION ?? "", 
        sessionSampleRate: parseInt(envData.DATADOG_SESSION_RATE ?? "100"),
        sessionReplaySampleRate: parseInt(envData.DATADOG_REPLAY_RATE ?? "100"),
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
        allowedTracingUrls: [
          envData.DATADOG_TRACE_URL ?? ""
          //, { match: envData.get("DATADOG_TRACE_URL") ?? "", propagatorTypes: ["tracecontext"] }
        ],
        traceSampleRate: parseInt(envData.DATADOG_TRACE_SAMPLE_RATE ?? "100")
      };

      //datadog RUM 추가 
      datadogRum.init(initData);    
      datadogRum.startSessionReplayRecording();
      setIsReqDataDog(true);
    }
    
    if (session) {
      datadogRum.setUser({
        id: session.user.id,
        name: session.user.name ?? "",
        email: session.user.email ?? "",
        //plan: 'premium',
      });   
    } else {
      datadogRum.clearUser();
    }
  }, [envData, isReqDataDog, session]);
  
  return <></>
}

interface UserInfo  { 
  id: string; 
  plan: string 
};

const App = ({
  Component,
  session,
  darkMode,
  systemColor,
  envData,
  AMPLITUDE_API_KEY,
  userInfo,
  ...rest
}: AppProps & {
  session: Session;
  darkMode: ColorMode;
  systemColor: ColorMode;
  envData: any;
  AMPLITUDE_API_KEY: string;
  userInfo: UserInfo;
}) => {
  
    
    const {store, props} = wrapper.useWrappedStore(rest);
    const [cookies, setCookie] = useCookies(["first_view_dashboard"]);
    const { track, revenue } = useAmplitude({user_id: session?.user?.id || "guest", AMPLITUDE_API_KEY});
    
    const router = useRouter();
    
    const handleRouteChange = useCallback(
      (url: string) => {

        if (window?.dataLayer?.push) {
          if (url?.includes("/dashboard")) {
            if (!cookies?.first_view_dashboard){
              setCookie("first_view_dashboard", true, { path: "/" });
              window.dataLayer.push({
                event: "first_view_dashboard",
                pageviewCount_dashboard: 1,
                user_id: userInfo?.id
              });
            }
            else {
              window.dataLayer.push({
                event: "page_view_dashboard",
                pageviewCount_dashboard: cookies?.first_view_dashboard + 1,
                user_id: userInfo?.id
              });
              track(
                "page_view_dashboard"
              );
            }

          } 
        }
      },
      [cookies?.first_view_dashboard, setCookie, track, userInfo?.id]
    );


  useEffect(() => {

    handleRouteChange(router.asPath);

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [handleRouteChange, router.asPath, router.events]);

  useEffect(()=>{
    if(router && router.query ){
      const { from = "" } = router.query;
      if(from === "google" || from==="microsoft"){
        const identifyEvent = new amplitude.Identify();
        const isPaid = userInfo?.plan !=='none' ? "paid" : "non_paid";
        identifyEvent.set("subscription_status", isPaid);
        amplitude.identify(identifyEvent);

        window.gtag('set', 'user_properties', {
          'subscription_status': isPaid,
        });

      }
    }
  },[router, userInfo?.plan])

  /**
   * 유저 플랜 GA로 전달
   * 
   * - 플랜 값 그대로 넘기기
   * - 키는 "user_plan"으로 약속
   */
  useEffect(() => {
    window?.gtag("set", "user_properties", {
      user_plan: userInfo?.plan,
    });
  }, [router.asPath, userInfo?.plan]);

  return (
  <Provider store={store}>
      <AntdStyleRegistry darkMode={darkMode} systemColor={systemColor}>
        <ThemeRegistry>
          <GlobalStyles />
          <SessionProvider session={session}>
            <AmplitudeContext.Provider value={{track, revenue}}>
              <Component {...props} />
              <DataDog envData={envData} />
            </AmplitudeContext.Provider>
          </SessionProvider>
        </ThemeRegistry>
      </AntdStyleRegistry>
    </Provider>
  );
}

function getCookies({ req }: any, key: string, initialValue: string) {
  if (req) {
    return req.cookies?.[key] || initialValue;
  }
  return Cookies.get(key) || initialValue;
}


App.getInitialProps = async (context: any) => {
  const { ctx, Component } = context;

  const session = await getSession(context);
  

  const darkMode = getCookies(ctx, "dark-mode", "light");
  const systemColor = getCookies(ctx, "system-color", "light");
  let AMPLITUDE_API_KEY: string = "";
  let envData: any = {};
  let userInfo = {};
  if (typeof window === "undefined") {
    AMPLITUDE_API_KEY = process.runtimeEnv.AMPLITUDE_API_KEY || "35aaed6af0559de0c3e8561a3109efce";
    const envKeys = Object.keys(process.runtimeEnv);
    envKeys.forEach((value) => {
      if (value.startsWith("DATADOG")) {
        envData[value] = process.runtimeEnv[value] ?? "";
      }
    });

    try {
      if(session){
        const response = await getUserInfo(session?.user?.email!);
        const { id, plan } = response.data;
        userInfo = { id, plan };
      }
    } catch (error) {
      console.log("error: ", error);
    }
    
  } else {
    const result:any = await fetch('/api/common/env/prefix_list', {
      method: "POST",
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ prefix: "DATADOG" }),
    });
    const data = await result.json();
    // console.log(">>>>>>> result  : ", data);
    
    if (data.success) {
      envData = data.data;
    }
    // console.log(">>>>>>> envData  : ", JSON.stringify(envData));
  }

  let pageProps = {};
  if (Component.getInitialProps) {
    // Component의 context로 ctx를 넣어주자
    pageProps = await Component.getInitialProps(ctx);
    // console.log("getInitialProps : ", pageProps);
  }

  
 
  
  
  // return한 값은 해당 컴포넌트의 props로 들어가게 됩니다.
  return { pageProps, darkMode, systemColor, envData, session, AMPLITUDE_API_KEY, userInfo };
};



export default appWithTranslation(App);
