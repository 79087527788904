import { fabric } from 'fabric'
import { IImageOptions } from 'fabric/fabric-impl'

export class EffectVideoFabricObjectClass extends fabric.Image {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    element: string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement,
    options: IImageOptions & {
      effectMeta: {
        effectVideoUrl: string
      }
      width: number
      height: number
    },
  ) {
    super(element, options)
  }
}
export interface EffectVideoFabricObjectClass extends fabric.Image {
  type: 'effect'
  show: () => void
  hide: () => void
  getIsPlaying: () => boolean
  play: () => Promise<void>
  stop: () => void
  pause: () => void
  /**
   * @returns unit: seconds
   */
  getDuration(): number
  /**
   * @returns unit: seconds
   */
  getCurrentTime(): number
  setCurrentTime(
    /**
     * unit: seconds
     */
    time: number,
  ): void
  goToEnd(): void
  onCanPlayThrough(callback: () => void): void
}

export const EffectVideoFabricObject: typeof EffectVideoFabricObjectClass =
  fabric.util.createClass(fabric.Image, {
    type: 'effect',
    _isPlaying: false,
    _canPlayThrough: false,
    _listeners: [],
    _options: {},

    initialize(
      element: string,
      options: {
        effectMeta: {
          effectVideoUrl: string
        }
        width: number
        height: number
      },
    ) {
      this._listeners = []
      this._options = options

      this.callSuper('initialize', '', {
        ...options,
        type: 'effect',
        visible: false,
      })

      this._initVideoElement()

      this.on('removed', () => {
        const videoElement = this.getElement()

        if (!videoElement) {
          return
        }

        videoElement.pause()
        videoElement.remove()
      })
    },

    _initVideoElement() {
      if (this.getElement() instanceof HTMLVideoElement) {
        return
      }

      const videoElement = document.createElement('video')
      videoElement.playsInline = true
      videoElement.crossOrigin = 'anonymous'
      videoElement.volume = 0.5
      videoElement.src = this._options.effectMeta.effectVideoUrl

      videoElement.addEventListener('loadedmetadata', () => {
        this.set('width', videoElement.videoWidth)
        this.set('height', videoElement.videoHeight)
        this.set(
          'scaleX',
          (this._options.width / videoElement.videoWidth) *
            this._options.scaleX,
        )
        this.set(
          'scaleY',
          (this._options.height / videoElement.videoHeight) *
            this._options.scaleY,
        )

        /**
         * 비디오의 너비, 높이를 설정해줘야 캔버스에 렌더링이 됨
         */
        videoElement.width = videoElement.videoWidth
        videoElement.height = videoElement.videoHeight
      })

      videoElement.addEventListener('canplaythrough', () => {
        this._canPlayThrough = true

        this._listeners.forEach((listener: () => void) => {
          listener()
        })
        this._listeners = []
      })

      videoElement.addEventListener('play', () => {
        this._isPlaying = true
        this._renderCanvas()
      })
      videoElement.addEventListener('pause', () => {
        this._isPlaying = false
      })

      this.setElement(videoElement)
    },

    _isMounted() {
      return !!this.canvas?.wrapperEl
    },

    _renderCanvas() {
      if (!this._isPlaying) {
        return
      }

      if (!this._isMounted()) {
        return
      }

      try {
        this.canvas.renderAll()
      } catch (e) {
        // do nothing
      }

      fabric.util.requestAnimFrame(() => {
        this._renderCanvas()
      })
    },

    show() {
      this.set('visible', true)
    },

    hide() {
      this.set('visible', false)
    },

    getIsPlaying() {
      return this._isPlaying
    },

    async play() {
      const videoEl = this.getElement()

      if (!videoEl) {
        return
      }

      if (this._isPlaying) {
        return
      }

      await videoEl.play()
    },

    stop(goToEnd = false) {
      const videoEl = this.getElement()

      if (!videoEl) {
        return
      }

      if (!this._isPlaying) {
        return
      }

      videoEl.pause()

      if (goToEnd) {
        videoEl.currentTime = videoEl.duration
      } else {
        videoEl.currentTime = 0
      }
    },

    pause() {
      const videoEl = this.getElement()

      if (!videoEl) {
        return
      }

      videoEl.pause?.()
    },

    getDuration() {
      const videoEl = this.getElement()

      if (!videoEl) {
        return 0
      }

      return videoEl.duration
    },

    getCurrentTime() {
      const videoEl = this.getElement()

      if (!videoEl) {
        return 0
      }

      return videoEl.currentTime
    },

    setCurrentTime(time: number) {
      const videoEl = this.getElement()

      if (!videoEl) {
        return
      }

      videoEl.currentTime = time
    },

    goToEnd() {
      const videoEl = this.getElement()

      if (!videoEl) {
        return
      }

      if (videoEl.currentTime === videoEl.duration) {
        return
      }

      videoEl.currentTime = videoEl.duration
    },

    onCanPlayThrough(callback: () => void) {
      if (this._canPlayThrough) {
        callback()
        return
      }

      this._listeners.push(callback)
    },
  })

/**
 * 썸네일, 서버사이드 등에서 사용되는 단순 이미지 형태의 이펙트 클립 오브젝트 클래스
 */
export const EffectImageFabricObject: typeof fabric.Image =
  fabric.util.createClass(fabric.Image, {
    type: 'effect',
    _options: {},

    initialize(
      element: string,
      options: {
        name: string
        width: number
        height: number
        left: number
        top: number
      },
    ) {
      this._options = options

      this.callSuper('initialize', element, {
        ...options,
        type: 'effect',
        objectCaching: false,
      })
    },

    setSrc(
      src: string,
      callback: (param: typeof EffectImageFabricObject) => void,
      options: any,
    ) {
      this.callSuper(
        'setSrc',
        src,
        () => {
          this.set(
            'scaleX',
            (this.scaleX * this._options.width) / (this.width ?? 1),
          )
          this.set(
            'scaleY',
            (this.scaleY * this._options.height) / (this.height ?? 1),
          )

          callback(this)
        },
        options,
      )
    },
  })

/**
 * 타입 정의를 위해 구현이 빈 클래스 작성
 */
export class EffectFabricObjectClass extends fabric.Image {
  /**
   * 인터페이스에는 생성자를 선언할 수 없는 것 같아서\
   * 구현이 빈 클래스를 작성함
   */
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    element: string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement,
    options: IImageOptions & {
      effectMeta: {
        effectId: string
        effectVideoUrl: string
        effectImageUrl: string
        effectOriginalVideoUrl: string
        connected: boolean
        order?: number
      }
      name: string
      width: number
      height: number
    },
  ) {
    super(element, options)
  }
}
/**
 * 타입 정의를 위해 인터페이스 작성
 */
export interface EffectFabricObjectClass extends fabric.Image {
  type: 'effect'
  changeEffect: (params: {
    effectMeta?: {
      effectId?: string
      effectVideoUrl?: string
      effectImageUrl?: string
      effectOriginalVideoUrl?: string
      connected?: boolean
      order?: number
    }
    width?: number
    height?: number
  }) => void
  play: () => Promise<void>
  stop: () => void
  pause: () => void
}

/**
 * 에디터의 메인 편집 캔버스에서 사용되는 이펙트 클립 오브젝트 클래스
 */
export const EffectFabricObject: typeof EffectFabricObjectClass =
  fabric.util.createClass(fabric.Group, {
    type: 'effect',

    _isPlaying: false,
    _videoFabricObject: null,
    _imageFabricObject: null,
    _orderRectFabricObject: null,
    _orderTextFabricObject: null,
    _orderFabricObject: null,
    _highlightFabricObject: null,
    _videoElement: null,
    _options: {},

    initialize(
      element: string,
      options: {
        effectMeta: {
          effectId: string
          effectVideoUrl: string
          effectImageUrl: string
          effectOriginalVideoUrl: string
          connected: boolean
          order?: number
        }
        name: string
        width: number
        height: number
      },
    ) {
      this._options = options

      const videoFabricObject = new fabric.Image('', {
        originX: 'center',
        originY: 'center',
        visible: false,
      })
      const imageFabricObject = new fabric.Image('', {
        originX: 'center',
        originY: 'center',
        visible: true,
      })

      const orderRectFabricObject = new fabric.Rect({
        originX: 'center',
        originY: 'center',
        fill: '#F6C038',
        opacity: 0.5,
        width: 35,
        height: 35,
        rx: 5,
        ry: 5,
      })
      /**
       * TODO: 넘버링 표시를 좀 더 선명하게 할 수 있는 방법 찾아보기\
       * TODO: 폰트 크기 키우고 스케일을 줄이는 방법은 의도대로 동작하지 않음
       */
      const multiplier = 2
      const orderTextFabricObject = new fabric.Text(
        `${(options.effectMeta.order ?? 0) + 1}`,
        {
          originX: 'center',
          originY: 'center',
          fill: '#000000',
          fontSize: 20 * multiplier,
          fontFamily: 'Inter',
          fontWeight: 400,
          scaleX: 1 / multiplier,
          scaleY: 1 / multiplier,
        },
      )

      const orderFabricObject = new fabric.Group(
        [orderRectFabricObject, orderTextFabricObject],
        {
          originX: 'center',
          originY: 'center',
          visible: options.effectMeta.connected,
        },
      )

      this._videoFabricObject = videoFabricObject
      this._imageFabricObject = imageFabricObject
      this._orderRectFabricObject = orderRectFabricObject
      this._orderTextFabricObject = orderTextFabricObject
      this._orderFabricObject = orderFabricObject

      this.callSuper(
        'initialize',
        [videoFabricObject, imageFabricObject, orderFabricObject],
        {
          ...options,
          type: 'effect',

          lockSkewingX: true,
          lockSkewingY: true,
          lockRotation: true,
          lockScalingFlip: true,
          lockUniScaling: true,

          objectCaching: false,
        },
      )

      this.setControlsVisibility({
        mtr: false,
      })

      this._initVideoElement()

      imageFabricObject.setSrc(
        options.effectMeta.effectImageUrl,
        () => {
          imageFabricObject.set(
            'scaleX',
            options.width / (imageFabricObject?.width ?? 1),
          )
          imageFabricObject.set(
            'scaleY',
            options.height / (imageFabricObject?.height ?? 1),
          )

          this.canvas?.renderAll()
        },
        {
          crossOrigin: 'anonymous',
        },
      )

      /**
       * 지워질 때 재생중이던 영상 멈추기/제거
       */
      this.on('removed', () => {
        if (!this._videoElement) {
          return
        }

        this._videoElement.pause()
        this._videoElement.remove()
      })

      /**
       *넘버링 위치/크기 재계산
       */
      this._calculateOrderFabricObject()

      /**
       * 스케일링, 이동 중일 때 넘버링 위치/크기 재계산
       */
      this.on('scaling', () => {
        this._calculateOrderFabricObject()
      })

      this.on('moving', () => {
        this._calculateOrderFabricObject()
      })

      /**
       * 값의 변화가 발생했을 때 넘버링 위치/크기 재계산
       */
      this.on('modified', () => {
        this._calculateOrderFabricObject()
      })
    },

    /**
     * 넘버링의 위치/크기를 재계산 함\
     * 좌측 상단에 동일 위치, 동일 크기로 보이도록 함\
     * 화면 밖으로 나간 경우에 화면 안쪽으로 보이도록 함
     *
     * TODO: 계산식 다시 보기!!\
     * TODO: 캔버스의 줌도 고려하기
     */
    _calculateOrderFabricObject() {
      // const canvasZoom = this.canvas?.getZoom() ?? 1
      const canvasZoom = 1

      // const canvasWidth = this.canvas?.getWidth() ?? 1920
      // const canvasHeight = this.canvas?.getHeight() ?? 1080

      const containerLeft = this.left
      const containerTop = this.top
      const containerWidth = this.width
      const containerHeight = this.height
      const containerScaleX = this.scaleX * canvasZoom
      const containerScaleY = this.scaleY * canvasZoom

      const pad = 20
      const targetWidth = this._orderFabricObject.width
      const targetHeight = this._orderFabricObject.height

      const pushX =
        (containerLeft < 0 ? Math.abs(containerLeft) : 0) / this.scaleX
      const pushY =
        (containerTop - targetHeight / canvasZoom < 0
          ? Math.abs(containerTop - targetHeight / canvasZoom)
          : 0) / this.scaleY

      const calculatedTargetLeft =
        ((targetWidth + pad) / containerScaleX - containerWidth) / 2 + pushX
      const calculatedTargetTop =
        (-targetHeight / containerScaleY - containerHeight) / 2 + pushY
      const calculatedTargetScaleX = 1 / containerScaleX
      const calculatedTargetScaleY = 1 / containerScaleY

      this._orderFabricObject.set('left', calculatedTargetLeft)
      this._orderFabricObject.set('top', calculatedTargetTop)
      this._orderFabricObject.set('scaleX', calculatedTargetScaleX)
      this._orderFabricObject.set('scaleY', calculatedTargetScaleY)
      this.canvas?.renderAll()
    },

    _initVideoElement() {
      if (this._videoElement) {
        this._videoElement.pause()
        this._videoElement.remove()
      }

      const videoElement = document.createElement('video')
      videoElement.playsInline = true
      videoElement.muted = true
      videoElement.crossOrigin = 'anonymous'
      videoElement.src = this._options.effectMeta.effectVideoUrl

      videoElement.addEventListener('loadedmetadata', () => {
        this._videoFabricObject.set('width', videoElement.videoWidth)
        this._videoFabricObject.set('height', videoElement.videoHeight)
        this._videoFabricObject.set(
          'scaleX',
          this._options.width / videoElement.videoWidth,
        )
        this._videoFabricObject.set(
          'scaleY',
          this._options.height / videoElement.videoHeight,
        )

        /**
         * 비디오의 너비, 높이를 설정해줘야 캔버스에 렌더링이 됨
         */
        videoElement.width = videoElement.videoWidth
        videoElement.height = videoElement.videoHeight

        this._calculateOrderFabricObject()

        this._switchVideoAndImage()
      })

      videoElement.addEventListener('play', () => {
        this._isPlaying = true
        this._renderCanvas()
        this._switchVideoAndImage()
      })
      videoElement.addEventListener('pause', () => {
        this._isPlaying = false
        this._switchVideoAndImage()
      })

      this._videoElement = videoElement
      this._videoFabricObject.setElement(videoElement)
    },

    _isMounted() {
      return !!this.canvas?.wrapperEl
    },

    /**
     * 영상이 재생중일 때는 캔버스 렌더
     */
    _renderCanvas() {
      if (!this._isPlaying) {
        return
      }

      if (!this._isMounted()) {
        return
      }

      try {
        this.canvas.renderAll()
      } catch (e) {
        // do nothing
      }

      fabric.util.requestAnimFrame(() => {
        this._renderCanvas()
      })
    },

    _switchVideoAndImage() {
      if (this._isPlaying) {
        this._videoFabricObject?.set('visible', true)
        this._imageFabricObject?.set('visible', false)
      } else {
        this._videoFabricObject?.set('visible', false)
        this._imageFabricObject?.set('visible', true)
      }

      this.canvas?.renderAll()
    },

    /**
     * 이펙트 종류 변경\
     * 넘버링, 타이밍 연결 여부 등 변경
     */
    changeEffect(params: {
      effectMeta?: {
        effectId?: string
        effectVideoUrl?: string
        effectImageUrl?: string
        effectOriginalVideoUrl?: string
        connected?: boolean
        order?: number
      }
      width?: number
      height?: number
    }) {
      this._options = {
        ...this._options,
        ...params,
        effectMeta: {
          ...this._options.effectMeta,
          ...params.effectMeta,
        },
      }

      if (!!params.effectMeta?.effectVideoUrl) {
        this._videoFabricObject.getElement()?.remove()
        this._initVideoElement()
      }

      if (!!params.effectMeta?.effectImageUrl) {
        this._imageFabricObject.setSrc(
          params.effectMeta?.effectImageUrl,
          () => {
            this._imageFabricObject.set(
              'scaleX',
              this._options.width / (this._imageFabricObject?.width ?? 1),
            )
            this._imageFabricObject.set(
              'scaleY',
              this._options.height / (this._imageFabricObject?.height ?? 1),
            )
          },
          {
            crossOrigin: 'anonymous',
          },
        )
      }

      if (
        typeof params.width === 'number' ||
        typeof params.height === 'number'
      ) {
        this.set('width', this._options.width)
        this.set('height', this._options.height)
      }

      if (
        typeof params.effectMeta?.connected === 'boolean' ||
        typeof params.effectMeta?.order === 'number'
      ) {
        this._orderFabricObject.set('visible', !!params.effectMeta?.connected)
        this._orderTextFabricObject.set(
          'text',
          `${(params.effectMeta?.order ?? 0) + 1}`,
        )
      }

      this.canvas?.renderAll()
    },

    async play() {
      const videoEl = this._videoFabricObject.getElement()

      if (!videoEl) {
        return
      }

      try {
        await videoEl.play()
      } catch (e) {
        videoEl.muted = true

        await videoEl.play()
      }
    },

    stop(goToEnd = false) {
      const videoEl = this._videoFabricObject.getElement()

      if (!videoEl) {
        return
      }

      videoEl.pause()

      if (goToEnd) {
        videoEl.currentTime = videoEl.duration
      } else {
        videoEl.currentTime = 0
      }
    },

    pause() {
      const videoEl = this._videoFabricObject.getElement()

      if (!videoEl) {
        return
      }

      videoEl.pause()
    },
  })
