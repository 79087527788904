import { runtimeEnv } from "@/lib/runtime-utils";
import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { PreviewAudioRequest, PreviewAudioResponse } from "@/types/preview.api";

export const previewApi = createApi({
  reducerPath: "previewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/preview`,
  }),
  tagTypes: ["Audio"],

  endpoints: (builder) => ({
    requestPreviewAudio: builder.mutation<RestApiResponse<void>, PreviewAudioRequest>({
      query: (body) => ({
        url: "/audio",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Audio"],
      // transformResponse: async (res: RestApiResponse<string>) => {
      //   if (!res.success) {
      //     return null;
      //   }
      //   return res.data;
      // },
    }),

    getPreviewAudios: builder.query<PreviewAudioResponse, { projectId: string; sessionId: string }>({
      query: (query) => {
        const params = new URLSearchParams(query);
        
        return {
          url: `/audio?${params}`,
          method: "GET",
        }
      },
      providesTags: ["Audio"],
      transformResponse: async (res: RestApiResponse<PreviewAudioResponse>) => {
        if (!res.success) {
          return {};
        }
        return res.data;
      },
    }),
  }),
});

export const {
  useRequestPreviewAudioMutation,
  useLazyGetPreviewAudiosQuery,
} = previewApi;

export default previewApi;
