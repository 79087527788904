import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RestApiResponse } from "@/types/common.api";
import { runtimeEnv } from "@/lib/runtime-utils";
import { HYDRATE } from "next-redux-wrapper";
import { VoiceVender } from "@/constants/voice";

export const backendApi = createApi({
  reducerPath: "backend",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/backend`,
  }),
  tagTypes: ["Backend"],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    /**
     * 음성 샘플 URL 가져오기
     */
    getVoiceSampleUrl: builder.query({
      query: ({
        type,
        modelId,
        language,
        resourceUrl,
      }: {
        type: VoiceVender
        modelId: string
        language: string
        resourceUrl?: string
      }) => ({
        url: `/multi_tts`,
        method: 'POST',
        body: {
          apiUrl: '/api/v2/tts/multi_tts',
          method: 'POST',
          params: {
            type,
            modelId,
            language,
            resourceUrl,
          },
        },
      }),
      transformResponse: (res: RestApiResponse<{
        resourceUrl: string
      }>) => {
        if (!res.success) {
          return
        }

        return res.data
      },
    }),
  }),
});

export const {
  util: { getRunningQueriesThunk },
} = backendApi;

export default backendApi;
