import { load } from 'cheerio'

import { Clip } from "@/types/type";
import { Clothes, Model } from "@/types/models";

// 프로젝트 내용중 base64 데이터를 제거한다.
export function removeBase64InProject(project: any) {
  const data = {
    ...project,
    thumbnailUrl: null,
    scenes: project?.scenes.map((scene: any) => {
      if (!scene) return scene;
      const retData = {
        ...scene,
        clips: scene.clips.map((clip: any) => {
          if (clip.type === "aiModel") {
            let pjt = { ...clip };
            if (clip.headOnly)
              pjt = { ...pjt, headOnly: { ...clip.headOnly, src: null } };
            if (pjt.beforHeadOnly)
              pjt = {
                ...pjt,
                beforHeadOnly: { ...clip.beforHeadOnly, src: null },
              };
            if (pjt?.src) pjt = { ...pjt, src: null };
            if (pjt?.thumbnailUrl) delete pjt.thumbnailUrl;
            return pjt;
          }
          return clip;
        }),
        thumbnailUrl: null,
      };
      return retData;
    }),
  };
  return data;
}

export function getAIAvatarPosition(props: {
  clip: Clip;
  avatar: Model & Clothes;
  canvasWidth: number;
  canvasHeight: number;
  avatarType: "original" | undefined;
}): Clip {
  const { clip, avatar, canvasWidth, canvasHeight, avatarType } = props;
  const { imageWidth, imageHeight, sourceUrl } = (() => {
    return {
      imageWidth: avatar.deploySize.edit_width,
      imageHeight: avatar.deploySize.edit_height,
      sourceUrl: avatar.deployImage.edit_src,
    }
  })();
  const scale = (() => {
    const x = (() => {
      if (canvasWidth < clip.width * (clip.scaleX || 1)) {
        return canvasWidth / imageWidth;
      }
      return clip.width * (clip.scaleX || 1) / imageWidth;
    })();
    const y = (() => {
      if (canvasHeight - clip.top < clip.height * (clip.scaleY || 1)) {
        return (canvasHeight - clip.top) / imageHeight;
      }
      return clip.height * (clip.scaleY || 1) / imageHeight;
    })();

    if (x > y) {
      return x;
    }
    return y;
  })();

  const left = (() => {
    return (
      clip.left
      + (clip.width * (clip.scaleX || 1) / 2)
      - (imageWidth * scale / 2)
    );
  })();

  return {
    ...clip,
    avatarType,
    width: imageWidth,
    height: imageHeight,
    scaleX: scale,
    scaleY: scale,
    left,
    model: {
      ...clip.model,
      source_url: sourceUrl,
    },
  };
}

/**
 * **`DOMParser`를 사용하므로 클라이언트 사이드에서만 사용할 것!!**
 * 
 * 바이트 계산을 위해 HTML을 텍스트로 변환함\
 * 바이트 계산만 하면 되기 때문에 최종 결과물이\
 * 사용자에게 보이거나, 저장되는 것을 고려할 필요 없음
 * 바이트 계산이 잘 되도록 가공만 하면 됨
 *
 * 규칙
 * - p 아래의 모든 태그는 제거
 *   - 제거된 태그는 1글자 취급 (. 으로 대체)
 *   - 단 br은 완전히 제거 (개행은 p의 개수로 대체)
 * - p는 개행으로 치환
 *   - 단 마지막 p는 개행으로 치환하지 않음
 * - body > div는 제거
 *   - pause의 경우 body > div에 있음
 *   - pause도 1글자 취급해야 하지만 개행 1글자를 이미 가지고 있어서
 *   - 다른 p 아래의 모든 태그에서의 처리와 달리 .으로 대체하지 않고
 *   - 그냥 제거함
 */
const parseHTMLToTextForCalculateBytesForClient = (html: string) => {
  if (typeof window === 'undefined') {
    return ''
  }

  const parser = new DOMParser()
  const parsed = parser.parseFromString(html, 'text/html')

  parsed.querySelectorAll('br, body > div').forEach((node) => {
    node.remove()
  })
  parsed.querySelectorAll('p > *').forEach((node) => {
    node.replaceWith('.')
  })
  parsed.querySelectorAll('p').forEach((node) => {
    const replacingNodes: (Node | string)[] = [...node.childNodes]

    if (!!node.nextSibling) {
      replacingNodes.push('\n')
    }

    node.replaceWith(...replacingNodes)
  })

  return parsed.body.textContent || ''
}

/**
 * **서버/클라이언트 모두 동작하지만 속도가 느림**
 */
const parseHTMLToTextForCalculateBytesForUniversal = (html: string) => {
  const $ = load(html)

  $('br, body > div').remove()
  $('p > *, body > :not(p)').replaceWith('.')
  $('p').each((_, node) => {
    $(node).before(node.childNodes)

    if (!!node.nextSibling) {
      $(node).after('\n')
    }

    $(node).remove()
  })

  return $('body').text() || ''
}


/**
 * 성능 이슈 발생할 것 같아서 바이트를 빠르게 계산하는 방법들을 찾아봤음\
 * 그런데 현재 우리 상황에서는 유의미하게 큰 차이가 없어서 new Blob 방식을 사용함\
 *
 * #### 테스트 방법
 * - 29000 bytes * 25 scenes
 *
 * #### 출처
 * - https://programmingsummaries.tistory.com/239
 * - https://gist.github.com/mathiasbynens/1010324
 * - https://dev.to/rajnishkatharotiya/get-byte-size-of-the-string-in-javascript-20jm
 */
const calculateBytes1 = (text: string) => {
  return new Blob([text]).size
}

const calculateBytes2 = (text: string) => {
  let bytes = 0

  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i)
    if (charCode <= 0x7f) {
      bytes += 1
    } else if (charCode <= 0x7ff) {
      bytes += 2
    } else if (charCode <= 0xffff) {
      bytes += 3
    } else {
      bytes += 4
    }
  }
  return bytes
}

const calculateBytes3 = (text: string) => {
  return text.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length
}

const calculateBytes4 = (text: string) => {
  let bytes = 0
  let charCode = 0
  for (
    let i = 0;
    (charCode = text.charCodeAt(i++));
    bytes += charCode >> 11 ? 3 : charCode >> 7 ? 2 : 1
  );
  return bytes
}

const calculateBytes5 = (text: string) => {
  return new TextEncoder().encode(text).length
}

/**
 * 스크립트의 바이트 계산
 */
export const calculateScriptBytes = (script: string) => {
  let plainText = ''

  if (typeof window !== 'undefined') {
    plainText = parseHTMLToTextForCalculateBytesForClient(script)
  } else {
    plainText = parseHTMLToTextForCalculateBytesForUniversal(script)
  }

  return calculateBytes1(plainText)
}
