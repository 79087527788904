import { GENDER } from './gender'

export const VOICE_VENDER = {
  DEEPBRAINAI: 'deepbrainai',
  ELEVENLABS: 'elevenlabs',
  GOOGLE: 'google',
  AMAZON: 'amazon',
  VOLCENGINE: 'volcengine',
  ALIBABA: 'alibaba',

  VOICE_CLONE: 'voiceclone',
} as const
export type VoiceVender = (typeof VOICE_VENDER)[keyof typeof VOICE_VENDER]

export const VISIBLE_VOICE_VENDERS = [
  VOICE_VENDER.DEEPBRAINAI,
  VOICE_VENDER.ELEVENLABS,
  // VOICE_VENDER.VOLCENGINE,
  VOICE_VENDER.GOOGLE,
  VOICE_VENDER.AMAZON,
  // VOICE_VENDER.ALIBABA,
] as const

export const VOICE_VENDER_PRIORITY: {
  [key: string]: number
} = {
  [VOICE_VENDER.DEEPBRAINAI]: 1,
  [VOICE_VENDER.ELEVENLABS]: 2,
  [VOICE_VENDER.VOLCENGINE]: 3,
  [VOICE_VENDER.GOOGLE]: 4,
  [VOICE_VENDER.AMAZON]: 5,
  [VOICE_VENDER.ALIBABA]: 6,
}

export const DEEPBRAINAI_VOICE_DEFAULTS = {
  /**
   * 이안 Ian
   */
  [GENDER.MALE]: 'M000351175',
  /**
   * 페이지 Paige
   */
  [GENDER.FEMALE]: 'M000187655',
}
