/* eslint-disable @typescript-eslint/naming-convention */
import axios from "axios";
import { runtimeEnv } from "./runtime-utils";
import { Session } from "next-auth";
import { genResWithCode, genResWithOK, printPrettyLog } from "./api-utils";
import { ERR_CODE_4000, ERR_CODE_4004 } from "./api-constants";
import { PlanType } from "@/components/editor/stripe/Cell";
import { proProducts, starterProducts } from "@/components/editor/stripe/constants";
import { ObjectId } from "mongodb";
import memoize from "./memoize";
import { synchronized } from "@/lib/synchronized-utils";

/**
 * 구독 관련 체크 로직을 처리하는 UTIL
 *
 * @author Ben
 * @date 2023.08.04
 * @param param0
 * @returns
 */
interface CheckSubParams {
  session?: Session;
  apiUser?: any;
  scenes: Array<any>;
  time: number;
  isAutomation?: boolean;
  isFirstGpt?: boolean;
}

// export async function getUserInfo(userId: string) {
const _getUserInfo = synchronized("_getUserInfo", async (userId: string) => {
  try {
    const params = { userId };
    const options = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      data: params,
      url: `${runtimeEnv.BK_ACCOUNT_URL}/api/studio/v3/userInfo`
    };
    const result = await axios(options);
    // console.log("----------- User INFO -----------");
    // console.log(
    //   `>> result status : ${result.status} : data : ${JSON.stringify(
    //     result.data
    //   )}`
    // );
    // console.log("---------------------------------");
    return result;
  } catch (e) {
    // console.error("[getUserInfo] - error : ", e);
    throw e;
  }
})

const getUserInfo = memoize(_getUserInfo, { tm: 15, unit: "seconds"});
export { getUserInfo };


export const getUserSubscriptions = async (userEmail?: string | null) => {
  return axios.get<
    | {
        success: true
        data: {
          plan: string
          subscriptionId?: string
          plans?: { [key: string]: any }[]
        }
      }
    | {
        success: false
        data: {
          code: string
          msg: string
        }
      }
  >(
    `${runtimeEnv.BK_ACCOUNT_URL}/api/studio/v3/subscriptions?userId=${userEmail}&product=studio3.0`,
  )
}

// 구독 상태를 체크한다.
export async function checkSubscription({
  session,
  apiUser,
  scenes,
  time,
  isAutomation,
  isFirstGpt,
}: CheckSubParams) {
  try {
    printPrettyLog("api-account-utils", "checkSubscription", {
      session,
      apiUser,
      scenes,
      time,
      isAutomation,
      isFirstGpt,
    });

    const userEmail = session ? session.user.email : apiUser?.id;

    const subscriptionRes = await axios.get(
      `${runtimeEnv.BK_ACCOUNT_URL}/api/studio/v3/subscriptions?userId=${userEmail}&product=studio3.0`
    );
    const creditRes = await axios.get(
      `${runtimeEnv.BK_ACCOUNT_URL}/api/studio/v3/credits?id=${userEmail}`
    );

    const subscription = subscriptionRes.data.data.plan;
    const { credit, automationCredit } = creditRes.data.data;
    console.log("creditRes.data.data: ", creditRes.data.data);
    
    /**
     * 2024.12.06 @june
     * 무제한 요금제 출시로, 프리플랜도 크레딧 체크를 하지 않음(영상길이만 체크)
     */
    const isUnlimitedPlan = credit === -1 || subscription === "none";
    if (time > credit && !isFirstGpt && !isUnlimitedPlan) {
      const data = {
        msg: "not enough credits",
        credit,
        needCredit: time,
        subscription,
      };
      return genResWithCode(ERR_CODE_4000, undefined, data);
    }

    /**
     * 2023.10.26 @dewei
     * Enterprise(b2b) 고객의 경우 구독권이 없어도 최대 50 scene 까지 지원.
     * time Limit X
     */
    // eslint-disable-next-line no-use-before-define
    const userInfo = await getUserInfo(userEmail);
    const { enterprise } = userInfo.data;

    const personalLimit =
      (subscription.includes("Personal") || subscription.includes("Starter")) &&
      isAutomation &&
      !subscription.includes("Unlimited") &&
      automationCredit === 0;

    if (personalLimit) {
      const data = {
        msg: "구독 권한 부족",
        credit,
        needCredit: time,
        subscription,
      };
      return genResWithCode(ERR_CODE_4004, undefined, data);
    }

    //  합성시간과 Scene 길이는 제한 두지않음

    // const noPlanLimit = (subscription === "none") && (scenes.length > Personal_SCENE_LIMIT || time > Personal_TIME_LIMIT);
    // const PersonalLimit = subscription.includes("Personal") && (scenes.length > Personal_SCENE_LIMIT || time > Personal_TIME_LIMIT);
    // const proLimit = subscription.includes("Pro") && (scenes.length > PRO_SCENE_LIMIT || time > PRO_TIME_LIMIT);

    // if ((noPlanLimit || PersonalLimit || proLimit) && !enterprise || b2bLimit) {
    //   const data = {
    //     msg: "구독 권한 부족",
    //     credit,
    //     needCredit: time,
    //     subscription
    //   }
    //   return genResWithCode(ERR_CODE_4004, undefined, data)
    // }

    let plan =
      subscription.includes("Pro") || subscription.includes("Team")
        ? "Team"
        : "Personal";
    plan = enterprise ? "enterprise" : plan;
    return genResWithOK({ plan });
  } catch (e) {
    console.error("[checkSubscription] - error : ", e);
    throw e;
  }
}

// 워터마크 표출여부를 조회한다.
export async function isWatermark(userId: string) {
  if (!userId || userId === "guest") {
    return true;
  } else {
    const userInfo = await getUserInfo(userId);
    // console.log("!@#!@#!@# userInfo", userInfo.data);
    if (userInfo.status === 200) {
      const { enterprise: b2b, useWatermark, plan, permission } = userInfo.data;
      if (permission === "admin") {
        // 관리자는 무조건 뺌
        return false;
      } else {
        if (plan !== "none") return false; //구매유저
        if (b2b) return false; //b2b유저
        if (!useWatermark) return false; //useWatermark 가 false 로 체크된 경우 무조건
        return true; //그외 모든 경우 true
      }
    } else {
      return true;
    }
  }
}

export async function checkUserSubscriptionType({
  userId,
  step,
  plan,
  period,
}: {
  userId: string;
  step: string;
  plan: string;
  period: string;
}) {
  try {
    const { BK_ACCOUNT_URL } = runtimeEnv;

    const { data: userInformation } = await getUserInfo(userId);

    const planName = userInformation?.plan?.toLowerCase();

    const userInterval = userInformation?.interval;
    const planOrder = +userInformation?.planOrder - 1;

    if (userInformation.plan === "none") {
      return "start"
    } else {
      const planAPIendPoint = `${BK_ACCOUNT_URL}/api/plans?product=studio3.0`;
      const planAPIresult = await axios.get(planAPIendPoint);
      const plans = planAPIresult.data;

      console.log("!@#!@#!@# ", {plans});
      const personalPlans = plans
        ?.filter((item: PlanType) => item?.planName?.includes("Personal"))
        .sort((a: PlanType, b: PlanType) => a.order - b.order);

      const teamPlans = plans
        ?.filter((item: PlanType) => item?.planName?.includes("Team"))
        .sort((a: PlanType, b: PlanType) => a.order - b.order);

      if (!userInformation?.company?.isMaster) {
        return "fail";
      }

      if (planName?.includes("personal")) {
        const myPlan = personalPlans[planOrder];
        const myPlanPricePerMonth = +myPlan?.pricePerMonth;
        const myPlanPricePerYear = +myPlan?.pricePerYear;
        let subscriptionPricePerYear = 0;
        let subscriptionPricePerMonth = 0;

        if (plan === "personal") {
          subscriptionPricePerYear = +personalPlans[+step]?.pricePerYear;
          subscriptionPricePerMonth = +personalPlans[+step]?.pricePerMonth;
        } else if (plan === "team") {
          subscriptionPricePerYear = +teamPlans[+step]?.pricePerYear;
          subscriptionPricePerMonth = +teamPlans[+step]?.pricePerMonth;
        }

        if (userInterval?.includes("year")) {
          if (period === "yearly") {
            if (subscriptionPricePerYear < myPlanPricePerYear) {
              return "downgrade";
            } else if (subscriptionPricePerYear > myPlanPricePerYear) {
              return "upgrade";
            } else if (subscriptionPricePerYear === myPlanPricePerYear) {
              return "current";
            }
          } else if (period === "monthly") {
            if (subscriptionPricePerMonth < myPlanPricePerYear) {
              return "downgrade";
            } else if (subscriptionPricePerMonth > myPlanPricePerYear) {
              return "upgrade";
            } else if (subscriptionPricePerMonth === myPlanPricePerYear) {
              return "current";
            }
          }
        } else if (userInterval?.includes("month")) {
          if (period === "yearly") {
            if (subscriptionPricePerYear < myPlanPricePerMonth) {
              console.log("checkUserSubscription here ");
              return "downgrade";
            } else if (subscriptionPricePerYear > myPlanPricePerMonth) {
              return "upgrade";
            } else if (subscriptionPricePerYear === myPlanPricePerMonth) {
              return "current";
            }
          } else if (period === "monthly") {
            if (subscriptionPricePerMonth < myPlanPricePerMonth) {
              return "downgrade";
            } else if (subscriptionPricePerMonth > myPlanPricePerMonth) {
              return "upgrade";
            } else if (subscriptionPricePerMonth === myPlanPricePerMonth) {
              return "current";
            }
          }
        }
      } else if (planName?.includes("starter")) {
        const starterPricePerMonth = +starterProducts[planOrder]?.monthlyPrice;
        const starterPricePerYear = +starterProducts[planOrder]?.yearlyPrice;
        let subscriptionPricePerYear = 0;
        let subscriptionPricePerMonth = 0;

        if (plan === "personal") {
          subscriptionPricePerYear = +personalPlans[+step]?.pricePerYear;
          subscriptionPricePerMonth = +personalPlans[+step]?.pricePerMonth;
        } else if (plan === "team") {
          subscriptionPricePerYear = +teamPlans[+step]?.pricePerYear;
          subscriptionPricePerMonth = +teamPlans[+step]?.pricePerMonth;
        }

        if (userInterval === "month") {
          if (period === "yearly") {
            if (starterPricePerMonth < subscriptionPricePerYear) {
              return "upgrade";
            } else if (starterPricePerMonth > subscriptionPricePerYear) {
              return "downgrade";
            } else if (starterPricePerMonth === subscriptionPricePerYear) {
              return "current";
            }
          } else if (period === "monthly") {
            if (starterPricePerMonth < subscriptionPricePerMonth) {
              return "upgrade";
            } else if (starterPricePerMonth > subscriptionPricePerMonth) {
              return "downgrade";
            } else if (starterPricePerMonth === subscriptionPricePerMonth) {
              return "upgrade";
            }
          }
        } else if (userInterval === "year") {
          if (period === "yearly") {
            if (starterPricePerYear < subscriptionPricePerYear) {
              return "upgrade";
            } else if (starterPricePerYear > subscriptionPricePerYear) {
              return "downgrade";
            } else if (starterPricePerYear === subscriptionPricePerYear) {
              return "upgrade";
            }
          } else if (period === "monthly") {
            if (starterPricePerYear < subscriptionPricePerMonth) {
              return "upgrade";
            } else if (starterPricePerYear > subscriptionPricePerMonth) {
              return "downgrade";
            } else if (starterPricePerYear === subscriptionPricePerMonth) {
              return "upgrade";
            }
          }
        }
      } else if (planName?.includes("team")) {
        // 현재 다운그레이드 불가능

        // plan === team
        const myPlan = teamPlans[planOrder];
        const myPlanPricePerMonth = +myPlan?.pricePerMonth;
        const myPlanPricePerYear = +myPlan?.pricePerYear;

        let subscriptionPricePerYear = 0;
        let subscriptionPricePerMonth = 0;

        if (plan === "personal") {
          subscriptionPricePerYear = +personalPlans[+step]?.pricePerYear;
          subscriptionPricePerMonth = +personalPlans[+step]?.pricePerMonth;
        } else if (plan === "team") {
          subscriptionPricePerYear = +teamPlans[+step]?.pricePerYear;
          subscriptionPricePerMonth = +teamPlans[+step]?.pricePerMonth;
        }

        if (plan === "personal") {
          return "downgrade";
        }

        if (userInterval?.includes("year")) {
          if (period === "yearly") {
            if (subscriptionPricePerYear < myPlanPricePerYear) {
              return "downgrade";
            } else if (subscriptionPricePerYear > myPlanPricePerYear) {
              return "upgrade";
            } else if (subscriptionPricePerYear === myPlanPricePerYear) {
              return "current";
            }
          } else if (period === "monthly") {
            if (subscriptionPricePerMonth < myPlanPricePerYear) {
              return "downgrade";
            } else if (subscriptionPricePerMonth > myPlanPricePerYear) {
              return "upgrade";
            } else if (subscriptionPricePerMonth === myPlanPricePerYear) {
              return "current";
            }
          }
        } else if (userInterval?.includes("month")) {
          if (period === "yearly") {
            if (subscriptionPricePerYear < myPlanPricePerMonth) {
              return "downgrade";
            } else if (subscriptionPricePerYear > myPlanPricePerMonth) {
              return "upgrade";
            } else if (subscriptionPricePerYear === myPlanPricePerMonth) {
              return "current";
            }
          } else if (period === "monthly") {
            if (subscriptionPricePerMonth < myPlanPricePerMonth) {
              return "downgrade";
            } else if (subscriptionPricePerMonth > myPlanPricePerMonth) {
              return "upgrade";
            } else if (subscriptionPricePerMonth === myPlanPricePerMonth) {
              return "current";
            }
          }
        }
      } else if (planName?.includes("pro")) {
        const proPricePerMonth = +proProducts[planOrder]?.monthlyPrice;
        const proPricePerYear = +proProducts[planOrder]?.yearlyPrice;

        let subscriptionPricePerYear = 0;
        let subscriptionPricePerMonth = 0;

        if (plan === "personal") {
          subscriptionPricePerYear = +personalPlans[+step]?.pricePerYear;
          subscriptionPricePerMonth = +personalPlans[+step]?.pricePerMonth;
        } else if (plan === "team") {
          subscriptionPricePerYear = +teamPlans[+step]?.pricePerYear;
          subscriptionPricePerMonth = +teamPlans[+step]?.pricePerMonth;
        }

        if (plan === "personal") {
          return "downgrade";
        }

        if (userInterval === "month") {
          if (period === "yearly") {
            if (proPricePerMonth < subscriptionPricePerYear) {
              return "upgrade";
            } else if (proPricePerMonth > subscriptionPricePerYear) {
              return "downgrade";
            } else if (proPricePerMonth === subscriptionPricePerYear) {
              return "upgrade";
            }
          } else if (period === "monthly") {
            if (proPricePerMonth < subscriptionPricePerMonth) {
              return "upgrade";
            } else if (proPricePerMonth > subscriptionPricePerMonth) {
              return "downgrade";
            } else if (proPricePerMonth === subscriptionPricePerMonth) {
              return "upgrade";
            }
          }
        } else if (userInterval === "year") {
          if (period === "yearly") {
            if (proPricePerYear < subscriptionPricePerYear) {
              return "upgrade";
            } else if (proPricePerYear > subscriptionPricePerYear) {
              return "downgrade";
            } else if (proPricePerYear === subscriptionPricePerYear) {
              return "upgrade";
            }
          } else if (period === "monthly") {
            if (proPricePerYear < subscriptionPricePerMonth) {
              return "upgrade";
            } else if (proPricePerYear > subscriptionPricePerMonth) {
              return "downgrade";
            } else if (proPricePerYear === subscriptionPricePerMonth) {
              return "current";
            }
          }
        }
      }
    }
  } catch (error) {
    console.log("error!!: ", error);
    return "fail";
  }
}

type CreditResource = {
  name: "text" | "image" | "video" | "ocr" | "tts" | "stt" | string;
  value: any; //number, totalToken, etc
}
export interface ChargeCreditParams {
  id: string; //email
  credit: number; 
  changeType: string; // export | generate | automation

  creditResources?: CreditResource[];
  backendTaskId?: ObjectId | string;
  exportedBy?: any; //email
  version?: string;
  projectId?: ObjectId | string;
  automationJobId?: ObjectId | string;
  functionName?: string;
}

//account credit 차감 요청
export async function chargeCredit(chargeCreditParams: ChargeCreditParams) {
  try {
    // console.log("!@#!@#!@# chargeCreditParams ", chargeCreditParams);
    const { BK_STUDIO_URL } = runtimeEnv;
    const { data: charge } = await axios.post(`${BK_STUDIO_URL}/api/payment/credits`, chargeCreditParams);
    return charge;
  } catch (error) {
    console.log("!@#!@#!@# chargeCredit error : ", error);
    return null
  }
}

export async function getUserInfoForProjectCreate(userId: string) {
  let userPlan = "none"; // none | enterprise | Starter | Personal | Pro | ....
  let companyId = "";
  let userType = 'customer'

  if (userId === "guest") {
    return { userPlan: "none", companyId: null };
  }
  
  const { data: userInfo } = await getUserInfo(userId);
  if (
    userInfo?.plan?.toLowerCase()?.includes("starter") ||
    userInfo?.plan?.toLowerCase()?.includes("personal")
  ) {
    userPlan = "personal";
  }

  if (
    userInfo?.plan?.toLowerCase()?.includes("pro") ||
    userInfo?.plan?.toLowerCase()?.includes("team")
  ) {
    userPlan = "team";
  }

  if (userInfo?.enterprise) {
    userPlan = "enterprise";
  }
  companyId = userInfo?.companyId;

  const pattern = /.*@(deepbrain|moneybrain|teml|ruu)/;

  if (pattern.test(userId) || userId.includes('+')) {
    userType = 'deepbrain'
  } else if (userId === 'guest' || userPlan === 'none') {
    userType = 'guest';
  }


  return { userPlan, companyId, userType };
}
