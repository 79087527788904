"use client";

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    html,
    body {
      padding: 0;
      margin: 0;
      max-width: 100vw;
      overflow-x: hidden;
    }

    *[data-flex] {
      flex: 1;
    }

    .ant-tour:not(.screen-recorder-tour) {
      width: 300px !important;

      .ant-tour-content{
        width: 300px !important;
        line-height: 1 !important;
      }
    }

    .ant-tour.screen-recorder-tour {
      width: 330px;

      .ant-tour-header,
      .ant-tour-footer {
        display: none;
      }
    }
  }

  /*
    portal 글로벌 스타일
  */
  #portal-root {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
