import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  refetchOnFocus: false,
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/payment",
  }),
  tagTypes: ["Payment"],
  endpoints: (builder) => ({
    getPlans: builder.query<RestApiResponse<any>, { product?: string }>({
      query: ({ product = "studio3.0" }) => ({
        url: `/plan?product=${product}`,
        method: "GET",
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getModelsByPlan: builder.query<RestApiResponse<any>, { product?: string }>({
      query: () => ({
        url: "/models",
        method: "GET",
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const { useGetPlansQuery, useGetModelsByPlanQuery } = paymentApi;
