import { Clip, Scene } from "@/types/type";
import { Middleware } from "@reduxjs/toolkit";
import debounce from "lodash.debounce";

import { getHeadOnly, thumbnailScene } from "@/lib/canvas-utils";
import { projectActions } from "../stores/projectStore";
import { checkDuplicatedClipId, checkUnstableClipLayer } from "@/lib/normalize-utils";

const debounceThumbnail = debounce(
  async (
    orientation: "landscape" | "portrait",
    scene: Scene,
    width: number,
    height: number,
    sceneIdx: number,
    dispatch: any,
  ) => {
    const thumbnailUrl = await thumbnailScene<string>({
      orientation,
      scene,
      width,
      height,
      exportType: "objectUrl",
    });

    if (typeof window !== "undefined") {
      setTimeout(() => {
        dispatch(projectActions.thumbnailScene({ sceneIdx, thumbnailUrl }));
      });
    }
  }
);

const debounceUpdateProject = debounce(
  async (project, dispatch: any) => {
    if (typeof window !== "undefined") {
      setTimeout(() => {
        dispatch(projectActions.fetchUpdateProject(project));
      })
    }
  },
  1000
);

const debounceDeleteProject = debounce(
  async (project, dispatch: any) => {
    if (typeof window !== "undefined") {
      setTimeout(() => {
        dispatch(projectActions.fetchDeleteProject(project));
      })
    }
  },
  1000
);


const projectMiddleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    // TODO undo/redo
    // capture scene
    switch (action.type) {
      case "project/changeBackground":
      case "project/changeObject":
      case "project/deleteObject":
      case "project/addObjectAsync/fulfilled":
      case "project/objectModified":
      case "project/objectPositionChange":
      case "project/changeModelHeadOnly":
      case "project/pasteClipBoards":
      case "project/cutAndSetClipBoards":
      case "project/objectsAlignChange":
      case "project/addScene":
      case "project/removeScene":
      case "project/moveScene":
      case "project/objectMoveByKeyIn":
      case "project/objectsAngleChange":
      case "project/changeModelApplyAll":
      case "project/deleteAudioObjectById":
      case "project/addAllTemplateScene":
      case "project/addSelectTemplateScene":
      case "project/removeWatermark":
      case "project/setBackgroundAudio":
      case "project/changeCanvasRatio":
      case "project/setCaptionText":
      case "project/setCaptionActions":
      case "project/changeTransition":
      case "project/changeLogoImage":
      case "project/changeConversationItemModel":
      case "project/initScripts":      
      case "project/trimAudio":
      case "project/callCropImage":
      case "project/trimVideo":
      case "project/toggleModelBackground":
      case "project/setClipSize":
      case "project/addCaptionAsync/fulfilled":
      case "project/changeEffectClipAsync/pending":
      case "project/setScreenRecordingCamClipping":
      case "project/applyAllTransition":
        if(
          action.type === "project/objectModified" 
          && action.payload.name 
          && action.payload.name.startsWith("cropimage")
        ) break;
        if(action.type === "project/addObjectAsync/fulfilled") {
          // console.log("!@#!@#!@# ", action.payload);
          //모델 변경인경우는 모델 삭제 이전 상태를 저장해야해서 더 빨리 저장한다.(addObjectAsync thunk 에서 미리 저장);
          if(action.payload.type === "aiModel") break;  
        }
        // trim video
        if (
          action.type === "project/trimVideo"
          && action.payload.type !== "trim"
        ) {
          break;
        }

        const state = getState();
        const { project } = state.projectReducer;
        dispatch(projectActions.saveCurrentProjectState(project));
        break;
      default:
        break;  
    }

    next(action);
    if (
      ["projectApi/executeQuery/fulfilled", "project/setCanvas"].includes(
        action.type
      )
    ) {
      const state = getState();
      const { project } = state.projectReducer;
      if (project) {
        project.scenes?.reduce(
          async (acc: Promise<void>, scene: Scene, sceneIdx: number) => {
            await acc;
            const reScene: Scene = { ...scene, clips: await Promise.all(scene.clips.map( async (v) => {
              if (
                v.type === "aiModel"
                && v.headOnly
                && !v.headOnly.src
              ) {
                return { ...v, headOnly: await getHeadOnly({ clip: v, shape: v.headOnly.shape })};
              }
              return v;
            }))};

            const thumbnailUrl = await thumbnailScene<string>({
              scene: reScene,
              orientation: project.orientation,
              exportType: "objectUrl",
            });
            if (typeof window !== "undefined") {
              setTimeout(() => {
                dispatch(
                  projectActions.thumbnailScene({ scene: reScene, sceneIdx, thumbnailUrl })
                );
              });
            }
          },
          Promise.resolve()
        );
      }
    }
    switch (action.type) {
      case "project/changeBackground":
      case "project/changeObject":
      case "project/deleteObject":
      case "project/addObjectAsync/fulfilled":
      case "project/objectModified":
      case "project/objectPositionChange":
      case "project/changeModelHeadOnly":
      case "project/pasteClipBoards":
      case "project/cutAndSetClipBoards":
      case "project/objectsAlignChange":
      case "project/undo":
      case "project/redo":
      case "project/objectMoveByKeyIn":
      case "project/changeAudioScriptFile":
      case "project/changeAudioScriptTrim":
      case "project/changeAudioScriptUseSts":
      case "project/changeProjectName":
      case "project/objectsAngleChange":
      case "project/changeModelApplyAll":
      case "project/deleteAudioObjectById":
      case "project/addAllTemplateScene":
      case "project/addSelectTemplateScene":
      case "project/removeWatermark":
      case "project/setBackgroundAudio":
      case "project/changeCanvasRatio":
      case "project/objectMoveForRatioAsync":
      case "project/setCaptionText":
      case "project/setCaptionActions":
      case "project/changeTransition":
      case "project/replaceAutomationProject":
      case "project/changeLogoImage":
      case "project/initScripts":
      case "project/trimAudio":
      case "project/cropObjectAdd":
      case "project/toggleModelBackground":
      case "project/setClipSize":
      case "project/addCaptionAsync/fulfilled":
      case "project/changeEffectClipAsync/fulfilled":
      case "project/applyAllTransition":
        {
          const state = getState();
          const { project, selectedSceneIdx } = state.projectReducer;
          const { sceneIdx = selectedSceneIdx } = action.payload || {};
          const scene = project.scenes[sceneIdx];

          // console.log("!@#!@#!@# objectModified", action)
          // debounceChangeProjectName(project.name, dispatch);
          debounceUpdateProject(project, dispatch);
          debounceThumbnail(project.orientation, scene as Scene, 208, 117, sceneIdx, dispatch);
          //translate default 프로젝트가 변경되는경우 flag 변경
          if(project.translateDefault) dispatch(projectActions.setTranslateDefaultProjectChanged(true));
          if (
            ([
              "project/changeBackground",
              "project/changeModelHeadOnly",
              "project/changeLogoImage"
            ].includes(action.type) && action.payload.isAll) 
            || ([
              "project/undo",
              "project/redo",
              "project/changeModelApplyAll",
              "project/removeWatermark",
              "project/changeCanvasRatio",
              "project/objectMoveForRatioAsync",
              "project/replaceAutomationProject",
              "project/addCaptionAsync/fulfilled",
            ].includes(action.type))
            || ([
              "project/changeModelHeadOnly"
            ].includes(action.type) && action.payload?.isModelDelete)
          ) {
            for (let idx = 0; idx < project.scenes.length; idx++) {
              if (idx === sceneIdx) continue;
              const s = project.scenes[idx];
              thumbnailScene<string>({
                scene: s,
                orientation: project.orientation,
                exportType: "objectUrl",
              }).then(
                (thumbnailUrl: string) => {
                  setTimeout(() => {
                    if (
                      project.scenes[sceneIdx].thumbnailUrl
                      && project.scenes[sceneIdx].thumbnailUrl.startsWith("blob:")
                    ) {
                      URL.revokeObjectURL(project.scenes[sceneIdx].thumbnailUrl);
                    }
                    dispatch(projectActions.thumbnailScene({ sceneIdx:idx, thumbnailUrl }));
                  });
                }
              )
            }
          }
        }
        break;
      case "project/addScene":
      case "project/removeScene":
      case "project/moveScene":
        {
          const state = getState();
          const { project, selectedSceneIdx } = state.projectReducer;
          const scene = project.scenes[selectedSceneIdx];
          debounceUpdateProject(project, dispatch);
          debounceThumbnail(project.orientation, scene as Scene, 208, 117, selectedSceneIdx, dispatch);
          if(project.translateDefault) dispatch(projectActions.setTranslateDefaultProjectChanged(true));

          for (let idx = 0; idx < project.scenes.length; idx++) {
            if (idx === selectedSceneIdx) continue;
            const s = project.scenes[idx];
            thumbnailScene<string>({
              scene: s,
              orientation: project.orientation,
              exportType: "objectUrl",
            }).then(
              (thumbnailUrl: string) => {
                setTimeout(() => {
                  if (
                    project.scenes[idx].thumbnailUrl
                    && project.scenes[idx].thumbnailUrl.startsWith("blob:")
                  ) {
                    URL.revokeObjectURL(project.scenes[idx].thumbnailUrl);
                  }
                  dispatch(projectActions.thumbnailScene({ sceneIdx:idx, thumbnailUrl }));
                });
              }
            )
          }
        }
        break;
      case "project/changeScript":
      case "project/changeVoice":
        {
          const state = getState();
          const { project, selectedSceneIdx } = state.projectReducer;
          const { value, sceneIdx = selectedSceneIdx } = action.payload;
          const aiModel = project?.scenes[sceneIdx]?.clips.find(
            (clip: Clip) => clip.type === "aiModel"
          );
          // console.log({ aiModel, sceneIdx, value });
          if (!aiModel) return;

          debounceUpdateProject(project, dispatch);
          if(project.translateDefault) dispatch(projectActions.setTranslateDefaultProjectChanged(true));

        }
        break;
      case "project/changeSceneTag":
      case "project/deleteSceneTag":
      case "project/updateProjectUserId":
      case "project/addConversationItem":
      case "project/removeConversationItem":
      case "project/changeConversationItemModel":
      case "project/changeConversationItemPause":
      case "project/setVideoQuality":
      case "project/setProjectLanguage":
        {
          const state = getState();
          const { project } = state.projectReducer;
          debounceUpdateProject(project, dispatch);
          if([
              "project/addConversationItem",
              "project/removeConversationItem",
              "project/changeConversationItemModel",
              "project/changeConversationItemPause",
            ].includes(action.type)) {
            if (typeof window !== "undefined") {
              setTimeout(() => {
                dispatch(
                  projectActions.changeEditorKey()
                );
              });
            }
          }
          
        }

        break;
      case "project/changeMultipleSendTag":
      case "project/deleteClipTag":
      case "project/updateClipTag":
      // 스크립트 종류 탭 변경 시 (내레이션, 컨버세이션, 오디오)
      case "project/setScriptEditorTab":
      // 스크립트 종류 변경 시 (내레이션, 컨버세이션, 오디오)
      case "project/switchSceneScriptType":
        {
          const state = getState();
          const { project } = state.projectReducer;
          debounceUpdateProject(project, dispatch);
        }
        break;
      case "project/trimVideo":
        {
          // trim video
          if (
            action.type === "project/trimVideo"
            && action.payload.type !== "trim"
          ) {
            break;
          }
          const state = getState();
          const { project } = state.projectReducer;
          debounceUpdateProject(project, dispatch);
        }
        break;
      default:
        break;
    }

    // 클립 ID / 레이어 정규화
    switch (action.type) {
      case "project/addObjectAsync/fulfilled":
      case "project/objectDuplicateAsync/fulfilled":
      case "project/addCaptionAsync/fulfilled":
      case "project/pasteClipBoards":
      case "project/cutAndSetClipBoards":
      case "project/deleteObjectById":
      case "project/deleteObject":
      case "project/initScripts":
      case "project/initCaptions":
      case "project/setCaptionActions":
      case "project/objectPositionChange":
      case "project/callCropImage":
         {
        const state = getState();
        const { project } = state.projectReducer;
  
        if (project) {
          if (checkDuplicatedClipId(project.scenes)) {
            dispatch(projectActions.normalizeClipId());
          }
          if (checkUnstableClipLayer(project.scenes)) {
            dispatch(projectActions.normalizeClipLayer());
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };

export default projectMiddleware;
