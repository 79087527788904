import { AutomationDto } from "@/types/automation";
import { 
  PayloadAction,
  createAsyncThunk, 
  createSlice, 
} from "@reduxjs/toolkit";
import type { DashboardState } from "@/types/dashboard.api";
import { AUTOMATION_CATEGORY_TITLE, AutomationCategoryTitle } from "@/constants/dashboard/automation";
import { DashboardQuickTemplate } from "@/lib/dashboard/db/dashboard-quick-template";
import { drop, find, last } from "lodash";
import { HYDRATE } from 'next-redux-wrapper';

const initialState: DashboardState= {
    pageLoading: false,
    actionLoading: false,
    showFolderModal: false,
    selectMyProject: false,
    currentProjectPage: 1,
    projectList: [],
    errorProjectList: null,
    // fetchProjectListLoading: false,
    startTemplates: {
      modalType: '',
      modalState: false,
      templateList: [],
    },
    showVideoModal: {
      videoUrl: '',
      videoTitle: '',
      showModal: false,
      projectId: '',
      projectName: '',
      isFirstSynth: false
    },
    downloadVideo: '',
    fetchLoading: false,
    showModalState: {
      modalType: '',
      modalState: false,
      projectId: '',
      videoUrl: '',
      videoTitle: '',
      projectName: '',
      deleteType: '',
      projectWorkspaceId: [],
    },
    showReviewModal: {
      modalState: false,
    },
    shareUrl: '',
    myProjects: {
      showProject: true,
      hoverItem: {
        _id: '',
        hovered: false,
      },
      selectedProject: {
        _id: '',
        name: '',
        selected: false
      }
    },
    templates: {
      showTemplate: true,
      orientation: 'landscape',
      category: '',
      showMore: false,
      searchValue: '',
    },
    folders: {
      modalType: '',
      modalState: false,
      folderName: '',
      folderDetail: {
        createdAt: '',
        folderName: '',
        isFolder: true,
        userId: '',
        _id: '',
        state: '',
      }
    },
    avatars: {
      _id: '',
      expertise: null,
      displayIndex: 0,
      id: '',
      imgPath: '',
      label: null,
      languageSupport: [],
      onSale: false,
      showDefault: true,
      videoUrl: '',
      modalState: false,
      clothesId: '',
    },
    chatGPT: {
      isWaiting: false,
      error: null,
      createChatGPTResult: null,
    },
    urlToVideo: {
      isWaiting: false,
      error: null,
      createUrlToVideoResult: null,
    },
    createChatGPTLog: {
      type: '',
      topic: '',
      _id: '',
      title: ''
    },
    projectSearch: {
      filters: {
        search: ''
      },
    },
    automationProgress: {
      progress: 0
    },
    accountUserInfo: {
      _id: "",
      plan: '',
      permission: '',
      companyId: '',
      workspaceId: '',
      userType: '1000',
      company: {
        thumb: "",
        headerThumb: "",
        name: "",
        masterUser: "",
        memberCount: 0,
        aistudios: {
          renewalRegister: false,
        }
      },
      workspace: [{
        companyId: "",
        createdAt: "",
        createdBy: "",
        id: "",
        name: "",
        status: "",
        updatedAt: "",
        _id: "",
        isDefault: false,
        aistudios: {
          seats: [],
          availableSeats: 0
        }
      }],
      id: "",
      aistudiosWorkspaces: [{
        workspaceId: '',
        companyId: '',
        userType: '',
        isDefault: true,
        companyPermission: '',
        workspacePermission: '',
        status: 'active',
        workspaceName: ''
      }],
      inviteWorkspaceLogs: [],
      myAvatar: {},
    },
    workspaceInfo: {
      decryptParams: {},
      modalInfo: {
        open: false,
        value: null,
      },
      workspaceDetail: []
    },
    workspaceList: [],
    workspaceListTotal: 0,
    inviteWorkspaceLogs: [],
    isMultipleWorkspace: false,
    selectedWorkspaceIndex: 0,
    masterUserInfo: {
      isMaster: false,
      name: '',
      userId: '',
      _id: '',
    },
    showDowngradeModal: {
      personal: false,
      team: false
    },
    workspaceModal: {
      open: false,
      value: null,
      type: '',
      content: null,
    },
    tiktokCredential: {
      cred: {
        access_token: '',
        refresh_token: ''
      }
    },
    inviteWorkspaceLink: {
      workspaceId: '',
      companyId: '',
      workspaceName: '',
      invitedBy: ''
    },
    selectDashboardCategory: {
      selected: true,
      selectedId: AUTOMATION_CATEGORY_TITLE.FOR_YOU,
      userUseCase: '',
    },
    templateOption: {
      name:'',
      category:'',
      multiLanguage: false,
      tag: [],
      voiceOnly: false
    },
    selectQuickStartTemplateHistory: null,
    showWorkspaceMenu: false,
    newFeatureGuideOpen: false,
    g2ReviewModalOpen: false,
    projectListQuery: {
      page: 0,
    },
};

const fetchProjectList = createAsyncThunk(
  "dashboard/fetchProjectList",
  async ({ page }: any) => {
    const res = await fetch(`/api/dashboard/project?page=${page}`, {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    return {data}
  }
)

const downloadVideo = createAsyncThunk(
  'dashboard/downloadVideo',
  async ({ videoUrl, videoTitle }: { videoUrl: string; videoTitle: string }) => {
    try {
      const response = await fetch(videoUrl);
      if (!response.ok) throw new Error("파일을 가져오는 데 실패했습니다.");

      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const format = videoUrl?.split('.').pop() || 'mp4';

      link.href = urlObject;
      link.setAttribute('download', `${videoTitle}.${format}`);
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(urlObject);
      document.body.removeChild(link);
    } catch (error) {
      console.error("비디오 다운로드 중 오류 발생:", error);
      throw error;
    }
  }
);


const downloadThumbnail = createAsyncThunk(
  'dashboard/downloadThumbnail',
  async ({thumbnailUrl, videoTitle}: any) => {
    const res = await fetch(thumbnailUrl, {
      method: 'GET',
      headers: {}
    }).then((data) => data.blob())
    .then((response: any) => {
      const urlObject = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');

      const format = thumbnailUrl?.split('.')?.pop() || 'png';
      console.log('>>>> format ', {format}, {thumbnailUrl})
      link.href = urlObject;
      // link.setAttribute('download', `${videoTitle}.mp4`);
      link.setAttribute('download', `${videoTitle}.${format}`);
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }
)

const fetchChatGPT = createAsyncThunk(
  'dashboard/fetchChatGPT',
  async({ topic, action, web, reqJobId }: any) => {
    const res = await fetch('/api/dashboard/textRephrase', {
      method: 'POST',
      body: JSON.stringify({ topic, action, web, reqJobId }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    console.log('fetchChatGPT fetch data ', {data})
    if(data?.success) {
      const result = data.data;
      return { result};
    } else {
      return { data };
    }
  }
)
const automation = createAsyncThunk(
  'dashboard/automation',
  async(automationDto:AutomationDto) => {
    const res = await fetch('/api/dashboard/automation', {
      method: 'POST',
      body: JSON.stringify(automationDto),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    console.log('fetchChatGPT fetch data ', {data})
    if(data?.success) {
      const result = data.data;
      return { result};
    } else {
      return { data };
    }
  }
)

const fetchUrlToVideo = createAsyncThunk(
  'dashboard/fetchUrlToVideo',
  async({ topic, action }: any) => {
    const res = await fetch('/api/dashboard/textWeb', {
      method: 'POST',
      body: JSON.stringify({ topic, action }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    console.log('urltovideo fetch async thunk ', {data});
    if(data?.success) {
      console.log('urltovideo success', {data})
      return { data };
    } else {
      console.log('urltovideo error ', {error: data.error.msg})
      return { data };
    }
  }
)

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setPageLoading: (state, action) => {
          state.pageLoading = action.payload;
        },
        setActionLoading: (state, action) => {
          state.actionLoading = action.payload;
        },
        showFolderModal: (state, action) => {
            state.showFolderModal = action.payload;
        },
        selectMyProject: (state, action) => {
            state.selectMyProject = action.payload;
        },
        currentProjectPageUpdate: (state, action) => {
          state.currentProjectPage = action.payload;
        },
        startTemplates: (state, action) => {
          return {
            ...state,
            startTemplates: {
              ...state.startTemplates,
              ...action.payload
            }
          }
        },
        showVideoModal: (state, action) => {
          const { videoUrl, videoTitle, showModal, projectId, projectName, orientation, isFirstSynth } = action.payload;
          return {
            ...state,
            showVideoModal: {
              videoUrl,
              videoTitle,
              showModal,
              projectId,
              projectName,
              orientation,
              isFirstSynth
            }
          }
        },
        showModalState: (state, action) => {
          const { modalType, modalState, videoUrl, videoTitle, projectId, projectName, deleteType, projectWorkspaceId = [], } = action.payload;
          return {
            ...state,
            showModalState: {
              modalType,
              modalState,
              videoUrl,
              videoTitle,
              projectId,
              projectName,
              deleteType,
              projectWorkspaceId,
            }
          }
        },
        shareUrl: (state, action) => {
          state.shareUrl = action.payload;
        },
        myProjects: (state, action) => {
          return {
            ...state,
            myProjects: {
              ...state.myProjects,
              ...action.payload
            }
          }
        },
        templates: (state, action) => {
          console.log('dispatch store ', {payload: action.payload})
          return {
            ...state, 
            templates: {
              ...state.templates,
              ...action.payload
            }
          }
        }, 
        folders: (state, action) => {
          return {
            ...state,
            folders: {
              ...state.folders,
              ...action.payload
            }
          }
        },
        avatars: (state, action) => {
          return {
            ...state,
            avatars: {
              ...state.avatars,
              ...action.payload
            }
          }
        }, 
        createChatGPTLog: (state, action) => {
          state.createChatGPTLog = {
            ...state.createChatGPTLog,
            ...action.payload
          }
        },
        reset: (state) => {
          const { selectedWorkspaceIndex, selectQuickStartTemplateHistory } = state;
          const newInitialState = { ...initialState, selectedWorkspaceIndex, selectQuickStartTemplateHistory };
          return newInitialState;
        },
        projectSearch: (state, action) => {
          const { search } = action.payload;
          return {
            ...state,
            projectSearch: {
              ...state.projectSearch,
              filters: {
                search
              }
            }
          }
        },
        automationProgress: (state, action) => {
          const { progress, task, slides } = action.payload?.progress;
          return {
            ...state,
            automationProgress : {
              progress,
              task,
              slides,
            }
          }
        },
        setAccountUserInfo: (state, action) => {
          const { accountUserInfo = {} } = action.payload;
          if(state.accountUserInfo._id === '') {
            state.accountUserInfo = {
              ...accountUserInfo
            }
          }
          // state.accountUserInfo = {
          //   ...state.accountUserInfo,
          //   ...accountUserInfo
          // }
        },
        setUpdateAccountWorkspace: (state, action) => {
          const { newWorkspace } = action.payload;
          console.log(`setUpdateAccountWorkspace newWorkspace: `, {newWorkspace});
          // state.accountUserInfo.workspace = [
          //   ...state.accountUserInfo.workspace,
          //   newWorkspace
          // ]
        },
        setWorkspaceList: (state, action) => {
          const { workspaceListData = [], total = 0, inviteWorkspaceLogs = [] } = action.payload;
          state.workspaceList = workspaceListData;
          state.workspaceListTotal = total;
          state.inviteWorkspaceLogs = inviteWorkspaceLogs;
          if(total > 1) {
            state.isMultipleWorkspace = true;
          }
        },
        setIsMultipleWorkspace: (state, action) =>  {
          const { isMultipleWorkspace = false } = action.payload;
          state.isMultipleWorkspace = isMultipleWorkspace;
        },
        setWorkspaceSelectedIndex: (state, action) => {
          const { index } = action.payload;
          state.selectedWorkspaceIndex = index;
        },
        setWorkspaceModal: (state, action) => {
          const { type, value, open, content = null } = action.payload;
          state.workspaceModal = {
            open,
            value,
            type,
            content,
          }
        },
        setWorkspaceInfo: (state, action) => {
          const { type, decryptObject, modalValue, workspaceDetail, updateWorkspaceDetail } = action.payload;
          switch(type) {
            case 'setDecryptParams':
              state.workspaceInfo.decryptParams = decryptObject;
              break;
            case 'modalAction':
              if(modalValue !== undefined && Object.keys(modalValue).length !== 0) {
                state.workspaceInfo.modalInfo = {
                  open: modalValue?.open,
                  value: modalValue?.value,
                }
              } 
              break;
            case 'resetDecryptParams':
              state.workspaceInfo.decryptParams = {};
              state.workspaceInfo.modalInfo = {
                open: false,
                value: null
              }
              break;
            case 'setWorkspaceDetail': 
              state.workspaceInfo.workspaceDetail = workspaceDetail;
              break;
            case 'updateWorkspaceDetail':
              state.workspaceInfo.workspaceDetail = {
                ...state.workspaceInfo.workspaceDetail, 
                ...updateWorkspaceDetail 
              };
              break;
            default: 
              break;
          }
        },
        setInviteLinkParams: (state, action: PayloadAction<{
          type: 'setDecryptParams' | 'resetDecryptParams',
          decryptObject?: {
            workspaceId: string;
            companyId: string;
            workspaceName: string;
            invitedBy: string;
          }
        }>) => {
          const { type, decryptObject } = action.payload;

          switch(type) {
            case 'setDecryptParams':
              if(decryptObject) {
                state.inviteWorkspaceLink = decryptObject;
              }
              break;
            case 'resetDecryptParams':
              state.inviteWorkspaceLink = {
                workspaceId: '',
                companyId: '',
                workspaceName: '',
                invitedBy: ''
              }
              break;
            default:
              break;
          }

        },
        setMasterUserInfo: (state, action) => {
         const { value } = action.payload;
         if(value) {
          state.masterUserInfo = value;
         }
        },
        setShowDowngradeModal: (state, action) =>{
          const { type, value} = action.payload;
          state.showDowngradeModal = {
            ...state.showDowngradeModal,
            [type]: value
          }
        },
        setSelectDashboardCategory: (state, action: PayloadAction<{
          selected: boolean,
          selectedId: AutomationCategoryTitle,
        }>) => {
          const { selected, selectedId } = action.payload;
          state.selectDashboardCategory = {
            ...state.selectDashboardCategory,
            selected,
            selectedId
          }
        },
        setSelectDashboardCategoryUseCase: (state, action: PayloadAction<{
          userUseCase: string
        }>) => {
          const { userUseCase } = action.payload;
          state.selectDashboardCategory.userUseCase = userUseCase;
        },
        setTemplateOption: (state, action) => {
          const { name, category, tags, multiLanguage, voiceOnly, type } = action.payload
          state.templateOption = {
            name, category, tags, multiLanguage, voiceOnly, type
          }
        },
        /**
         * 즐겨찾기 기능 대신 사용자가 눌렀던 히스토리 가지고 있는다
         * 최대 5개로 유지시킴(현재기준)
         */
        setSelectQuickStartTemplateHistory: (state, action: PayloadAction<{
          selectQuickStartTemplate: DashboardQuickTemplate
        }>) => {
          const { selectQuickStartTemplate } = action.payload;
          if(state.selectQuickStartTemplateHistory === null) {
            state.selectQuickStartTemplateHistory = [selectQuickStartTemplate]
          } else {
            const lastTemplate = last(state.selectQuickStartTemplateHistory);

            if(!lastTemplate || lastTemplate.key !== selectQuickStartTemplate.key) {
              if(state.selectQuickStartTemplateHistory.length > 5) {
                state.selectQuickStartTemplateHistory = drop(state.selectQuickStartTemplateHistory, 1);
              }
            }
            state.selectQuickStartTemplateHistory.push(selectQuickStartTemplate);
          }
        },
        /**
         * Workspace DropdownMenu를 대시보드 어디에서 누르든 닫기/열기 컨트롤 할 수 있도록 하게끔 변경
         */
        setShowWorkspaceMenu: (state, action: PayloadAction<{ isShow: boolean }>) => {
          const { isShow } = action.payload;
          state.showWorkspaceMenu = isShow;
        },
        setNewFeatureGuideOpen:(state, action: PayloadAction<{ isShow: boolean }>) => {
          const { isShow } = action.payload;
          state.newFeatureGuideOpen = isShow;
        },
        setG2ReviewModalOpen:(state, action: PayloadAction<{ isShow: boolean }>) => {
          const { isShow } = action.payload;
          state.g2ReviewModalOpen = isShow;
        },
        /**
         * projectListQuery rtk-query 담당
         */
        setProjectListQuery: (state, action: PayloadAction<{ page: number }>) => {
          const { page } = action.payload;
          state.projectListQuery.page = page;
        },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchProjectList.pending, (state, action) => {
        state.fetchProjectListLoading = true;
      })
      .addCase(fetchProjectList.fulfilled, (state, action) => {
        state.fetchProjectListLoading = false;
        state.projectList = action.payload.data;
      })
      .addCase(fetchProjectList.rejected, (state, action) => {
        state.fetchProjectListLoading = false;
        state.errorProjectList = action.error;
      })
      builder.addCase(downloadVideo.pending, (state, action) => {
        state.fetchLoading = true;
      })
      .addCase(downloadVideo.fulfilled, (state, action) => {
        state.fetchLoading = false;
      })
      .addCase(downloadVideo.rejected, (state, action) => {
        state.errorProjectList = action.error;
      })
      builder.addCase(fetchChatGPT.pending, (state, action) => {
        state.chatGPT.isWaiting = true;
      }).addCase(fetchChatGPT.fulfilled, (state, action) => {
        state.chatGPT.isWaiting = false;
        console.log('createGPT result action ', {action: action.payload})
        state.chatGPT.createChatGPTResult = {
          ...action.payload
        };
      }).addCase(fetchChatGPT.rejected, (state, action) => {
        state.chatGPT.isWaiting = false;
        state.chatGPT.error = action.error;
      })
      builder.addCase(fetchUrlToVideo.pending, (state, action) => {
        state.urlToVideo.isWaiting = true;
      }).addCase(fetchUrlToVideo.fulfilled, (state, action) => {
        state.urlToVideo.isWaiting = false;
        console.log('urlToVideo result action ', {action: action.payload});
        state.urlToVideo.createUrlToVideoResult = {
          ...action.payload
        }
      }).addCase(fetchUrlToVideo.rejected, (state, action) => {
        state.urlToVideo.isWaiting = false;
        state.urlToVideo.error = action.error;
      })
      builder.addMatcher(
        (action) => {
          return action.type === HYDRATE;
        },
        (state, action) => {
          const getAccountUserInfoQueryValues =
            Object.entries(action.payload.dashboard_data.queries)
              .filter(([key]) => key.startsWith("getAccountUserInfo"))
              .map(([, value]) => value);

          if (getAccountUserInfoQueryValues.length > 0) {
            const accountUserInfo =
              (getAccountUserInfoQueryValues[0] as any).data || {};

            state.accountUserInfo = { ...accountUserInfo };

            const plan = accountUserInfo.plan || "";

            if (
              plan.toLocaleLowerCase().includes("team") ||
              plan.toLocaleLowerCase().includes("pro") ||
              plan.toLocaleLowerCase().includes("enterprise") ||
              accountUserInfo.enterprise
            ) {
              const workspaceList = accountUserInfo.workspaceList || [];
              const workspaceListTotal = accountUserInfo.workspaceListTotal || 0;
              const inviteWorkspaceLogs = accountUserInfo.inviteWorkspaceLogs || [];

              state.workspaceList = workspaceList;
              state.workspaceListTotal = workspaceListTotal;
              state.inviteWorkspaceLogs = inviteWorkspaceLogs;

              if (workspaceListTotal > 1) {
                state.isMultipleWorkspace = true;
              }
            }
          }
        }
      );
    }
})

export default dashboardSlice.reducer;
export const dashboardActions = { fetchProjectList, downloadVideo, downloadThumbnail, fetchChatGPT, fetchUrlToVideo, automation, ...dashboardSlice.actions };