/* eslint-disable @typescript-eslint/naming-convention */
import RTLArabic from 'rtl-arabic';

import {
  AddShapeProps,
  AddTextBoxProps,
  AnimationProps,
  Clip,
  EditProps,
  Fps,
  InitCanvasSceneProps,
  InsertObjectProps,
  Scene,
  ThumbnailSceneProps,
  AnimationCanvasSceneProps as DrawCanvasSceneProps,
  AddCaptionsProps,
  Caption,
  DrawCaptionsProps,
  SceneAnimationProps,
  SceneAnimationEasingName,
  SceneAnimationEasingFunction,
  SceneAnimationObject,
} from "../types/type";

import FontFaceObserver from "fontfaceobserver";

import fontList from "./canvas-fonts.json";
import { getSVGPathDefinition } from "./image-utils";
import { runtimeEnv } from "./runtime-utils";
import { base64ToBlob } from "./base64-to-blob-utils";

import { fabric as fabricModule } from "fabric";
import Fabric from "fabric/fabric-impl";
import FabricCustom from '@/types/fabricCustom';

import {
  EffectImageFabricObject,
  EffectFabricObject,
  EffectFabricObjectClass,
  EffectVideoFabricObject,
  EffectVideoFabricObjectClass,
} from './canvas-utils/effect-clip-fabric-object'

declare global {
  interface Window { _canvas_holder: any; }
}

const fabric = fabricModule as typeof fabricModule & {
  runningAnimations: {
    cancelAll: () => any[];
  };
  nodeCanvas: any;
  devicePixelRatio?: number;
};

if (typeof window !== 'undefined' && !!fabric) {
  if ((fabric.devicePixelRatio ?? 1) < 2) {
    fabric.devicePixelRatio = 2
  }
}

function disposeCanvas(canvas:any) {
  if (canvas) {
    canvas.getObjects().forEach((row: any) => {
      if (row.dispose) {
        row.dispose();
      }
    });

    canvas.dispose();
  }
}

export const canvasHolder: { canvas?: Fabric.Canvas } = new Proxy(
  { canvas: undefined },
  {
    get(target, key) {
      if (key !== "canvas") return undefined;
      if (target.hasOwnProperty(key)) return target[key];

      if (typeof window !== "undefined") {
        target[key] = window._canvas_holder?.[key];
        return window._canvas_holder?.[key];
      }
      return undefined;
    },

    set(target, key, value) {
      if (key !== "canvas") return false;
      target[key] = value;
      if (typeof window !== "undefined") {
        if (!window._canvas_holder) {
          window._canvas_holder = {};
        }
        window._canvas_holder[key] = value;
      }
      return true;
    },
  }
);

export async function createHeadOnly(params: {
  clip: any;
  imagePath?: string;
  shape?: "round" | "rectangle";
}) {
  const { clip, imagePath = "", shape = "round" } = params;
  // 1. 얼굴 사이즈를 가져와서 Reect 사이즈를 구성한다.
  const { model } = clip;
  const {
    headCenterX,
    headCenterY,
    spaceT,
    spaceL,
    headWidth,
    headHeight,
  } = model.editor;

  const { _headCenterX, _headCenterY, _headWidth, _headHeight } = (() => {
    const callRender = typeof window === "undefined";
    const instantAvatar = clip?.avatarType && clip?.avatarType !== 'original';
    const hasValues = [
      "reHeadCenterX",
      "reHeadCenterY",
      "reHeadWidth",
      "reHeadHeight",
    ].every((key) => (
      key in model.editor && typeof model.editor[key] === "number"
    ));

    if (instantAvatar && callRender && hasValues) {
      return {
        _headCenterX: model.editor.reHeadCenterX,
        _headCenterY: model.editor.reHeadCenterY,
        _headWidth: model.editor.reHeadWidth,
        _headHeight: model.editor.reHeadHeight,
      }
    }

    return {
      _headCenterX: headCenterX,
      _headCenterY: headCenterY,
      _headWidth: headWidth,
      _headHeight: headHeight,
    }
  })();

  const offsetX = imagePath === "" ? 0 : spaceL;
  const offsetY = imagePath === "" ? 0 : spaceT;
  
  // 2. 배경은 투명으로 하고 흰색 원을 그린다.
  const canvas: FabricCustom.StaticCanvas = 
    new fabric.StaticCanvas(null, { backgroundColor: "transparent" });
  
  canvas.name = "headonly";
  const headRate = 2.8;
  const offsetTop = 20;
  const { width, height } = (() => {
    const size = _headWidth * headRate;
    if (shape === "round") {
      return { width: size, height: size };
    }
    if (shape === "rectangle") {
      return { width: size * 4 / 3, height: size };
    }
    return { width: 0, height: 0 };
  })();

  canvas.setWidth(width);
  canvas.setHeight(height);

  const strUrl = (() => {
    if (imagePath !== "") {
      return imagePath;
    }
    if (clip.avatarType === "dreamAvatar" || clip.avatarType === "customAvatar") {
      if (clip.model.current_url === "origin") {
        return model.toggle_urls.origin;
      }
      return model.toggle_urls.alpha;
    }
    return model.source_url;
  })();

  const getModelImage = () => new Promise<fabric.Image>((resolve) => {
    fabric.Image.fromURL(strUrl, (image: any) => {
      image.crossOrigin = "Anonymous";
      resolve(image);
    },
    { crossOrigin: "anonymous" });
  });

  const modelImage = await getModelImage();
  canvas.add(modelImage);

  const headLeft = _headCenterX - spaceL - width / 2 + _headWidth / 2;
  const headTop = _headCenterY - spaceT - height / 2 + _headHeight / 2 - offsetTop;
  modelImage.scale(1).set({ 
    top: -1 * (headTop + offsetY), 
    left: -1 * (headLeft + offsetX)
  });

  if (shape === "round") {
    // Clip Path
    const clipPath = new fabric.Circle({
      radius: width / 2,
      top: height / 2,
      left: width / 2,
      originY: "center",
      originX: "center",
      fill: "white",
    });
    
    canvas.add(clipPath);
    canvas.add(modelImage)
    canvas.clipPath = clipPath;
  }
  if (shape === "rectangle") {
    const background = new fabric.Rect({
      top: 0,
      left: 0,
      width,
      height,
      fill: "#fff",
    });
    canvas.add(background);
    canvas.add(modelImage);
  }
  canvas.renderAll();

  const data = canvas.toDataURL();
  // canvas.dispose();
  disposeCanvas(canvas);

  return {
    src: data,
    width,
    height,
    top: headTop,
    left: headLeft,
    offsetTop,
    shape,
  };
}

export async function getHeadOnly(params: {
  clip: any;
  imagePath?: string;
  shape?: "round" | "rectangle";
}) {
  const { clip, imagePath = "", shape = "round" } = params;

  const cacheKey =
    `${clip?.model.ai_name}/${clip?.model.emotion}/${imagePath}/${shape}/${clip?.model?.current_url ? clip.model.current_url : ""}`;

  if (typeof window === "undefined") {
    const headOnly = await createHeadOnly(params);
    return headOnly;
  }
  
  const storageKey = "model-headonly-images";
  const cache = JSON.parse(sessionStorage.getItem(storageKey) || "{}");

  try {
    if (!cache[cacheKey]) {
      throw new Error();
    }
    await fetch(cache[cacheKey].src);
    return cache[cacheKey];
  } catch {
    const headOnly = await createHeadOnly(params);
    headOnly.src = base64ToBlob<string>({ base64: headOnly.src });
  
    sessionStorage.setItem(storageKey, JSON.stringify({
      ...JSON.parse(sessionStorage.getItem(storageKey) || "{}"),
      [cacheKey]: headOnly,
    }));
  
    return headOnly;
  }
}

// Image -> HeadOnly point calc
export function convertHeadOnlyPoint({ object, beforHeadOnly, headOnly }: any) {
  const { left, top, width, height, scaleX = 1.0, scaleY = 1.0 } = object;
  // let ps = { left: left/scaleX, top: top/scaleY, width, height, scaleX, scaleY };
  let ps = { left, top, width, height, scaleX, scaleY };

  if (headOnly) {
    const rate = beforHeadOnly ? beforHeadOnly.height / headOnly.height : 1.0;
    ps.width = headOnly.width;
    ps.height = headOnly.height;
    ps.scaleX = ps.scaleX * rate;
    ps.scaleY = ps.scaleY * rate;

    if (!beforHeadOnly) {
      ps.left = (ps.left / scaleX + headOnly.left) * ps.scaleX;
      ps.top = (ps.top / scaleY + headOnly.top) * ps.scaleY;
    }
    if (
      beforHeadOnly &&
      beforHeadOnly.shape !== headOnly.shape
    ) {
      ps.left = ps.left + (beforHeadOnly.width - headOnly.width) * ps.scaleX / 2;
    }
  } else {
    if (beforHeadOnly) {
      // 헤드 온리에서 몸으로 변경됨
      ps.left = (ps.left / scaleX - beforHeadOnly.left) * scaleX;
      ps.top = (ps.top / scaleY - beforHeadOnly.top) * scaleY;
      ps.width = object.width * scaleX;
      ps.height = object.height * scaleY;
    }
  }

  return ps;
}

// head only 이미지로 변경한다.
export function modelChangeIamge({
  canvas,
  name,
  model,
  beforHeadOnly,
  headOnly,
  voiceOnly,
  isDelete = false
}: any) {
  const object = canvas.getObjects().find((obj: any) => (
    obj.type === "aiModel" && obj.name === name
  ));
  const strUrl = headOnly && headOnly?.src ? headOnly?.src : model.source_url;
  const calcPoint = convertHeadOnlyPoint({ object, beforHeadOnly, headOnly });
  object.set("left", calcPoint.left);
  object.set("top", calcPoint.top);

  object.set("width", calcPoint.width);
  object.set("height", calcPoint.height);
  object.set("scaleX", calcPoint.scaleX);
  object.set("scaleY", calcPoint.scaleY);

  /**
   * voiceOnly -> visibility hide or show
   * if voiceOnly -> hide
   * else -> show
   */
  const opacity = (voiceOnly || isDelete) ? 0 : 1;
  object.set('opacity',opacity);
  // object.set('visible', !voiceOnly);
  object.setSrc(strUrl, () => { 
    canvas.renderAll(); 
  }, { crossOrigin: "anonymous" });
  return calcPoint;
}

export async function logoImageChange({ canvas, url, asset }: any) {
  const object = canvas.getObjects().find((obj:any) => obj.type === 'image' && obj?.tag === 'logo');
  const { top, left } = object;

  const targetWidth = asset.width;
  const targetHeight = asset.height;
  const sourceWidth = object.width;
  const sourceHeight = object.height;

  const scaleFactor = (targetWidth / sourceWidth) && (targetHeight /sourceHeight) < 1
  ? Math.min(
    sourceHeight / targetHeight,
    sourceWidth / targetWidth
  )
  : Math.min(
    targetWidth / sourceWidth,
    targetHeight / sourceHeight
  )

  object.setSrc(url, function() {
    canvas.renderAll();
  });
  object.set('scaleY', scaleFactor);
  object.set('scaleX', scaleFactor);
  canvas.requestRenderAll();
  return;

  return new Promise<Fabric.Image>((resolve) => {
    object.setSrc(
      url,
      (_img: Fabric.Image) => {
        _img.crossOrigin = 'Anonymous';
        
        _img.set('top', top);
        _img.set('left', left);
        _img.set('lockMovementX', false);
        _img.set('lockMovementY', false);
        _img.set('lockRotation', false);
        _img.set('lockScalingX', false);
        _img.set('lockScalingY', false);
        _img.set('lockSkewingX', false);
        _img.set('lockSkewingY', false);
        _img.set('lockUniScaling', false);

        _img.set('scaleY', scaleFactor);
        _img.set('scaleX', scaleFactor);

        _img.dirty = true; 
        canvas.add(_img)
        canvas.requestRenderAll();
        resolve(_img);
      },
      { crossOrigin: 'anonymous' }
    )
  })
}

function roundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  r: number
) {
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + w, y, x + w, y + h, r);
  ctx.arcTo(x + w, y + h, x, y + h, r);
  ctx.arcTo(x, y + h, x, y, r);
  ctx.arcTo(x, y, x + w, y, r);
  ctx.closePath();
  return ctx;
}

export function createFabricCanvas(
  element: any,
  options: any,
  staticCanvas = false
): FabricCustom.Canvas | FabricCustom.StaticCanvas {
  let canvas: FabricCustom.Canvas | FabricCustom.StaticCanvas;
  if (staticCanvas) canvas = new fabric.StaticCanvas(element, {
    ...options,
    preserveObjectStacking: true,
  });
  else canvas = new fabric.Canvas(element, {
    ...options,
    preserveObjectStacking: true,
  });

  const fabricObjectPrototype = fabric.Object.prototype as FabricCustom.Object;
  const fabricTextBoxPrototype = fabric.Textbox.prototype as FabricCustom.Textbox;
  const fabricRectPrototype = fabric.Rect.prototype as FabricCustom.Rect;

  fabricTextBoxPrototype._renderBackground = function (ctx) {
    if (!this.backgroundColor) {
      return;
    }
    var dim = this._getNonTransformedDimensions();
    ctx.fillStyle = this.backgroundColor;

    const bgCornerRadius = this.canvas.bgCornerRadius || this.bgCornerRadius;
    const padding = this.padding;
    if (padding) {
      dim.x += (padding * 3) / (this.scaleX || 1.0);
      dim.y += (padding * 3) / (this.scaleY || 1.0);
    }
    if (!bgCornerRadius) {
      ctx.fillRect(-dim.x / 2, -dim.y / 2, dim.x, dim.y);
    } else {
      roundRect(
        ctx,
        -dim.x / 2,
        -dim.y / 2,
        dim.x,
        dim.y,
        bgCornerRadius
      ).fill();
    }
    // if there is background color no other shadows
    // should be casted
    this._removeShadow(ctx);
  };

  const kRect = 1 - 0.5522847498;

  fabricRectPrototype._renderFill = function (ctx: any) {
    if (!this.fill) {
      return;
    }
    // console.log("_renderFill");

    ctx.save();
    try {
      this._setFillStyles(ctx, this);
    } catch {
      console.error("Prevent: InvalidStateError: The object is in an invalid state")
    }

    const { width: w = 0, height: h = 0 } = this;

    if (typeof this.fill === "string") {
      const x = -w / 2;
      const y = -h / 2;
      const rx = this.rx ? Math.min(this.rx, w / 2) : 0;
      const ry = this.ry ? Math.min(this.ry, h / 2) : 0;
      const isRounded = rx !== 0 || ry !== 0;

      ctx.beginPath();

      ctx.moveTo(x + rx, y);

      ctx.lineTo(x + w - rx, y);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isRounded &&
        ctx.bezierCurveTo(
          x + w - kRect * rx,
          y,
          x + w,
          y + kRect * ry,
          x + w,
          y + ry
        );

      ctx.lineTo(x + w, y + h - ry);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isRounded &&
        ctx.bezierCurveTo(
          x + w,
          y + h - kRect * ry,
          x + w - kRect * rx,
          y + h,
          x + w - rx,
          y + h
        );

      ctx.lineTo(x + rx, y + h);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isRounded &&
        ctx.bezierCurveTo(
          x + kRect * rx,
          y + h,
          x,
          y + h - kRect * ry,
          x,
          y + h - ry
        );

      ctx.lineTo(x, y + ry);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isRounded &&
        ctx.bezierCurveTo(x, y + kRect * ry, x + kRect * rx, y, x + rx, y);

      ctx.closePath();
    }
    if (this.fillRule === "evenodd") {
      ctx.fill("evenodd");
    } else {
      ctx.fill();
    }
    ctx.restore();
  };

  // fabricTextBoxPrototype._wrapLine = function(_line: any, lineIndex: any, desiredWidth: any, reservedSpace: any) {
  //   let lineWidth = 0,
  //       splitByGrapheme = this.splitByGrapheme,
  //       graphemeLines = [],
  //       line = [],
  //       // spaces in different languges?
  //       words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
  //       word = '',
  //       offset = 0,
  //       infix = splitByGrapheme ? '' : ' ',
  //       wordWidth = 0,
  //       infixWidth = 0,
  //       largestWordWidth = 0,
  //       lineJustStarted = true,
  //       additionalSpace = splitByGrapheme ? 0 : this._getWidthOfCharSpacing();

  //   reservedSpace = reservedSpace || 0;
  //   desiredWidth -= reservedSpace;
  //   for (var i = 0; i < words.length; i++) {
  //       // i would avoid resplitting the graphemes
  //       word = fabric.util.string.graphemeSplit(words[i]);
  //       wordWidth = this._measureWord(word, lineIndex, offset);
  //       offset += word.length;

  //       // Break the line if a word is wider than the set width
  //       if (this.breakWords && wordWidth >= desiredWidth) {

  //       	if (!lineJustStarted) {
  //           	line.push(infix);
  //               lineJustStarted = true;
  //           }

  //           // Loop through each character in word
  //           for (let w = 0; w < word.length; w++) {
  //           	var letter = word[w];
  //               var letterWidth = this.getMeasuringContext().measureText(letter).width * this.fontSize / this.CACHE_FONT_SIZE;
  //               if (lineWidth + letterWidth > desiredWidth) {
  //               	graphemeLines.push(line);
  //                   line = [];
  //                   lineWidth = 0;
  //               } else {
  //               	line.push(letter);
  //                   lineWidth += letterWidth;
  //               }
  //           }
  //           word = [];
  //       } else {
  //       	lineWidth += infixWidth + wordWidth - additionalSpace;
  //       }

  //       if (lineWidth >= desiredWidth && !lineJustStarted) {
  //           graphemeLines.push(line);
  //           line = [];
  //           lineWidth = wordWidth;
  //           lineJustStarted = true;
  //       } else {
  //           lineWidth += additionalSpace;
  //       }

  //       if (!lineJustStarted) {
  //           line.push(infix);
  //       }
  //       line = line.concat(word);

  //       infixWidth = this._measureWord([infix], lineIndex, offset);
  //       offset++;
  //       lineJustStarted = false;
  //       // keep track of largest word
  //       if (wordWidth > largestWordWidth && !this.breakWords) {
  //           largestWordWidth = wordWidth;
  //       }
  //   }

  //   i && graphemeLines.push(line);

  //   if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
  //       this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
  //   }
  //   console.log("!@#!@#!@# graphemeLine ", graphemeLines);
  //   return graphemeLines;
  // };

  fabricTextBoxPrototype._measureWord = function (
    word: any,
    lineIndex: number,
    charOffset = 0
  ): number {
    let width = 0,
      prevGrapheme;
    const skipLeft = true;
    for (let i = 0, len = word.length; i < len; i++) {
      const box = this._getGraphemeBox(
        word[i],
        lineIndex,
        i + charOffset,
        prevGrapheme,
        skipLeft
      );
      width += box.kernedWidth;
      prevGrapheme = word[i];
    }
    // console.log("width: ", word, Math.floor(width));
    if (typeof window === "undefined") {
      return Math.floor(width);
    }
    return Math.floor(width);
  };

  fabricObjectPrototype.drawBorders = function (ctx: any, styleOverride: any) {
    styleOverride = styleOverride || {};

    var wh = this._calculateCurrentDimensions(),
      strokeWidth = this.borderScaleFactor || 0,
      width = wh.x + strokeWidth,
      height = wh.y + strokeWidth,
      hasControls =
        typeof styleOverride.hasControls !== "undefined"
          ? styleOverride.hasControls
          : this.hasControls,
      shouldStroke = false;

    ctx.save();
    ctx.strokeStyle = styleOverride.borderColor || this.borderColor;
    this._setLineDash(
      ctx,
      styleOverride.borderDashArray || this.borderDashArray
    );

    if (!this.selectionRadius) {
      ctx.strokeRect(-width / 2, -height / 2, width, height);
    } else {
      roundRect(
        ctx,
        -width / 2,
        -height / 2,
        width,
        height,

        this.selectionRadius
      ).stroke();
    }

    if (hasControls) {
      ctx.beginPath();

      this.forEachControl(function (control, key, fabricObject) {
        // in this moment, the ctx is centered on the object.
        // width and height of the above function are the size of the bbox.
        if (
          control.withConnection &&
          control.getVisibility(fabricObject, key)
        ) {
          // reset movement for each control
          shouldStroke = true;
          ctx.moveTo(control.x * width, control.y * height);
          ctx.lineTo(
            control.x * width + control.offsetX,
            control.y * height + control.offsetY
          );
        }
      });
      if (shouldStroke) {
        ctx.stroke();
      }
    }
    ctx.restore();
    return this;
  };

  /**
   * 오브젝트 회전 마우스 커서를 다른 것으로 변경함
   * 
   * 십자가 모양에서 회전 모양으로 변경
   */
  fabricObjectPrototype.controls.mtr.cursorStyle =
    'url(/cursors/rotate-cursor.svg) 8 8, crosshair'

  return canvas;
}



export function initCanvasSize({
  canvas,
  width,
  height,
  original_landscape = 1920,
  original_portrait = 1080,
  orientation = "landscape",
}: any) {
  if (!canvas) return;

  const [originalWidth,originalHeight] = orientation === 'landscape' ? [original_landscape , original_portrait] : [original_portrait , original_landscape];
  const scaleWidth = width / originalWidth;
  const scaleHeight = height / originalHeight;
  const scale = Math.min(scaleWidth, scaleHeight);
  canvas.setDimensions({
    width: originalWidth * scale,
    height: originalHeight * scale,
  });
  canvas.setZoom(scale)
  return;
}

export function setClipping(params: {
  object: fabricModule.Object;
  shape?: "circle" | null;
}) {
  const { object, shape = null } = params;

  if (!object || !object.width || !object.height) {
    return;
  }

  switch (shape) {
    case "circle": {
      object.set("clipPath", new fabric.Circle({
        objectCaching: false,
        radius: object.width > object.height ? object.height / 2: object.width / 2,
        originX: "center",
        originY: "center",
      }));
    }
    break;
    default: {
      object.set("clipPath", undefined);
    }
  }
}

export async function addShape({
  canvas,
  id,
  left,
  top,
  width,
  height,
  selectable,
  evented,
  shapeName,
  radius,
  scaleX = 1,
  scaleY = 1,
  angle,
  fill,
  stroke,
  strokeWidth,
  shadow,
  lock = false,
  skewX = 0,
  skewY = 0,
  animation,
}: AddShapeProps) {
  let shadowPath;
  let enabled = false;
  if(shadow && shadow.enabled) {
    enabled = shadow.enabled
    shadowPath = new fabric.Shadow(shadow)
  }

  switch (shapeName) {
    case "rectangle":
      const rect = canvas.getObjects().find((o: any) => o.name === id)
        ?? new fabric.Rect({
          name: id,
          left,
          top,
          stroke: stroke,
          width,
          height,
          strokeWidth: strokeWidth ?? 3,
          fill: fill ?? "#D9F8FB",  
          dirty: true,
          evented: evented,
          objectCaching: false,
          selectable: selectable ?? evented,
          scaleX,
          scaleY,
          angle,  
          lockScalingFlip: true,
          lockMovementX: lock,
          lockMovementY: lock,
          lockRotation: lock,
          lockScalingX: lock,
          lockScalingY: lock,
          lockSkewingX: lock,
          lockSkewingY: lock,
          lockUniScaling: lock,
          moveCursor: lock ? 'not-allowed' : undefined,
          hoverCursor: lock ? 'not-allowed' : undefined,
          skewX,
          skewY
        });
      if (shadow && enabled) {
        rect.shadow = shadowPath;
      }     

      // rect.dirty = true;
      canvas.add(rect);
      return rect
      break;
    case "triangle":
      const triangle = canvas.getObjects().find((o: any) => o.name === id)
        ?? new fabric.Triangle({
          name: id,
          left,
          top,
          width,
          height,
          stroke: "#444444",
          strokeWidth: 1.5,
          fill: "#D9F8FB",
          objectCaching: false,
          selectable: selectable ?? evented,
          evented: evented,
          scaleX: 1,
          scaleY: 1,
          dirty: true,
          lockScalingFlip: true,
          lockMovementX: lock,
          lockMovementY: lock,
          lockRotation: lock,
          lockScalingX: lock,
          lockScalingY: lock,
          lockSkewingX: lock,
          lockSkewingY: lock,
          lockUniScaling: lock,
          moveCursor: lock ? 'not-allowed' : undefined,
          hoverCursor: lock ? 'not-allowed' : undefined,   
          skewX,
          skewY 
        });
    
      // rect.dirty = true;
      canvas.add(triangle);
      break;
    case "circle":
      const circle = canvas.getObjects().find((o: any) => o.name === id)
        ?? new fabric.Circle({
          name: id,
          left,
          top,
          stroke: stroke ?? "#444444",
          strokeWidth: strokeWidth ?? 1.5,
          fill: fill ?? "#D9F8FB",
          objectCaching: false,
          selectable: selectable ?? evented,
          evented: evented,
          radius,
          scaleX,
          scaleY,
          angle,
          lockMovementX: lock,
          lockMovementY: lock,
          lockRotation: lock,
          lockScalingX: lock,
          lockScalingY: lock,
          lockSkewingX: lock,
          lockSkewingY: lock,
          lockUniScaling: lock,
          moveCursor: lock ? 'not-allowed' : undefined,
          hoverCursor: lock ? 'not-allowed' : undefined,
          skewX,
          skewY
        });
    
      // rect.dirty = true;
      canvas.add(circle);
      break;
    default:
      
      const shapeDef = await getSVGPathDefinition(shapeName + '.svg');
      if(shapeDef) {
        const path: FabricCustom.Path = canvas.getObjects().find((o: any) => o.name === id)
          ?? new fabric.Path(shapeDef, {
            name: id,
            left,
            top,
            stroke: stroke,
            width,
            height,
            strokeWidth: strokeWidth ?? 3,
            fill: (fill === undefined ? "#D9F8FB" : fill),
            dirty: true,
            evented: evented,
            objectCaching: false,
            selectable: selectable ?? evented,
            scaleX,
            scaleY,
            angle,  
            lockMovementX: lock,
            lockMovementY: lock,
            lockRotation: lock,
            lockScalingX: lock,
            lockScalingY: lock,
            lockSkewingX: lock,
            lockSkewingY: lock,
            lockUniScaling: lock,
            moveCursor: lock ? 'not-allowed' : undefined,
            hoverCursor: lock ? 'not-allowed' : undefined,
            skewX,
            skewY,
          });
        if (shadow && enabled) {
          path.shadow = shadowPath;
        }
        if (animation) {
          path.animation = animation;
        }
        canvas.add(path);
        return path
      }

      break;
  }
}


export function addRect({
  canvas,
  id,
  left,
  top,
  width,
  height,
  selectable,
  evented,
  lock = false,
}: any) {
  const rect = canvas.getObjects().find((o: any) => o.name === id)
    ?? new fabric.Rect({
      name: id,
      left,
      top,
      width,
      height,
      fill: "yellow",
      objectCaching: false,
      stroke: "lightgreen",
      strokeWidth: 1,
      selectable: selectable ?? evented,
      evented: evented,
      lockMovementX: lock,
      lockMovementY: lock,
      lockRotation: lock,
      lockScalingX: lock,
      lockScalingY: lock,
      lockSkewingX: lock,
      lockSkewingY: lock,
      lockUniScaling: lock,
      moveCursor: lock ? 'not-allowed' : undefined,
      hoverCursor: lock ? 'not-allowed' : undefined,
    });

  // rect.dirty = true;
  canvas.add(rect);
}

export async function addWatermark({ canvas, lazy, id, source_url }: any) {
  const zoom = canvas.getZoom();
  const canvasWidth = canvas.getWidth() / zoom;
  const canvasHeight = canvas.getHeight() / zoom;
  // console.log(">>>>>> canvasWidth / canvasHeight", canvasWidth, canvasHeight)
  
  const img = canvas.getObjects().find((o: any) => o.name === id) ?? 
    new fabric.Image("", {
      name: id,
      selectable: false,
      evented: true,
      hoverCursor: "pointer",
      hasControls: false,
      hasBorders: false,
    });

  // img.dirty = true;
  // canvas.add(img);
  return new Promise<fabric.Image>((resolve) => {
    img.setSrc(
      source_url,
      (_img: Fabric.Image) => {
        _img.crossOrigin = "Anonymous";
        const { width: imgWidth = 1, height: imgHeight = 1 } = _img;
        // console.log(">>>>>> imgWidth / canvasHeight", imgWidth, imgHeight)
        const width = canvasWidth * 0.30;
        const scale =  width / imgWidth;
        const height = imgHeight * scale;

        const top = canvasHeight - height - (canvasHeight * 0.12);
        const left = (canvasWidth - width) / 2;

        // console.log("zoom / scale ", zoom, scale);

        _img.set("top", top);
        _img.set("left", left);
        _img.set("scaleX", scale);
        _img.set("scaleY", scale);
        _img.set('lockMovementX', true);
        _img.set('lockMovementY', true);
        _img.set('lockRotation', true);
        _img.set('lockScalingX', true);
        _img.set('lockScalingY', true);
        _img.set('lockSkewingX', true);
        _img.set('lockSkewingY', true);
        _img.set('lockUniScaling', true);

        _img.dirty = true; 
        canvas.add(_img)
        if (!lazy) {
          canvas.requestRenderAll();
        }
        resolve(_img);
      },
      { crossOrigin: "anonymous" }
    );
  });
}

export function addBackground({
  canvas,
  id,
  source_type,
  source_color,
  source_url,
  fill,
  evented,
  lazy,
  size = "auto" // auto(원래사이즈) | contain(잘림없이 한쪽에 fit) | cover(가로 100% 세로 잘림) 
}: any) {
  return new Promise<void>((resolve) => {
    canvas.getObjects().forEach((o: any) => {
      if (o.isBackground) {
        canvas.remove(o);
      }
    });

    const rect = new fabric.Rect({
      name: id,
      left: 0,
      top: 0,
      fill,
      selectable: false,
      evented: evented,
      hoverCursor: "default",
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      hasBorders: true,
      padding: -4,
      borderScaleFactor: 5,
    }) as Fabric.Rect & {
      selectionRadius: number;
      isBackground: boolean;
    };

    rect.selectionRadius = 6;
    rect.isBackground = true;
  
    canvas.add(rect);
    rect.sendToBack()

    const canvasWidth = canvas.getWidth() / canvas.getZoom();
    const canvasHeight = canvas.getHeight() / canvas.getZoom();
    // console.log(">>>>>>>> canvasWidth / canvasHeight ", canvasWidth, canvasHeight);

    // Pixabay로 background를 추가할 경우 Chormakey 색으로 치환.
    if (source_type !== "color" && source_url?.includes("pixabay.com")) {
      rect.set("width", canvasWidth);
      rect.set("height", canvasHeight);
      rect.set("fill", 'rgb(54,188,37)');
      rect.dirty = true;
      if (!lazy) {
        canvas.renderAll();
      }
      resolve();
    } else if (source_type === "color") {
      rect.set("width", canvasWidth);
      rect.set("height", canvasHeight);
      rect.set("fill", source_color);
      rect.dirty = true;
      if (!lazy) {
        canvas.renderAll(); 
      }
      resolve();
    } else {
      const url  = source_url.startsWith("/") && typeof window === "undefined" ? `file://${process.cwd()}/public${source_url}` : source_url;
      fabric.Image.fromURL(
        url,
        (image: any) => {
          image.crossOrigin = "anonymous";

          if(size === "auto") {
            if (canvasWidth > canvasHeight) {
              image.scaleToWidth(canvasWidth);
            } else {
              image.scaleToHeight(canvasHeight);
            }
          } else if(size === "contain") {
            if(canvasWidth > canvasHeight) {  //landscape
              if(image.height / image.width <= 9 / 16) { //이미지 가로가 더 긴
                const scale = canvasWidth / image.width;
                image.set("scaleX", (image.scaleX ?? 1) * scale);
                image.set("scaleY", (image.scaleY ?? 1) * scale);
                image.left = 0;
                image.top = (canvasHeight - image.height * scale) / 2;
              } else {  //이미지 세로가 더긴
                const scale = canvasHeight / image.height;
                image.set("scaleX", (image.scaleX ?? 1) * scale);
                image.set("scaleY", (image.scaleY ?? 1) * scale);
                image.left = (canvasWidth - image.width * scale) / 2;
                image.top = 0;
              }
            } else {  //portrait
              if(image.height / image.width > 16 / 9) { //이미지 세로가 더 긴
                const scale = canvasHeight / image.height;
                image.set("scaleX", (image.scaleX ?? 1) * scale);
                image.set("scaleY", (image.scaleY ?? 1) * scale);
                image.left = (canvasWidth - image.width * scale) / 2;
                image.top = 0;
              } else {  //이미지가로가 더 긴
                const scale = canvasWidth / image.width;
                image.set("scaleX", (image.scaleX ?? 1) * scale);
                image.set("scaleY", (image.scaleY ?? 1) * scale);
                image.left = 0;
                image.top = (canvasHeight - image.height * scale) / 2;              
              }
            }  
          } else if(size === "cover") {

          }
          var patternSourceCanvas = new fabric.StaticCanvas(null, {
            width: canvasWidth,
            height: canvasHeight,
          });

          patternSourceCanvas.setZoom(
            typeof window === 'undefined'
              ? 1
              : 1 / (fabric.devicePixelRatio || window.devicePixelRatio),
          )

          patternSourceCanvas.add(image);
          patternSourceCanvas.renderAll();

          rect.set("width", canvasWidth);
          rect.set("height", canvasHeight);
          rect.set(
            "fill",
            new fabric.Pattern({
              source: patternSourceCanvas.getElement() as any,
              crossOrigin: "anonymous",
            })
          );
          rect.dirty = true;
          if (!lazy) {
            canvas.renderAll();
          }
          resolve();
        },
        { crossOrigin: "anonymous" }
      );
    }
  });
}

export function addAiModel({
  canvas,
  id,
  left,
  top,
  width,
  height,
  model,
  selectable,
  evented,
  scaleX = 1,
  scaleY = 1,
  effects,
  headOnly,
  script,
  ...props
}: any): Promise<fabric.Image> {
  const strUrl = headOnly?.src ? headOnly.src : model.source_url;
  const lock = props?.lock ? true : false;
  const opacity = (props?.voiceOnly || props?.isDelete) ? 0 : 1;

  const fabricModel = canvas.getObjects().find((o: any) => o.name === id)
    ?? new fabric.Image("", {
      selectable: selectable ?? evented,
      evented: evented,
      ...props,
      name: id,
      lockScalingFlip: true,
      type: "aiModel"
    })

  canvas.remove(fabricModel);

  fabricModel.dirty = true;

  return new Promise<fabric.Image>((resolve) => {
    fabricModel.setSrc(
      strUrl,
      async (_img: Fabric.Image) => {
        _img.set("hasRotatingPoint", false);
        _img.set("scaleX", scaleX);
        _img.set("scaleY", scaleY);
        _img.set('opacity', opacity);
        _img.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          tr: true,
          tl: true,
          br: true,
          bl: true,
          mtr: false, //the rotating point (defaut: true)
        });
        _img.set('lockMovementX', lock);
        _img.set('lockMovementY', lock);
        _img.set('lockRotation', lock);
        _img.set('lockScalingX', lock);
        _img.set('lockScalingY', lock);
        _img.set('lockSkewingX', lock);
        _img.set('lockSkewingY', lock);
        _img.set('lockUniScaling', lock);
        _img.set('moveCursor', lock ? 'not-allowed' : undefined);
        _img.set('hoverCursor', lock ? 'not-allowed' : undefined);

        if (typeof left !== "undefined" && typeof top !== "undefined") {
          _img.set("left", left);
          _img.set("top", top);
        } else {
          const canvasZoom = canvas.getZoom();
          const canvasWidth = canvas.getWidth() / canvasZoom;
          const canvasHeight = canvas.getHeight() / canvasZoom;
          const imgHeight = (_img.height || 0) * (_img.scaleY || 0);

          const canvasPoint = new fabric.Point(
            canvasWidth / 2,
            imgHeight > canvasHeight ? 102.71196783306596 : canvasHeight / 2
          );

          _img.setPositionByOrigin(
            canvasPoint,
            "center",
            imgHeight > canvasHeight ? "top" : "center"
          );
        }

        canvas.add(_img);
        resolve(_img);
      },
      { crossOrigin: "anonymous" }
    );
  });
}

async function loadCustomFont(customFont: FontFace): Promise<void> {
  try {
    await customFont.load();
    document.fonts.add(customFont);
  } catch(error) {
    console.error(`addTextBox LoadCustomFont Error `, error);
    throw error;
  }
}

export function addTextBox({
  canvas,
  id,
  left,
  top,
  width,
  height,
  text,
  fontFamily,
  shadow,
  lock,
  fontWeight = "normal",
  breakWord,
  ...props
}: AddTextBoxProps) {
  let charSpacing = props.charSpacing ? props.charSpacing : 0;
  if (typeof window === "undefined") {
    charSpacing = -0.0375;
  }

  let convertedText = '';

  const isArabic = RTLArabic.isArabic(text);

  if (isArabic) {
    convertedText = new RTLArabic(text, { harakat: true, numbers: false, multiline: true }).convert();
  } else {
    convertedText = text;
  }

  const textBox = (() => {
    const targetObject = canvas.getObjects().find((o: any) => o.name === id);
    if (targetObject) return targetObject;

    const splitByGrapheme = !(text.trim().includes(' ') && breakWord);
    const textBoxValues: fabric.ITextboxOptions = {
      ...props,
      name: id,
      left,
      top,
      width,
      height,
      objectCaching: false,
      text: convertedText,
      fontFamily,
      fontWeight,
      strokeWidth: props.strokeWidth || 0,
      lockScalingFlip: true,

      // lock
      lockMovementX: lock,
      lockMovementY: lock,
      lockRotation: lock,
      lockScalingX: lock,
      lockScalingY: lock,
      lockSkewingX: lock,
      lockSkewingY: lock,
      lockUniScaling: lock,

      moveCursor: lock ? "not-allowed" : undefined,
      hoverCursor: lock ? "not-allowed" : undefined,
      editable: !lock,
      splitByGrapheme,
      // charSpacing: fontFamily === "Gmarket Sans" ? -0.0375 : 0,
      charSpacing: fontFamily === "Gmarket Sans" ? -0.0375 : charSpacing
    }

    return new fabric.Textbox(text, textBoxValues);
  })();

  textBox.setControlsVisibility({
    mt: false,
    mb: false,
    ml: true,
    mr: true,
    tr: true,
    tl: true,
    br: true,
    bl: true,
    mtr: true, //the rotating point (defaut: true)
  });


  if (shadow && shadow.enabled) {
    textBox.shadow = new fabric.Shadow(shadow);
  }

  /**
   * Custom Font의 경우 폰트 등록되어있는지 체크하고
   * 등록되어있지 않은경우 폰트 등록 후 고우
   * -> Backend에서도 사용하고 있으므로 front/backend 분기 태워서 실행해야함 
   */
  if(props?.custom && props?.src) {
    if(typeof window !== 'undefined') {
      const isCustomFontRegistered = Array.from(document.fonts).some(
        (font) => 
          font.family === fontFamily &&
          (!fontWeight || font.weight === fontWeight)
      )
  
      if(!isCustomFontRegistered) {
        const customFont = new FontFace(fontFamily || 'Noto Sans CJK', `url(${props.src})`, {
          weight: fontWeight,
          style: 'normal',
        });
  
        loadCustomFont(customFont)
        .then(() => {
          textBox.set({ fontFamily });
          textBox.dirty = true;
          canvas.renderAll();
        })
        .catch((error) => {
          console.error(`AddTextBox loadCustomFont Error `, error);
        })
      }
    } 
  }

  textBox.dirty = true;
  // console.log(">>>>>>> textBox ", textBox)
  canvas.add(textBox);
  return textBox;
}

// const getCaptionWidth = (text: string, height: number) => {
//   const tempTextElement = new fabric.Textbox(text, {
//     height: height,
//     fontSize: 20,
//   });

//   // Use the getWidth method to get the width of the text
//   return tempTextElement.width;
// }

export async function addCaptions({
  canvas,
  id,
  text,
  fontFamily,
  fontWeight = "normal",
  ...props
}: AddCaptionsProps) {
  let charSpacing = props.charSpacing ? props.charSpacing : 0;
  if (typeof window === "undefined") {
    charSpacing = -0.0375;
  }

  const zoom = canvas.getZoom();
  const canvasWidth = canvas.getWidth() / zoom;
  const canvasHeight = canvas.getHeight() / zoom;
  const width = props.width ?? 0.5 * canvasWidth;
  const height = props.height ?? 35;
  const top = props.top ?? canvasHeight - height - (canvasHeight * 0.12) as number;
  const left = props.left ?? (canvasWidth - (0.5 * canvasWidth)) / 2;

  const textBox = new fabric.Textbox(text || "", {
    ...props,
    name: id,
    top,
    left,
    width,
    height,
    objectCaching: false,
    text,
    fontFamily,
    fontWeight,
    strokeWidth: props.strokeWidth || 0,
    lockScalingFlip: true,
    // fontSize: 40,

    // lock
    lockMovementX: false,
    lockMovementY: false,
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    lockUniScaling: true,

    // moveCursor: "not-allowed",
    // hoverCursor: "not-allowed",
    editable: false,
    splitByGrapheme: true,
    charSpacing: fontFamily === "Gmarket Sans" ? -0.0375 : charSpacing
  })

  textBox.setControlsVisibility({
    mt: false,
    mb: false,
    ml: true,
    mr: true,
    tr: true,
    tl: true,
    br: true,
    bl: true,
    mtr: true, //the rotating point (defaut: true)
  });

  textBox.dirty = true;
  textBox.bringToFront();
  canvas.add(textBox);
  return textBox;
}

const addImagePadding = 200;

export function addImage({
  canvas,
  id,
  source_url,
  evented,
  selectable,
  type,
  clipping,
  ...props
}: any): Promise<fabric.Image> {
  const img = canvas.getObjects().find((o: any) => o.name === id)
    ?? new fabric.Image("", {
      selectable: selectable ?? evented,
      evented: evented,
      ...props,
      name: id,
      lockScalingFlip: true,
    });
  
  //todo videoImage 인경우 플레이 버튼을 이미지위에 올린다.

  const lock = props?.lock ? true : false;
  // console.log('>>>>> canvas-utils props ', {props})
  const { angle = 0 } = props;

  img.dirty = true;
  canvas.add(img);


  return new Promise<fabric.Image>((resolve) => {
    img.setSrc(
      source_url.startsWith('http') ? source_url :
        source_url.startsWith('/') ? `file://${source_url}` : `file://${process.cwd()}/${source_url}`,
      (_img: Fabric.Image) => {
        _img.crossOrigin = "Anonymous";
        _img.angle = angle;

        const canvasWidth = canvas.getWidth() / canvas.getZoom();
        const canvasHeight = canvas.getHeight() / canvas.getZoom();

        _img.width = _img.width ?? 160;
        // _img.width = 343;
        _img.height = _img.height ?? 90;
        // _img.height = 50;

        _img.scaleX = _img.scaleX ?? 1;
        _img.scaleY = _img.scaleY ?? 1;

        // console.log("!@#!@#!@# 1", canvas.name, _img.scaleX, _img.scaleY, _img.width, _img.height, canvasWidth)

        const ratio = {
          x: (_img.width * _img.scaleX) / canvasWidth,
          y: (_img.height * _img.scaleY) / canvasHeight,
        }

        if (_img.scaleX === 1 && _img.scaleY === 1 && (ratio.x > 1 || ratio.y > 1)) {
          const scale = ratio.x > ratio.y
            ? (canvasWidth - addImagePadding) / _img.width
            : (canvasHeight - addImagePadding) / _img.height;

            _img.set('scaleX', scale);
            _img.set('scaleY', scale);
        }

        // console.log("!@#!@#!@# 2", canvas.name, _img.scaleX, _img.scaleY, _img.width, _img.height, canvasWidth)
        
        const left = _img.left ?? (canvasWidth - ((_img.width * _img.scaleX) || 160)) / 2;
        const top = _img.top ?? (canvasHeight - ((_img.height * _img.scaleY) || 90)) / 2;

        _img.set("left", left);
        _img.set("top", top);

        if (type === "videoImage") {
          _img.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: true,
            tl: true,
            br: true,
            bl: true,
            mtr: false, //the rotating point (defaut: true)
          });
        }
        _img.set('lockMovementX', lock);
        _img.set('lockMovementY', lock);
        _img.set('lockRotation', lock);
        _img.set('lockScalingX', lock);
        _img.set('lockScalingY', lock);
        _img.set('lockSkewingX', lock);
        _img.set('lockSkewingY', lock);
        _img.set('lockUniScaling', lock);
        _img.set('moveCursor', lock ? 'not-allowed' : undefined);
        _img.set('hoverCursor', lock ? 'not-allowed' : undefined);

        if(props?.opacity) {
          _img.set('opacity', props?.opacity / 100)
        }

        setClipping({ object: _img, shape: clipping });
        // console.log("!@#!@#!@#", {canvas, img, _img})
        resolve(_img);
      },
      { crossOrigin: "anonymous" }
    );
  });
}

export async function addEffect({
  canvas,
  id,
  evented,
  selectable,
  type,
  effectMeta,
  left,
  top,
  width,
  height,
  lazy,
  ...props
}: {
  canvas: Fabric.Canvas
  id: string
  evented: boolean
  selectable: boolean
  type: 'effect'
  effectMeta: {
    effectId: string
    effectVideoUrl: string
    effectImageUrl: string
    effectOriginalVideoUrl: string
    connected: boolean
    order?: number
  }
  left: number
  top: number
  width: number
  height: number
  lazy?: boolean
}) {
  const canvasName: string =
    // @ts-expect-error
    canvas.name || ''

  let effect = canvas.getObjects().find((o) => o.name === id) as
    | EffectFabricObjectClass
    | EffectVideoFabricObjectClass
    | fabric.Image
    | undefined

  const canvasWidth = canvas.getWidth() / canvas.getZoom()
  const canvasHeight = canvas.getHeight() / canvas.getZoom()

  /**
   * 찾은 이펙트 오브젝트가 없으면 새로 생성
   */
  if (!effect) {
    /**
     * main, thumbnail, preview, previewVideoThumbnail, render
     * - main: 에디터 메인 캔버스
     * - thumbnail: 에디터 하단의 썸네일
     * - preview: 미리보기
     * - previewVideoThumbnail: 미리보기 하단의 썸네일
     * - render: 서버사이드
     *
     * main은 조작 가능한 이펙트 객체로 생성
     */
    if (['main'].includes(canvasName)) {
      /**
       * 가운데 표시
       */
      left = left || (canvasWidth - width) / 2
      top = top || (canvasHeight - height) / 2

      effect = new EffectFabricObject('', {
        selectable: selectable ?? evented,
        evented,
        ...props,
        name: id,
        effectMeta,
        width,
        height,
        left,
        top,
      })
    } else if (
      ['previewVideo', 'previewVideoTransitionImage'].includes(canvasName)
    ) {
      /**
       * previewVideo는 단순 동영상으로 동작해야 함
       */
      await new Promise((resolve) => {
        effect = new EffectVideoFabricObject('', {
          selectable: selectable ?? evented,
          evented,
          ...props,
          name: id,
          effectMeta,
          width,
          height,
          left,
          top,
        })

        if ('onCanPlayThrough' in effect) {
          effect.onCanPlayThrough(() => {
            resolve(effect)
          })
        }
      })
    } else {
      /**
       * 그 외에는 일반 이미지로 동작해야 함
       */
      /**
       * 이미지 로드 대기 필요
       */
      await new Promise((resolve) => {
        effect = new EffectImageFabricObject('', {
          selectable: selectable ?? evented,
          evented,
          ...props,
          name: id,
          width,
          height,
          left,
          top,
        })

        effect.setSrc(
          effectMeta.effectImageUrl,
          () => {
            resolve(effect)
          },
          {
            crossOrigin: 'anonymous',
          },
        )
      })
    }

    /**
     * 여기까지 오면 effect는 무조건 존재해야 함
     */
    canvas.add(effect!)
  }

  if (!lazy) {
    canvas.renderAll()
  }

  /**
   * 여기까지 오면 effect는 무조건 존재해야 함
   */
  return effect!
}

export async function drawAiModel({
  canvas,
  id,
  left,
  top,
  width,
  model,
  frame,
  animation,
  scaleX = 1,
  scaleY = 1,
  headOnly,
  avatarType,
}: {
  canvas: Fabric.Canvas;
  id: string;
  fps: Fps;
  frame: number;
  model: any;
  animation: any;
  [key: string]: any;
}) {
  if (animation?.enable) {
    const object: any = canvas.getObjects().find(({ name }) => name === id);
    let img: Fabric.Image;
    if (object) {
      img = object;
    } else {
      return;
    }

    let strUrl:string = "";
    if (headOnly) {
      const currHeadOnly = await createHeadOnly({
        clip: { model, left, top, width, animation, scaleX, scaleY, headOnly, avatarType },
        imagePath: animation.fileName(frame),
        shape: headOnly.shape
      });
      strUrl = currHeadOnly.src;
    } else {
      strUrl = animation.fileName(frame);
    }

    const loadImage = () => new Promise<fabric.Image>((resolve) => {
      img.setSrc(strUrl, (image: Fabric.Image) => {
        image.crossOrigin = "Anonymous";

        if (avatarType === 'customAvatar' || avatarType === 'dreamAvatar') {
          image.set("top", top);
          image.set("left", left);
        } else {
          const scale = width / model.editor.width;
          if (!headOnly) {
            if (!scaleX || !scaleY) {
              image.set("top", top - model.editor.top * scale);
              image.set("left", left - model.editor.left * scale);
            } else {
              image.set("top", top - model.editor.top * scaleY);
              image.set("left", left - model.editor.left * scaleX);
            }
          }
        }
        resolve(image);
      },
      { crossOrigin: "anonymous" });
    });
    await loadImage();
  }
}

export async function drawVideoImage({
  canvas,
  id,
  left,
  top,
  width,
  height,
  frame,
  scaleX = 1.0,
  scaleY = 1.0,
  drawVideoInfo,
  clipping = undefined
}: {
  canvas: Fabric.Canvas;
  id: string;
  fps: Fps;
  frame: number;
  drawVideoInfo: any;
  [key: string]: any;
}) {
  if (drawVideoInfo) {
    const object: any = canvas.getObjects().find(({ name }) => name === id);
    let img: Fabric.Image;
    if (!object) return;
    img = object;
    
    let strUrl:string = drawVideoInfo.fileName(frame);
    const loadImage = () => new Promise<fabric.Image>((resolve) => {
      img.setSrc(strUrl, (image: Fabric.Image) => {
        image.crossOrigin = "Anonymous";
        const { width: imgWidth, height: imgHeight } = image;
        const scx = width / ( imgWidth || width );
        const scy = height / ( imgHeight || height );
        image.set("top", top);
        image.set("left", left);
        image.set("width", imgWidth);
        image.set("height", imgHeight);
        image.set("scaleX", scx*scaleX);
        image.set("scaleY", scy * scaleY);

        setClipping({ object: image, shape: clipping });

        resolve(image);
      },
      { crossOrigin: "anonymous" });
    });
    await loadImage();
  }
}

export async function drawAnimationObject({
  clip,
  canvas,
  id,
  fps,
  frame,
  animation,
}: {
  clip: Clip,
  canvas: Fabric.Canvas;
  id: string;
  fps: Fps;
  frame: number;
  sceneStartFrame: number;
  animation: any;
  [key: string]: any;
}) {
  if (animation && animation.type && animation.type !== "") {
    const object: any = canvas.getObjects().find(({ name }) => name === id);
    if (!object) return;

    const canvasWidth = canvas.getWidth();
    const canvasHeight = canvas.getHeight();

    const startFrame = animation.delay * (fps.num / fps.den);
    const animateFrame = frame - startFrame < 0 ? 0 : frame - startFrame;
    const t = animateFrame / (fps.num / fps.den);
    const d = animation.duration || 1;
    const b = 0.0;
    const c = 1.0;
    const easing =  t < d ? fabric.util.ease.easeInQuad(t, b, c, d) : 1.0;
    const padding = (object.padding || 0) * 4;
    const stroke = (object.strokeWidth || 0) * 4;
    const width = (object.width + padding + stroke) * (object.scaleX || 1.0);
    const height = (object.height + padding + stroke) * (object.scaleY || 1.0);
    const screenWidth = canvas.vptCoords?.br.x || canvasWidth;
    const screenHeight = canvas.vptCoords?.br.y || canvasHeight;
    const { left, top } = clip;

    if (animation.type === "fade-in") {
      object.set("opacity", easing < 0 ? 0 : easing);
    } else if (animation.type === "fade-out") {
      object.set("opacity", 1.0 - (easing < 0 ? 0 : easing));
    } else if (animation.type === "out-left") {
      const distance = left + width;
      const calc = distance * easing;
      object.set("left", left - calc);
    } else if (animation.type === "out-right") {
      const distance = screenWidth - left;
      const calc = distance * easing;
      object.set("left", left + calc);
    } else if (animation.type === "out-up") {
      const distance = top + height;
      const calc = distance * easing;
      object.set("top", top - calc);
    } else if (animation.type === "out-down") {
      const distance = screenHeight - top;
      const calc = distance * easing;
      object.set("top", top + calc);
    } else if (animation.type === "in-up") {
      const distance = top + height;
      const calc = distance * easing;
      object.set("top", calc-height);
    } else if (animation.type === "in-down") {
      const distance = screenHeight - top;
      const calc = distance * easing;
      object.set("top", top + (distance - calc));
    } else if (animation.type === "in-left") {
      const distance = left + width;
      const calc = distance * easing;
      object.set("left", calc-width);
    } else if (animation.type === "in-right") {
      const distance = screenWidth- left;
      const calc = distance * easing;
      object.set("left", left + (distance - calc));
    } else if (animation.type === "zoom-in") {
      const { width: w, height: h, scaleX: scx = 1.0, scaleY:scy = 1.0 } = clip;
      object.set("left", left + (1.0 - easing) * ((w * scx) / 2));
      object.set("top", top + (1.0 - easing) * ((h * scy) / 2));
      object.set("scaleX", scx * easing);
      object.set("scaleY", scy * easing);
    } else if (animation.type === "zoom-out") {
      const { width: w, height: h, scaleX: scx = 1.0, scaleY:scy = 1.0 } = clip;
      object.set("left", left + easing * ((w * scx) / 2));
      object.set("top", top + easing * ((h * scy) / 2));
      object.set("scaleX", scx - scx * easing);
      object.set("scaleY", scy - scy * easing);
    }
    object.set("dirty", true);
  }
}

/**
 * Frame에 자막 그리기
 * - 기존 자막 캡션이 있을 경우 해당 자막 Text만 수정
 */
export async function drawCaption({
  canvas,
  // id,
  clip,
  frame,
  fps
  // width,
  // height,
  // text,
  // ...props
}: DrawCaptionsProps) {
  const CaptionObject = canvas.getObjects().find((object) => object.type === 'captions') as Fabric.Textbox | undefined;
  try {
    if (!CaptionObject) return;
    const FRAMEPERSEC = (fps.num / fps.den) || 29970 / 1000;
    const captionStartTime = frame / FRAMEPERSEC;

    const padding = 0.2;

    const capInfo = clip.captions?.find((cp: Caption) => cp.start + padding < captionStartTime && cp.end + padding >= captionStartTime);
  
    if (!capInfo) {
      CaptionObject.set('visible', false);
    } else {
      CaptionObject.set('visible', true);
      CaptionObject.set('text', capInfo.caption);
    }
    
    canvas.renderAll();
  } catch (err) {
    console.error(`${frame} frame ##### ${CaptionObject}`);
  }
}

export async function drawEffect({
  canvas,
  id,
  left,
  top,
  width,
  height,
  frame,
  scaleX = 1.0,
  scaleY = 1.0,
  effectMeta,
  drawEffectInfo
}: {
  canvas: Fabric.Canvas;
  id: string;
  frame: number;
  [key: string]: any;
}) {
  if (drawEffectInfo) {
    const object: Fabric.Image | undefined = canvas.getObjects().find(({ name }) => name === id) as Fabric.Image | undefined
    let img: Fabric.Image;
    if (!object) return;
    img = object;

    let strUrl: string = drawEffectInfo.fileName(frame);

    const loadImage = () => new Promise<fabric.Image>((resolve) => {
      img.setSrc(strUrl, (image: Fabric.Image) => {
        image.crossOrigin = "anonymous";
        const { width: imgWidth, height: imgHeight } = image;
        const scx = width / (imgWidth || width);
        const scy = height / (imgHeight || height);
        image.set("top", top);
        image.set("left", left);
        image.set("width", imgWidth);
        image.set("height", imgHeight);
        image.set("scaleX", scx * scaleX);
        image.set("scaleY", scy * scaleY);  
        resolve(image);
      }, {
        crossOrigin: "anonymous"
      });
    });

    if (effectMeta.startFrame > frame) {
      object.set("visible", false);
    } else {
      object.set("visible", true)
      await loadImage();
    }
  }
}

/**
 * Front에서만 사용하는 코드
 */
export async function editObject({ canvas, name, key, value }: EditProps) {
  const object = canvas.getObjects().find((obj: Fabric.Object) => obj.name === name);
  if (object) {
    if (["fontFamily", "fontWeight", "fontStyle"].includes(key)) {
      // console.log("value: ", value);
      // const fontFamily = key === "fontFamily" ? value : object.fontFamily;
      /**
       * CustomFont일 경우 value가 editor-my-asset의 font속성 Json타입으로 넘어온다
       */
      const customFontFamily = value?.src
        ? value?.fontFamily || value.orgFileName.replace(/\.(ttf|otf)$/i, '')
        : value;
      const fontFamily = key === 'fontFamily' ? customFontFamily : object.fontFamily;
      const weight = key === "fontWeight" ? value : object.fontWeight;
      const style = key === "fontStyle" ? value : object.fontStyle;

      /**
       *  customFont일때는 다르게 갑시다.
       * -> value 가 json이면 customFont
       * -> key === 'fontFamily' && customFont의 경우 value.src 존재함
       */
      if(key === 'fontFamily' && value?.src) {
        const isCustomFontRegistered = Array.from(document.fonts).some(
          (font) =>
            font.family === fontFamily &&
            font.weight === weight &&
            font.style === style
        );
        /**
         * custom Font 등록 안되어있으면 document에 등록시켜준다 
         */
        if(!isCustomFontRegistered) {
          const customFont = new FontFace(fontFamily, `url(${value.src})`, {
            weight, 
            style
          });

          customFont
          .load()
          .then(() => {
            document.fonts.add(customFont);
            if(key === 'fontFamily') {
              object.set(key, fontFamily);
            } else {
              object.set(key, value);
            }
            fabric.util.clearFabricFontCache();
            canvas.requestRenderAll();
          })
          .catch((error) => {
            console.error(`editObject isCustomFontRegistered font upload failed `, error);
            document.body.style.fontFamily = 'Noto Sans CJK';
          })
        } else {
          if(key === 'fontFamily') {
            object.set(key, fontFamily) ;
          } else {
            object.set(key, value);
          }
          fabric.util.clearFabricFontCache();
          canvas.requestRenderAll();
        }
      } else {
        /**
         * 기본폰트는 src/savePath 필요없으므로 custom 속성이니까 삭제해준다
         */
        if(key === 'fontFamily' && object?.src) {
          delete object.src;
          delete object.savePath;
        }
        const font = new FontFaceObserver(fontFamily, {
          weight,
          style,
        });
        font
          .load()
          .then(function () {
            // when font is loaded, use it.
            // console.log("load font", fontFamily, {weight, style,});
  
            object.set(key, value);
            fabric.util.clearFabricFontCache();
            canvas.requestRenderAll();
          })
          .catch(function (e: any) {
            console.log(e);
            document.body.style.fontFamily = 'Noto Sans CJK';
          });
      }
      // const font = new FontFaceObserver(fontFamily, {
      //   weight,
      //   style,
      // });
      // font
      //   .load()
      //   .then(function () {
      //     // when font is loaded, use it.
      //     // console.log("load font", fontFamily, {weight, style,});

      //     object.set(key, value);
      //     fabric.util.clearFabricFontCache();
      //     canvas.requestRenderAll();
      //   })
      //   .catch(function (e: any) {
      //     console.log(e);
      //     document.body.style.fontFamily = 'Noto Sans CJK';
      //   });
    } else if (key === "shadow") {
      if (value.enabled) {
        object.shadow = new fabric.Shadow(value);
      } else {
        object.shadow = null;
      }
      canvas.requestRenderAll();
    } else if (key === "lock") {
      object.lockMovementX = value;
      object.lockMovementY = value;
      object.lockRotation = value;
      object.lockScalingX = value;
      object.lockScalingY = value;
      object.lockSkewingX = value;
      object.lockSkewingY = value;
      object.lockUniScaling = value;

      object.moveCursor = value ? "not-allowed" : undefined;
      object.hoverCursor = value ? "not-allowed" : undefined;
      object.editable = !value;
    } else if(key === "strokeRadius") {
      object.set(key, value);      
      object.set("rx", value);
      object.set("ry", value);
      canvas.requestRenderAll();
    } else if(key === "opacity") {
      object.set(key, value / 100)
      canvas.requestRenderAll();
    } else if(key === 'text') {
      object.set(key, value);
      canvas.requestRenderAll();
    } else if(key === 'src') {
      object.setSrc(value, function() {
        canvas.requestRenderAll();
      }, { crossOrigin: 'Anonymous' })
    } else if(key === 'captions') {
      object.set(key, value);
      canvas.requestRenderAll();
    }
    else {
      object.set(key, value);
      canvas.requestRenderAll();
    }
  }
}

export function mouseOverObject(props: any) {
  const { canvas, id } = props;
  const object: any = canvas.getObjects().find(({ name }:{ name: any }) => name === id);
  if(!object) return
  object.set("hasControls", true)
  canvas.requestRenderAll();
}

export function mouseOutObject(props: any) {
  const { canvas, id } = props;
  const object: any = canvas.getObjects().find(({ name }:{ name: any }) => name === id);
  if(!object) return
  object.set("hasControls", false)
  canvas.requestRenderAll();  
}

async function saveState(object: any) {
  let newObj = {...object};
  // const {initialState} = object
  // if(initialState) object.set(initialState)
  object.stateProperties.map((key: any) => newObj[key] = object[key]);
  object.initialState = newObj;
  object.isMoving = true
}

async function restoreState(object: any, _canvas: any) {
  fabric.runningAnimations.cancelAll()
  const {initialState} = object
  object.set(initialState)
  object.isMoving = false
  _canvas.renderAll();
}

export async function animateObject({ canvas, name, value }: AnimationProps) {
  const canvasWidth = canvas.getWidth();
  const canvasHeight = canvas.getHeight();
  const object = canvas.getObjects().find((obj: Fabric.Object) => obj.name === name);
  // console.log("!@#!@#!@# canvas object", {canvas, object});
  if (object) {
    const padding = (object.padding || 0) * 4;
    const width = object.width + padding;
    const height = object.height + padding;
    const { left, top, scaleX = 1, scaleY = 1 } = object;    

    if(object.isMoving) {
      await restoreState(object, canvas)
    } else {
      await saveState(object)
    }
  
    switch (value.type) {
      case "fade-in":
        fabric.util.animate({
          startValue: 0,
          endValue: 1,
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.opacity = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        });
        break;
      case "fade-out":
        fabric.util.animate({
          startValue: 1,
          endValue: 0,
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.opacity = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        });
        break;  
      case "out-left": 
        fabric.util.animate({
          startValue: object.left,
          endValue: 0 - width,
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.left = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "out-right": 
        fabric.util.animate({
          startValue: object.left,
          endValue: 1920, //todo portrait 일때는 1080 으로 변경 하는 코드 필요
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.left = _value * 1.2
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "out-up": 
        fabric.util.animate({
          startValue: object.top,
          endValue: 0 - height * 4, 
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.top = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "out-down":
        fabric.util.animate({
          startValue: object.top,
          endValue: 1920 + height, 
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.top = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "in-up": 
        fabric.util.animate({
          startValue: 0 - height * 4,
          endValue: top, 
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.top = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "in-down": 
        fabric.util.animate({
          startValue: 1920 + height,
          endValue: top, 
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.top = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "in-left": 
        fabric.util.animate({
          startValue: 0 - width,
          endValue: left, 
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.left = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "in-right": 
        fabric.util.animate({
          startValue: 1920 + width * 4,
          endValue: left, 
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.left = _value
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "zoom-in": 
        fabric.util.animate({
          startValue: 1,
          endValue: 0, 
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.left = left + _value * ( width / 2 ) * scaleX
            object.top = top + _value * ( height / 2 ) * scaleY
            object.scaleX = ( 1 - _value ) * scaleX
            object.scaleY = ( 1 - _value ) * scaleY
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      case "zoom-out": 
        fabric.util.animate({
          startValue: 0,
          endValue: 1, 
          duration: 1000,
          easing: fabric.util.ease.easeInQuad,
          onChange: (_value: any) => {
            object.left = left + _value * ( width / 2 ) * scaleX
            object.top = top + _value * ( height / 2 ) * scaleY
            object.scaleX =  (1 - _value) * scaleX
            object.scaleY = (1 - _value) * scaleY
            canvas.renderAll()
          },
          onComplete: async () => {
            await restoreState(object, canvas)
          }
        })
        break;
      default:
        break;  
    }
  }
}

export async function previewSceneTransition({
  canvas,
  // sceneIdx,
  type,
  duration,
  currentScene,
  nextScene,
  orientation
}: any) {
  // console.log("!@#!@#!@#", {sceneIdx, type, duration, orientation, currentScene, nextScene});
  /**
   * 0. 이전 object 가 있으면 삭제시키기
   * 1. 썸네일 생성
   * 2. 타입에 따라 캔버스 초기 상태 값으로 썸네일 세팅
   *  - 위치(top, left)
   *  - opacity
   *  - scale
   * 3. animate thumbnail
   * 4. animate 종료시 thumbnail 삭제
   */

  //todo 0. 이전 object 삭제시키기

  //1. 썸네일 생성
  const currentThumbnail = await thumbnailScene<string>({
    scene: currentScene, 
    width: orientation === "landscape" ? 1920 : 1080, 
    height: orientation === "landscape" ? 1080 : 1920,
    orientation
  });
  let nextThumbnail;
  if(nextScene) {
    nextThumbnail = await thumbnailScene<string>({
      scene: nextScene, 
      width: orientation === "landscape" ? 1920 : 1080, 
      height: orientation === "landscape" ? 1080 : 1920,
      orientation
    });
  }

  const prepareThumbnailOnCanvas = async function({base64, name}: any) {
    const curImg = new fabric.Image("", {
      left: 0,
      top: 0,
      width: orientation === "landscape" ? 1920 : 1080,
      height: orientation === "landscape" ? 1080 : 1920,
      name,
      // evented: true
    });
    // canvas.add(curImg);
  
    await new Promise<fabric.Image>((resolve) => {
      curImg.setSrc(base64,
        (_img: Fabric.Image) => {
          _img.crossOrigin = "Anonymous";

          const imgInfo = {
            width: _img.width,
            height: _img.height,
            scaleX: _img.scaleX,
            scaleY: _img.scaleY,
          } as { [key: string]: number; }

          _img.scaleX = imgInfo.scaleX / imgInfo.width * (orientation === "landscape" ? 1920 : 1080);
          _img.scaleY = imgInfo.scaleY / imgInfo.height * (orientation === "landscape" ? 1080 : 1920);
          
          resolve(_img);
        },
        { crossOrigin: "anonymous" }
      );    
    })
    // console.log("!@#!@#!@# img height getZoom", curImg.height, canvas.getZoom());
    return curImg
  }

  //2. 썸네일을 캔버스 옆에 띄워두기
  const object1 = await prepareThumbnailOnCanvas({
    base64: currentThumbnail,
    name: "currentThumbnail"
  }) as Fabric.Image & { width: number; height: number; };
  
  const object2 = nextScene
    ? await prepareThumbnailOnCanvas({base64: nextThumbnail, name: "nextThumbnail"}) as
      Fabric.Object & { width: number; height: number; }
    : new fabric.Rect({
      name: "nextThumbnail",
      left: 0,
      top: 0,
      width: orientation === "landscape" ? 1920 : 1080,
      height: orientation === "landscape" ? 1080 : 1920,
    }) as Fabric.Object & { width: number; height: number; };

  // return

  const blackRect = new fabric.Rect({
    name: "blackRect",
    left: 0,
    // left: orientation === "landscape" ? 1920 : 1080,
    top: 0,
    width: orientation === "landscape" ? 1920 : 1080,
    height: orientation === "landscape" ? 1080 : 1920,
    fill: "#000000"
  });

  const whiteRect = new fabric.Rect({
    name: "whiteRect",
    left: 0,
    // left: orientation === "landscape" ? 1920 : 1080,
    top: 0,
    width: orientation === "landscape" ? 1920 : 1080,
    height: orientation === "landscape" ? 1080 : 1920,
    fill: "#ffffff"
  });

  // fabric.util.animate({
  //   startValue: 0,
  //   endValue: 1,
  //   duration: duration * 1000,
  //   easing: fabric.util.ease.easeInQuad,
  //   onChange: (_value: any) => {
  //     object1.opacity = 1 - _value;
  //     object2.opacity = _value;
  //     canvas.renderAll();
  //   },
  //   onComplete: async () => {
  //     canvas.remove(object1);
  //     canvas.remove(object2);
  //     canvas.renderAll();
  //   }
  // });    

  // return

  //장면전환 효과가 끝나고 원래 씬으로 돌아갈때 smooth 하게 돌리는 훅
  const returnToCurrentScene = (_obj: Fabric.Object) => {
    fabric.util.animate({
      startValue: 0,
      endValue: 1,
      duration: 500,
      easing: fabric.util.ease.easeInQuad,
      onChange: (_value2: any) => {
        _obj.opacity = 1 - _value2;
        canvas.renderAll();
      },
      onComplete: async () => {
        canvas.remove(_obj);
        canvas.renderAll();
      }
    });
  }

  switch (type) {
    case "FADE_BLACK_OUT":
      object2.opacity = 0
      canvas.add(blackRect);
      canvas.add(object1);
      canvas.add(object2);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          // console.log(_value)
          if(_value < 0.5) {
            object1.opacity = 1 - _value * 2;
          } else {
            object2.opacity = 2 * _value - 1;  
          }
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.remove(blackRect);
          canvas.renderAll();
        }
      });    
      break;

    case "FADE_WHITE_OUT":
      object2.opacity = 0
      canvas.add(whiteRect);
      canvas.add(object1);
      canvas.add(object2);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          // console.log(_value)
          if(_value < 0.5) {
            object1.opacity = 1 - _value * 2;
          } else {
            object2.opacity = 2 * _value - 1;  
          }
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.remove(whiteRect);
          canvas.renderAll();
        }
      });    
      break;
  
    case "FADE_CROSS_OUT_IN":
      canvas.add(object1);
      canvas.add(object2);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.opacity = 1 - _value;
          object2.opacity = _value;
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;

    case "FADE_EXPEND_OUT":
      object1.originX = "center"
      object1.originY = "center"
      object1.left = (orientation === "landscape" ? 1920 : 1080) / 2
      object1.top = (orientation === "landscape" ? 1080 : 1920) / 2
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();
      // console.log("!@#!@#!@# left top scaleX scaleY ", object1.left, object1.top, object1.scaleX, object1.scaleY);

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.opacity = 1 - _value;
          object1.scaleX = 1 + _value 
          object1.scaleY = 1 + _value;
          // console.log("!@#!@#!@# left top scaleX scaleY ", object1.left, object1.top, object1.scaleX, object1.scaleY);
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;

    case "FADE_COLLAPSE_IN":
      object1.originX = "center"
      object1.originY = "center"
      object1.left = (orientation === "landscape" ? 1920 : 1080) / 2
      object1.top = (orientation === "landscape" ? 1080 : 1920) / 2
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.opacity = 1 - _value;
          object1.scaleX = 1 - _value;
          object1.scaleY = 1 - _value;
          // console.log("!@#!@#!@# left top scaleX scaleY ", object1.left, object1.top, object1.scaleX, object1.scaleY);
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;

    case "ERASE_LEFT_OUT":
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.opacity = 1 - _value;
          object1.left = (-1) * object1.width * _value;
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;
  
    case "ERASE_RIGHT_OUT":
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.opacity = 1 - _value;
          object1.left = object1.width * _value;
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;
    
    case "ERASE_UP_OUT":
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.opacity = 1 - _value;
          object1.top = (-1) * object1.height * _value;
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;

    case "ERASE_DOWN_OUT":
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.opacity = 1 - _value;
          object1.top = object1.height * _value;
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;
        
    case "PUSH_LEFT_OUT_IN":
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.left = (-1) * object1.width * _value;
          object2.left = (-1) * object2.width * (_value - 1);
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;
  
    case "PUSH_RIGHT_OUT_IN":
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.left = object1.width * _value;
          object2.left = object2.width * (_value - 1);
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;
  
    case "PUSH_UPDOWN_OUT_IN":
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.top = (-1) * object1.height * _value;
          object2.top = (-1) * object2.height * (_value - 1);
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;
  
    case "PUSH_DOWNUP_OUT_IN":
      canvas.add(object2);
      canvas.add(object1);
      canvas.renderAll();

      fabric.util.animate({
        startValue: 0,
        endValue: 1,
        duration: duration * 1000,
        easing: fabric.util.ease.easeInQuad,
        onChange: (_value: any) => {
          object1.top = object1.height * _value;
          object2.top = object2.height * (_value - 1);
          canvas.renderAll();
        },
        onComplete: async () => {
          returnToCurrentScene(object2);
          canvas.remove(object1);
          canvas.renderAll();
        }
      });    
      break;




    default:
      break;
  }

}

async function getCoverImage({
  canvas,
}: {
  canvas: fabric.Canvas | fabric.StaticCanvas;
}): Promise<fabric.Image> {
  // console.log("canvas: ", canvas);
  const imageUrl = canvas.toDataURL();

  return new Promise<fabric.Image>((resolve) => {
    fabric.Image.fromURL(imageUrl, (img: Fabric.Image) => {
      // Set the position and size of the image
      // TODO portrait
      const canvasWidth = canvas.getWidth();
      const canvasHeight = canvas.getHeight();
      const scale = 1 / canvas.getZoom();

      const filter = new fabric.Image.filters.Blur({
        blur: 0.1,
      });

      img.set({
        left: 0,
        top: 0,
        width: canvasWidth,
        height: canvasHeight,
        scaleX: scale,
        scaleY: scale,
        filters: [filter],
        selectable: false,
        evented: false,
      });
      img.applyFilters();
      resolve(img);
    });
  });
}

const watermark = {
  id: "watermark",
  type: "watermark",
  top: 0,
  left: 0,
  // source_url: "https://cdn.aistudios.com/images/watermark_new.png",
  source_url: typeof window === "undefined" ? 
  `file://${process.cwd()}/public/images/editor/video_watermark.png` : 
  `${runtimeEnv.NEXTAUTH_URL}/images/editor/video_watermark.png`,
};

/**
 * initial scene
 * @param param0
 * @returns
 */
export async function initCanvasScene({
  canvas,
  scene,
  evented = true,
  smooth = false,
  selectable = true,
  lazy = false,
  callback = {},
}: InitCanvasSceneProps) {
  if (!canvas) return;
  // console.log("init canvas", canvas.name);
  if (typeof window !== "undefined") {
    // console.time("init font " + canvas.name);
    const clipFonts = scene?.clips
      .filter(({ type }) => type === "textImage" || type === "captions")
      .map(({ fontFamily, fontWeight, fontStyle, src = '', custom = false, }) => ({
        fontFamily,
        fontWeight,
        fontStyle,
        key: [fontFamily, fontWeight, fontStyle].join("/"),
        custom,
        src,
      }))
      .filter(
        ({ key }, idx, arr) => arr.findIndex((itm) => itm.key === key) === idx
      );
      
    // 기존 코드가 분석 및 폰트 로드 문제가 지속적으로 발생하여 변경함
    /**
     * Custom Font / Default Font 나눠서 등록
     * -> 에러났을때 통합적으로 기본폰트는 NotoSansCJK 
     */
    for(const clipFont of clipFonts) {
      try {
        /**
         * CustomFont의 경우
         * 폰트가 등록되어있는지 안되어있는지부터 체크한다
         */
        if(clipFont?.custom && clipFont?.src) {
          const fontFamily = clipFont.fontFamily || "Noto Sans CJK";
          const isCustomFontRegistered = Array.from(document.fonts).some(
            (font) =>
              font.family === fontFamily &&
              (!clipFont.fontWeight || font.weight === clipFont.fontWeight) &&
              (!clipFont.fontStyle || font.style === clipFont.fontStyle)
          );
          /**
           * font가 등록되어있지 않은경우 등록처리 
           */
          if(!isCustomFontRegistered) {
            const customFont = new FontFace(fontFamily, `url(${clipFont.src})`, {
              weight: clipFont.fontWeight,
              style: clipFont.fontStyle,
            });
            customFont
            .load()
            .then(() => {
              document.fonts.add(customFont);
            })
            .catch((error) => {
              console.error(`InitCanvasScene CustomFont Load Failed `, error);
              // throw new Error(`InitCanvasScene CustomFont Load Failed`);
            })
          }
        } 
        /**
         * 기본 폰트의 경우
         */
        else {
          const defaultFont = new FontFaceObserver(clipFont.fontFamily || "Noto Sans CJK", {
            weight: clipFont.fontWeight,
            style: clipFont.fontStyle,
          });
          defaultFont
          .load(null, 1000 * 20)
          .then(() => {
          })
          .catch((error) => {
            console.error(`InitCanvasScene DefaultFont Load Failed `, error);
            // throw new Error(`InitCanvasScene DefaultFont Load Failed`);
          })
        }

      } catch(error) {
        console.error(`InitCanvasScene TotalFont load error `, {error});
        document.body.style.fontFamily = 'Noto Sans CJK';
      }
    }

    // for (const f of clipFonts) {
    //   const font = new FontFaceObserver(f.fontFamily || "Noto Sans CJK", {
    //     weight: f.fontWeight,
    //     style: f.fontStyle,
    //   });
    //   await font.load(null, 1000 * 20).catch((error) => 
    //   document.body.style.fontFamily = 'Noto Sans CJK');
    // }
    // console.timeEnd("init font " + canvas.name);
  } else {
    fontList.fonts.map((font) => {
      const fontOption = Object.entries(font).reduce((acc, [key, value]) => {
        if (key === "file") {
          return acc;
        }
        return { ...acc, [key]: value };
      }, {}) as { family: string, weight?: string, style?: string };
      fabric.nodeCanvas.registerFont(`${process.cwd()}/${font.file}`, fontOption);
    });

    for(const clip of scene.clips) {
      try {
        if ((clip.type === "textImage" || clip.type === 'captions') && clip.savePath) {
          fabric.nodeCanvas.registerFont(`${clip.savePath}`, {
            family: clip.fontFamily,
            weight: clip.fontWeight,
            style: clip.fontStyle
          });
        }
      } catch (err) {
        console.error('[canvas] registerFont error', err);
      }
    }
  }

  fabric.util.clearFabricFontCache();

  if (scene) {
    let coverImg: Fabric.Image | null = null;
    if (smooth) {
      //
      const objects = canvas.getObjects();
      coverImg = await getCoverImage({ canvas });
      canvas.add(coverImg);
      objects.forEach((object: Fabric.Object) => canvas.remove(object));
    } else {
      if (lazy) {
        const objects = canvas.getObjects();
        objects.forEach((object: Fabric.Object) => canvas.remove(object));
      } else {
        canvas.clear();
      }
    }

    if (callback.onAfterClear) {
      callback.onAfterClear(canvas);
    }

    await addBackground({
      canvas,
      evented,
      lazy,
      ...scene.background,
    });

    /**
     * 이펙트 관련 처리
     */
    let effectBlots: {
      effectClipId: string
      /**
       * 0부터 시작하는 순서
       */
      order: number
    }[] = []

    const firstScript = scene.scripts?.[0]

    /**
     * 스크립트에서 이펙트 타이밍 블록 찾기
     * - 오디오 스크립트가 아닌 경우에만 이펙트가 스크립트에 포함되어 있음
     * - 클라이언트 사이드에서만 실행되도록 함
     */
    if (
      typeof window !== 'undefined' &&
      !(firstScript?.isTTS && firstScript?.tts.type === 'audio')
    ) {
      effectBlots = scene.scripts
        .map((script) => {
          const parser = new DOMParser()
          const parsed = parser.parseFromString(script.org, 'text/html')

          return [...parsed.querySelectorAll('effect')]
        })
        .flat()
        .map((blot, i) => ({
          effectClipId: blot.getAttribute('effect-clip-id') || '',
          order: i,
        }))
    }

    // selectable = canvas.name === "preview" ? false : selectable
    // toSorted, 20.0...
    await [...scene.clips]
      .sort((a: any, b: any) => a.layer - b.layer)
      .reduce(async (acc: Promise<void>, clip: any, i: number) => {
        await acc;

        const { id = `xxx_${i}`, type, ...props } = clip;
        // console.log("add", props);
        switch (type) {
          case "aiModel":
            const headOnly = clip.headOnly && !clip.headOnly.src
              ? await getHeadOnly({ clip, shape: clip.headOnly.shape })
              : clip.headOnly;
            await addAiModel({ canvas, id, evented, ...props, headOnly });
            break;
          case "videoImage":
            if (!props?.video_url) break;
          case "image":
            if(clip.source_url.includes("pixabay.com")) break;
            await addImage({ canvas, id, evented, selectable, type, ...props });
            break;
          case "textImage":
            addTextBox({ canvas, id, evented, selectable, ...props });
            break;
          case "shape":
            await addShape({ canvas, id, evented, selectable, ...props });
            break;
          case "audio":
            break;
          case "captions":
            await addCaptions({ canvas, id, type, ...props });
            break;
          case 'effect': {
            const foundEffectBlot = effectBlots.find(
              (blot) => blot.effectClipId === clip.id,
            )

            const connected = !!foundEffectBlot
            const order = foundEffectBlot?.order

            await addEffect({
              canvas,
              id,
              evented,
              selectable,
              ...props,
              effectMeta: {
                ...props.effectMeta,
                connected,
                order,
              },
              lazy
            })

            break;
          }
          default:
            addRect({ canvas, id, evented, selectable, ...props });
            break;
        }
        if (coverImg) {
          coverImg.bringToFront();
          // await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }, Promise.resolve());
    // watermark
    if (scene.watermark) {
      await addWatermark({ canvas, lazy, ...watermark });
    }
    canvas.remove(coverImg);

    if (callback.onBeforeRenderAll) {
      callback.onBeforeRenderAll(canvas);
    }
    try {
      if (!lazy) {
        canvas.renderAll();
      }      
    } catch (error) {
      
    }
  }

  /**
   * canvas에 모두 그려지면 호출
   */
  callback.onRenderedAll?.()
}

export async function initPreviewCanvasScene(params: {
  canvas: (fabric.Canvas | fabric.StaticCanvas) & { renderKey?: string };
  scene: Scene;
  key: string;
  cover?: boolean;
}) {
  const { canvas, scene, key, cover } = params;

  if (!canvas || !scene) {
    return false;
  }

  if (cover) {
    const coverImage = await getCoverImage({ canvas });
    canvas.add(coverImage);
    canvas.renderAll();
  }

  canvas.renderKey = key;

  const canvasWidth = canvas.getWidth() / canvas.getZoom();
  const canvasHeight = canvas.getHeight() / canvas.getZoom();

  const defaultCanvasColor = new fabric.Rect({
    left: 0,
    top: 0,
    width: canvasWidth,
    height: canvasHeight,
    fill: "#fff",
  });

  const backgroundClip = (async () => {
    if (!scene.background) {
      return null;
    }

    const {
      id,
      source_type: sourceType,
      source_url: sourceUrl,
      source_color: sourceColor,
      size = "auto",
    } = scene.background;

    if (sourceType === "color") {
      return new fabric.Rect({
        name: id,
        left: 0,
        top: 0,
        width: canvasWidth,
        height: canvasHeight,
        fill: sourceColor,
      });
    }

    if (!sourceUrl) {
      return null;
    }

    return new Promise<fabric.Image>((resolve) => {
      const image = new fabric.Image("", {
        name: id,
        top: 0,
        left: 0,
      });
      image.setSrc(sourceUrl, () => {
        if (size === "auto") {
          if (canvasWidth > canvasHeight) {
            image.scaleToWidth(canvasWidth);
          } else {
            image.scaleToHeight(canvasHeight);
          }
        }
        if (size === "contain") {
          const imageWidth = image.width || 0;
          const imageHeight = image.height || 0;
          const imageScaleX = image.scaleX || 1;
          const imageScaleY = image.scaleY || 1;

          if (canvasWidth > canvasHeight) {
            if (imageHeight / imageWidth <= 9 / 16) {
              const scale = canvasWidth / imageWidth;
              image.set("scaleX", imageScaleX * scale);
              image.set("scaleY", imageScaleY * scale);
              image.left = 0;
              image.top = (canvasHeight - imageHeight * scale) / 2;
            } else {
              const scale = canvasHeight / imageHeight;
              image.set("scaleX", imageScaleX * scale);
              image.set("scaleY", imageScaleY * scale);
              image.left = (canvasWidth - imageWidth * scale) / 2;
              image.top = 0;
            }
          } else {
            if (imageHeight / imageWidth > 16 / 9) {
              const scale = canvasHeight / imageHeight;
              image.set("scaleX", imageScaleX * scale);
              image.set("scaleY", imageScaleY * scale);
              image.left = (canvasWidth - imageWidth * scale) / 2;
              image.top = 0;
            } else {
              const scale = canvasWidth / imageWidth;
              image.set("scaleX", imageScaleX * scale);
              image.set("scaleY", imageScaleY * scale);
              image.left = 0;
              image.top = (canvasHeight - imageHeight * scale) / 2;              
            }
          }  
        }
        if (size === "cover") {
          //
        }
        resolve(image);
      }, { crossOrigin: "anonymous" });
    });
  })();

  const watermarkClip = (async () => {
    if (!scene.watermark) {
      return null;
    }

    return new Promise<fabric.Image>((resolve) => {
      const image = new fabric.Image("", {
        name: "watermark",
        top: 0,
        left: 0,
      });
      image.setSrc("/images/editor/video_watermark.png", () => {
        image.scaleToWidth(canvasWidth * 0.3);

        const imageWidth = (image.width || 0) * (image.scaleX || 1);
        const imageHeight = (image.height || 0) * (image.scaleY || 1);

        image.set("top", canvasHeight * 0.88 - imageHeight);
        image.set("left", (canvasWidth - imageWidth) / 2);
        resolve(image);
      }, { crossOrigin: "anonymous" });
    });
  })();
  
  const clips = [...scene.clips]
    .sort((a, b) => a.layer - b.layer)
    .map(async (clip) => {
      const { id, type, shadow, ...props } = clip;
      
      if (clip.type === "aiModel") {
        return new Promise<fabric.Image>(async (resolve) => {
          const headOnlySrc = await (async () => {
            if (!clip.headOnly) {
              return null;
            }

            if (!clip.headOnly.src) {
              const headOnlyData = await getHeadOnly({ clip, shape: clip.headOnly.shape })
              return headOnlyData.src;
            }

            return clip.headOnly.src;
          })();
          const src = headOnlySrc || clip.model.source_url;
          const opacity = clip.voiceOnly || clip.isDelete ? 0 : 1;

          const image = new fabric.Image("", {
            ...props,
            name: clip.id,
          });
          image.setSrc(src, () => {
            image.set("top", clip.top);
            image.set("left", clip.left);
            image.set("scaleX", clip.scaleX || 1);
            image.set("scaleY", clip.scaleY || 1);
            image.set("opacity", opacity);
            resolve(image);
          }, { crossOrigin: "anonymous" });
        });
      }
      if (clip.type === "videoImage" || clip.type === "image") {
        return new Promise<fabric.Image | null>((resolve) => {
          if (!clip.source_url) {
            return resolve(null);
          }
          const opacity = clip.opacity ? clip.opacity / 100 : 1;

          const image = new fabric.Image("", {
            ...props,
            name: clip.id,
          });
          image.setSrc(clip.source_url, () => {
            image.set("top", clip.top);
            image.set("left", clip.left);
            image.set("scaleX", clip.scaleX || 1);
            image.set("scaleY", clip.scaleY || 1);
            image.set("opacity", opacity);
            setClipping({ object: image, shape: clip.clipping });
            resolve(image);
          }, { crossOrigin: "anonymous" });
        });
      }
      if (clip.type === "textImage") {
        const { text = "", breakWord = false } = clip;

        const splitByGrapheme = !(text.trim().includes(' ') && breakWord);
        const textBoxValues = {
          ...props,
          name: clip.id,
          top: clip.top,
          left: clip.left,
          width: clip.width,
          height: clip.height,
          scaleX: clip.scaleX || 1,
          scaleY: clip.scaleY || 1,
          text: clip.text,
          fontFamily: clip.fontFamily,
          fontWeight: clip.fontWeight ? clip.fontWeight : "normal",
          strokeWidth: clip.strokeWidth || 0,
          splitByGrapheme,
          charSpacing: clip.fontFamily === "Gmarket Sans"
            ? -0.0375
            : clip.charSpacing,
          shadow: clip.shadow && clip.shadow.enabled
            ? new fabric.Shadow(clip.shadow)
            : undefined,
        }

        return new fabric.Textbox(text, textBoxValues);
      }
      if (clip.type === "shape") {
        const shapeDef = await getSVGPathDefinition(clip.shapeName + ".svg");

        if (!shapeDef) {
          return null;
        }

        return new fabric.Path(shapeDef, {
          ...props,
          name: clip.id,
          top: clip.top,
          left: clip.left,
          width: clip.width,
          height: clip.height,
          scaleX: clip.scaleX || 1,
          scaleY: clip.scaleY || 1,
          stroke: clip.stroke,
          strokeWidth: clip.strokeWidth ?? 3,
          fill: clip.fill ?? "#d9f8fb",
          angle: clip.angle,
          skewX: clip.skewX,
          skewY: clip.skewY,
          shadow: clip.shadow && clip.shadow.enabled
            ? new fabric.Shadow(clip.shadow)
            : undefined,
        });
      }
      if (clip.type === "captions") {
        return new fabric.Textbox(clip.text || "", {
          ...props,
          name: clip.id,
          top: clip.top,
          left: clip.left,
          width: clip.width,
          height: clip.height,
          scaleX: clip.scaleX || 1,
          scaleY: clip.scaleY || 1,
          text: clip.text,
          fontFamily: clip.fontFamily,
          fontWeight: clip.fontWeight ? clip.fontWeight : "normal",
          strokeWidth: clip.strokeWidth || 0,
          splitByGrapheme: true,
          charSpacing: clip.fontFamily === "Gmarket Sans"
            ? -0.0375
            : clip.charSpacing,
        });
      }
      if (clip.type === "effect") {
        return new Promise<EffectVideoFabricObjectClass | null>((resolve) => {
          if (!clip.effectMeta) {
            return resolve(null);
          }

          const effect = new EffectVideoFabricObject('', {
            ...props,
            name: clip.id,
            top: clip.top,
            left: clip.left,
            width: clip.width,
            height: clip.height,
            effectMeta: clip.effectMeta,
          })

          if ("onCanPlayThrough" in effect) {
            effect.onCanPlayThrough(() => {
              resolve(effect)
            });
          }
        });
      }

      return null;
    });

  await Promise.all([
    defaultCanvasColor,
    backgroundClip,
    ...clips,
    watermarkClip
  ]).then((values) => {
    if (canvas.renderKey === key) {
      canvas.getObjects().forEach((obj: fabric.Object) => canvas.remove(obj));
      values.filter((obj) => !!obj).forEach((obj) => canvas.add(obj));
    }
  });

  return canvas.renderKey === key;
}

export async function drawCanvasScene({
  canvas,
  scene,
  fps,
  frame = 0,
}: DrawCanvasSceneProps) {
  if (scene) {
    // toSorted, 20.0...
    await [...scene.clips]
      .sort((a: any, b: any) => a.layer - b.layer)
      .reduce(async (acc: Promise<void>, clip: any, i: number) => {
        await acc;

        const { id = `xxx_${i}`, type, ...props } = clip;
        switch (type) {
          case "aiModel":
            if (props?.isDelete !== true) {
              await drawAiModel({
                canvas,
                id,
                frame,
                fps,
                evented: false,
                ...props,
              });
            }
            break;
          case "videoImage":
            if (props?.video_url) {
              await drawVideoImage({
                canvas,
                id,
                frame,
                fps,
                evented: false,
                ...props,
              });
            }
          case "audio":
            break;
          case "captions":
            await drawCaption({
              clip,
              canvas,
              fps,
              frame,
              ...props,
            });
            break;
          case "effect":
            await drawEffect({
              canvas,
              id,
              frame,
              ...props
            });
          default:
            await drawAnimationObject({
              clip,
              canvas,
              id,
              frame,
              fps,
              evented: false,
              ...props,
            });
            break;
        }
      }, Promise.resolve());
    canvas.renderAll();
  }
}

export async function thumbnailScene<T extends (string | Blob)>(props: ThumbnailSceneProps): Promise<T> {
  const {
    scene,
    width = 208,
    height = 117,
    orientation = "landscape",
    reverseAxis = true,
    canvasName = "thumbnail",
    exportType = "base64",
  } = props;

  const canvas = createFabricCanvas(null, { 
    backgroundColor: "white",
    // fireRightClick: true,
    // stopContextMenu: true
  }, true);
  canvas.name = canvasName;

  let w = width;
  let h = height;
  
  if (orientation !== "landscape" && reverseAxis) {
    w = height;
    h = width;
  }

  initCanvasSize({ canvas, width: w, height: h, orientation });

  const reScene: Scene = { ...scene, clips: await Promise.all(scene.clips.map( async (v) => {
    if (v.type === "aiModel" && v.headOnly && !v.headOnly.src && v.isDelete !== true) {
      return { ...v, headOnly: await getHeadOnly({ clip: v, shape: v.headOnly.shape })};
    }
    return v;
  }))};
  
  await initCanvasScene({ canvas, scene: reScene, evented: false, smooth: true });
  const data = canvas.toDataURL();
  disposeCanvas(canvas);

  if (exportType === "objectUrl") {
    return<T> base64ToBlob<string>({ base64: data });
  }

  if (exportType === "blob") {
    return<T> base64ToBlob<Blob>({ base64: data, exportType: "blob" });
  }
  
  return<T> data;
}

export async function thumbnailScenes<T extends (string | Blob)>(
  props: Omit<ThumbnailSceneProps, "scene"> & {
    scenes: (Scene | null)[];
    config?: {
      format: "png" | "jpeg";
      quality: number;
    }
  }
): Promise<(T | null)[]> {
  const {
    scenes,
    width = 208,
    height = 117,
    orientation = "landscape",
    canvasName = "thumbnails",
    exportType = "base64",
    config = { format: "png", quality: 1 },
  } = props;

  const canvas = createFabricCanvas(null, { 
    backgroundColor: "white",
  }, true);
  canvas.name = canvasName;

  let w = width;
  let h = height;

  initCanvasSize({ canvas, width: w, height: h, orientation });

  const results = await scenes.reduce(
    async (acc: Promise<(string | Blob | null)[]>, item: (Scene | null)) => {
      const prev = await acc;

      if (item === null) {
        return [...prev, null];
      }

      const reScene: Scene = {
        ...item,
        clips: await Promise.all(item.clips.map( async (v) => {
          if (
            v.type === "aiModel"
            && v.headOnly
            && !v.headOnly.src
            && v.isDelete !== true
          ) {
            return { ...v, headOnly: await getHeadOnly({ clip: v, shape: v.headOnly.shape })};
          }
          return v;
        }))
      };
      
      await initCanvasScene({
        canvas,
        scene: reScene,
        evented: false,
        smooth: true
      });
      const data = canvas.toDataURL(config);
    
      if (exportType === "objectUrl") {
        return [...prev, base64ToBlob<string>({ base64: data })];
      }

      if (exportType === "blob") {
        return [...prev, base64ToBlob<Blob>({ base64: data, exportType: "blob" })];
      }
      
      return [...prev, data];
    },
    Promise.resolve([])
  );

  disposeCanvas(canvas);

  return<(T | null)[]> results;
}

export async function insertObject({
  canvas,
  id,
  type,
  index,
  width = 100,
  height = 100,
  evented,
  ...props
}: InsertObjectProps): Promise<{
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  scaleX?: number;
  scaleY?: number;
}> {
  // console.log('>>>>> captions insertObject ', {id}, {props}, {width}, {height}, {type})
  let { left, top } = props;
  const canvasWidth = canvas.getWidth() / canvas.getZoom();
  const canvasHeight = canvas.getHeight() / canvas.getZoom();
  if (left === undefined) {
    left = (canvasWidth - width) / 2;
  }
  if (top === undefined) {
    top = (canvasHeight - height) / 2;
  }

  const watermarkBringToFront = async () => {
    const object = canvas.getObjects().find( (obj: any) => obj.name === "watermark");
    if(object) {
      canvas.remove(object);
      await addWatermark({ canvas, ...watermark })
    }
  }
  switch (type) {
    case "aiModel":
      const model = await addAiModel({
        canvas,
        ...props,
        id,
        evented,
        width,
        height,
      });
      await watermarkBringToFront();
      setTimeout(() => {
        canvas.setActiveObject(model);
        canvas.renderAll();
      });
      return {
        left: model.left,
        top: model.top,
        width: model.width,
        height: model.height,
        scaleX: model.scaleX || 1,
        scaleY: model.scaleY || 1,
      };
    case "text":
    case "textImage": {
      const textBox = addTextBox({
        canvas,
        ...props,
        id,
        type,
        left,
        top,
        width,
        height,
        evented,
      });
      await watermarkBringToFront();
      setTimeout(() => {
        canvas.setActiveObject(textBox);
        canvas.renderAll();
      });

      return {
        left: textBox.left,
        top: textBox.top,
        width: textBox.width,
        height: textBox.height,
        scaleX: textBox.scaleX || 1,
        scaleY: textBox.scaleY || 1,
      };
    }
    case "videoImage":
    case "image": {
      
      console.log('videoImage props: ', props)
      const image = await addImage({
        canvas,
        ...props,
        id,
        type,
        evented,
        width
      });
      console.log('addImage image: ', image)
      
      await watermarkBringToFront();
      setTimeout(() => {
        canvas.setActiveObject(image);
        canvas.renderAll();
      });
      return {
        left: image.left,
        top: image.top,
        width: image.width,
        height: image.height,
        scaleX: image.scaleX || 1,
        scaleY: image.scaleY || 1,
      };
    }
    case "shape": {
      const shape = await addShape({
        canvas,
        ...props,
        id, 
        type, 
        left, 
        top, 
        width, 
        height, 
        evented,
      })
      await watermarkBringToFront();
      setTimeout(() => {
        canvas.setActiveObject(shape);
        canvas.renderAll();
      });
      return {
        left,
        top,
        width,
        height,
        scaleX: shape?.scaleX || 1,
        scaleY: shape?.scaleY || 1,
        // left: shape.left,
        // top: shape.top,
        // width: shape.width,
        // height: shape.height,
      };
    }
    case 'audio': {      
      /**
       * audio add -> canvas active object remove
       */
      setTimeout(() => {
        canvas.discardActiveObject();
        canvas.requestRenderAll();
      });

      return {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      }
    }
    case 'captions': {
      const captions = await addCaptions({
        canvas,
        ...props,
        id,
        type,
      });
      await watermarkBringToFront();
      setTimeout(() => {
        canvas.setActiveObject(captions);
        canvas.renderAll();
      });
      return {
        left,
        top,
        width,
        height,
        scaleX: captions.scaleX || 1,
        scaleY: captions.scaleY || 1,
      }
    }
    case 'effect': {
      const effect = await addEffect({
        canvas,
        ...props,
        id,
        type,
        width,
        height,
        evented,
      })

      await watermarkBringToFront();

      /**
       * 생성 후 잠시 후에 선택해야 함\
       * 상단 메뉴에 선택된 객체용 버튼들을 보여주기 위함
       */
      setTimeout(() => {
        canvas.setActiveObject(effect);
      });

      return {
        left: effect.left,
        top: effect.top,
        width: effect.width,
        height: effect.height,
        scaleX: effect.scaleX || 1,
        scaleY: effect.scaleY || 1,
      };
    }
    default:
      return {};
  }
}

export function discardForAudioObject({
  canvas,
}: {
  canvas: Fabric.Canvas;
  id: string;
  type: string;
}) {
  canvas.discardActiveObject();
  canvas.requestRenderAll();
}

export function deleteObjects({
  canvas,
  names,
}: {
  canvas: Fabric.Canvas;
  names: string[];
}) {
  canvas.getObjects().forEach((object) => {
    if (object.name !== undefined && names.includes(object.name) || names.includes("-?-")) {
      // console.log("hi hi hi", {object})
      canvas.remove(object);
    }
  });

  canvas.discardActiveObject();
  canvas.requestRenderAll();
}

export function sortLayer({ canvas, scene }: { canvas: Fabric.Canvas; scene: Scene }) {
  const objects = canvas.getObjects();
  [...scene.clips]
    .sort((a: any, b: any) => a.layer - b.layer)
    .forEach((clip) => {
      const object = objects.find((obj) => obj.name === clip.id);
      if (object) object.moveTo(clip.layer);
    });

  // watermark
  const object = objects.find((obj) => obj.name === watermark.id);
  if (object) object.moveTo(scene.clips.length + 1);
}

export function genId(type: string, clips: Pick<Clip, "id">[]): string {
  const timestamp: number = Date.now();
  const random: number = Math.floor(Math.random() * 1000);
  const id = `${type}-${timestamp.toString(32)}${random.toString(32)}`;
  if (clips.some((clip) => clip.id === id)) {
    return genId(type, clips);
  }
  return id;
}

/**
 * 중복 없는 씬 아이디 생성 (이미 생성된 씬 리스트를 넘기는 경우)
 */
export function genSceneId(scenes: (Scene | undefined)[] = []) {
  const timestamp: number = Date.now();
  const random: number = Math.floor(Math.random() * 1000);
  const id = `scene-${timestamp.toString(32)}${random.toString(32)}`;
  if (scenes.some((scene) => scene?.id === id)) {
    return genSceneId(scenes);
  }
  return id;
}

export function rgba({ r, g, b, a }: any) {
  return [
    "#",
    r.toString(16).padStart(2, "0"),
    g.toString(16).padStart(2, "0"),
    b.toString(16).padStart(2, "0"),
    Math.round(a * 255)
      .toString(16)
      .padStart(2, "0"),
  ].join("");
}

export function getCommonBoundingBox(selectedObjects: Clip[]) {
  // const { canvas } = canvasHolder;
  // const selectedObjects = canvas.getActiveObjects();
  if (!selectedObjects || selectedObjects.length === 0) return null;

  let minX = Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxX = Number.MIN_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;

  selectedObjects.forEach((obj) => {
    const { left, top, width, height, scaleX = 1, scaleY = 1} = obj;
    // console.log("!@#!@#!@#", {left, top, width, height})
    if (left < minX) minX = left;
    if (top < minY) minY = top;
    if (left + width > maxX) maxX = left + width * scaleX;
    if (top + height > maxY) maxY = top + height * scaleY;
  });

  return {
    left: minX,
    top: minY,
    width: maxX - minX,
    height: maxY - minY,
    center: minX + (maxX - minX) / 2,
    middle: minY + (maxY - minY) / 2
  };
}

export function removeAllObject({ canvas }: { canvas: Fabric.Canvas; }) {
  canvas.remove(...canvas.getObjects());
}

export function animateScene(
  canvas: SceneAnimationProps,
  startTime: number = 0
) {
  const canvasWidth = canvas.getWidth() / canvas.getZoom();
  const canvasHeight = canvas.getHeight() / canvas.getZoom();
  const objects: SceneAnimationObject[] = canvas.getObjects().filter(
    (obj: SceneAnimationObject) => obj.animation && obj.animation.type !== ""
  );
  const easingFunctions = { ...fabric.util.ease } as {
    [key in SceneAnimationEasingName]: SceneAnimationEasingFunction
  };
  function getEasing(
    fn: SceneAnimationEasingFunction,
    delay: number,
    start: number
  ): SceneAnimationEasingFunction {
    return function(t, b, c, d) {
      if (d <= 0) {
        return c;
      }
      if (t + start <= delay) {
        return b;
      }
      return fn(t - delay + start, b, c, d - delay + start);
    }
  }

  return objects.map((object) => {
    const padding = (object.padding || 0);
    const width = (object.width || 0) + padding;
    const height = (object.height || 0) + padding;
    const { left = 0, top = 0, scaleX = 1, scaleY = 1 } = object;

    const [startValue, endValue] = [0, 1];
    const delay = object.animation.delay * 1000;
    const duration = delay + object.animation.duration * 1000 - startTime;

    const easingFunction = (() => {
      if (
        object.animation.easing
        && object.animation.easing in easingFunctions
      ) {
        const easingName = object.animation.easing as SceneAnimationEasingName;
        return easingFunctions[easingName];
      }
      return easingFunctions.easeInQuad;
    })()
    const easing = getEasing(easingFunction, delay, startTime);
  
    switch (object.animation.type) {
      case "fade-in": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.opacity = _value;
            },
          }),
          restore: () => {
            object.opacity = startValue;
          },
        }
      }
      case "fade-out": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.opacity = 1 - _value;
            },
          }),
          restore: () => {
            object.opacity = 1 - startValue;
          },
        }
      }
      case "out-left": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.left = left - (_value * (width + left));
            },
          }),
          restore: () => {
            object.left = left - (startValue * (width + left));
          },
        }
      }
      case "out-right": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.left = left + (_value * (canvasWidth - left));
            },
          }),
          restore: () => {
            object.left = left + (startValue * (canvasWidth - left));
          },
        }
      }
      case "out-up": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.top = top - (_value * (height + top));
            },
          }),
          restore: () => {
            object.top = top - (startValue * (height + top));
          },
        }
      }
      case "out-down": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.top = top + (_value * (canvasHeight - top));
            },
          }),
          restore: () => {
            object.top = top + (startValue * (canvasHeight - top));
          },
        }
      }
      case "in-left": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.left = left - ((1 - _value) * (width + left));
            },
          }),
          restore: () => {
            object.left = left - ((1 - startValue) * (width + left));
          },
        }
      }
      case "in-right": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.left = left + ((1 - _value) * (canvasWidth - left));
            },
          }),
          restore: () => {
            object.left = left + ((1 - startValue) * (canvasWidth - left));
          },
        }
      }
      case "in-up": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.top = top - ((1 - _value) * (height + top));
            },
          }),
          restore: () => {
            object.top = top - ((1 - startValue) * (height + top));
          },
        }
      }
      case "in-down": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.top = top + ((1 - _value) * (canvasHeight - top));
            },
          }),
          restore: () => {
            object.top = top + ((1 - startValue) * (canvasHeight - top));
          },
        }
      }
      case "zoom-in": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.left = left + (1 - _value) * (width / 2) * scaleX;
              object.top = top + (1 - _value) * (height / 2) * scaleY;
              object.scaleX = _value * scaleX;
              object.scaleY = _value * scaleY;
            },
          }),
          restore: () => {
            object.left = left + (1 - startValue) * (width / 2) * scaleX;
            object.top = top + (1 - startValue) * (height / 2) * scaleY;
            object.scaleX = startValue * scaleX;
            object.scaleY = startValue * scaleY;
          },
        }
      }
      case "zoom-out": {
        return {
          cancel: fabric.util.animate({
            startValue,
            endValue,
            duration,
            easing,
            onChange: (_value: number) => {
              object.left = left + _value * (width / 2) * scaleX;
              object.top = top + _value * (height / 2) * scaleY;
              object.scaleX =  (1 - _value) * scaleX;
              object.scaleY = (1 - _value) * scaleY;
            },
          }),
          restore: () => {
            object.left = left + startValue * (width / 2) * scaleX;
            object.top = top + startValue * (height / 2) * scaleY;
            object.scaleX =  (1 - startValue) * scaleX;
            object.scaleY = (1 - startValue) * scaleY;
          },
        }
      }
      default: {
        return {
          cancel: () => {},
          restore: () => {},
        };
      }
    }
  }) as { cancel: () => void; restore: () => void }[];
}

export function animateTransition(params: {
  canvas: SceneAnimationProps;
  transition: {
    type: string;
    duration: number;
    easing?: string;
  };
  objects: {
    prevSceneObject: fabric.Image;
    nextSceneObject: fabric.Image | fabric.Rect;
    blackRect: fabric.Rect;
    whiteRect: fabric.Rect;
  };
  onComplete: () => void;
  startTime?: number;
}) {
  const animate = fabric.util.animate as (
    (options?: fabricModule.IUtilAnimationOptions) => () => void
  );
  const { canvas, transition, objects, onComplete, startTime = 0 } = params;
  const canvasWidth = canvas.getWidth() / canvas.getZoom();
  const canvasHeight = canvas.getHeight() / canvas.getZoom();
  const { prevSceneObject, nextSceneObject, blackRect, whiteRect } = objects;
  const easingFunctions = { ...fabric.util.ease } as {
    [key in SceneAnimationEasingName]: SceneAnimationEasingFunction
  };
  function getEasing(
    fn: SceneAnimationEasingFunction,
    delay: number,
    start: number
  ): SceneAnimationEasingFunction {
    return function(t, b, c, d) {
      if (d <= 0) {
        return c;
      }
      if (t + start <= delay) {
        return b;
      }
      return fn(t - delay + start, b, c, d - delay + start);
    }
  }

  const [startValue, endValue] = [0, 1];
  const duration = transition.duration * 1000 - startTime;

  const easingFunction = (() => {
    if (
      transition.easing
      && transition.easing in easingFunctions
    ) {
      const easingName = transition.easing as SceneAnimationEasingName;
      return easingFunctions[easingName];
    }
    return easingFunctions.easeInQuad;
  })()
  const easing = getEasing(easingFunction, 0, startTime);
  const restore = () => {
    canvas.remove(prevSceneObject);
    canvas.remove(blackRect);
    canvas.remove(whiteRect);
    canvas.remove(nextSceneObject);
  }

  switch (transition.type) {
    case "FADE_BLACK_OUT": {
      prevSceneObject.opacity = 1;
      nextSceneObject.opacity = 0;
      blackRect.opacity = 1;

      canvas.add(blackRect);
      canvas.add(prevSceneObject);
      canvas.add(nextSceneObject);

      canvas.bringToFront(blackRect);
      canvas.bringToFront(prevSceneObject);
      canvas.bringToFront(nextSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            if (_value < 0.5) {
              prevSceneObject.opacity = 1 - (_value * 2);
            } else {
              nextSceneObject.opacity = (2 * _value) - 1;
            }
          },
        }),
        restore,
      }
    }
    case "FADE_WHITE_OUT": {
      prevSceneObject.opacity = 1;
      nextSceneObject.opacity = 0;
      whiteRect.opacity = 1;

      canvas.add(whiteRect);
      canvas.add(prevSceneObject);
      canvas.add(nextSceneObject);

      canvas.bringToFront(whiteRect);
      canvas.bringToFront(prevSceneObject);
      canvas.bringToFront(nextSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            if (_value < 0.5) {
              prevSceneObject.opacity = 1 - (_value * 2);
            } else {
              nextSceneObject.opacity = (2 * _value) - 1;
            }
          },
        }),
        restore,
      }
    }
    case "FADE_CROSS_OUT_IN": {
      prevSceneObject.opacity = 1;
      nextSceneObject.opacity = 0;

      canvas.add(prevSceneObject);
      canvas.add(nextSceneObject);

      canvas.bringToFront(prevSceneObject);
      canvas.bringToFront(nextSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.opacity = 1 - _value;
            nextSceneObject.opacity = _value;
          },
        }),
        restore,
      }
    }
    case "FADE_EXPEND_OUT": {
      prevSceneObject.originX = "center";
      prevSceneObject.originY = "center";
      prevSceneObject.left = canvasWidth / 2;
      prevSceneObject.top = canvasHeight / 2;

      canvas.add(nextSceneObject);
      canvas.add(prevSceneObject);

      canvas.bringToFront(nextSceneObject);
      canvas.bringToFront(prevSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.opacity = 1 - _value;
            prevSceneObject.scaleX = 1 + _value 
            prevSceneObject.scaleY = 1 + _value;
          },
        }),
        restore,
      }
    }
    case "FADE_COLLAPSE_IN": {
      prevSceneObject.originX = "center";
      prevSceneObject.originY = "center";
      prevSceneObject.left = canvasWidth / 2;
      prevSceneObject.top = canvasHeight / 2;

      canvas.add(nextSceneObject);
      canvas.add(prevSceneObject);

      canvas.bringToFront(nextSceneObject);
      canvas.bringToFront(prevSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.opacity = 1 - _value;
            prevSceneObject.scaleX = 1 - _value;
            prevSceneObject.scaleY = 1 - _value;
          },
        }),
        restore,
      }
    }
    case "ERASE_LEFT_OUT": {
      canvas.add(nextSceneObject);
      canvas.add(prevSceneObject);

      canvas.bringToFront(nextSceneObject);
      canvas.bringToFront(prevSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.opacity = 1 - _value;
            prevSceneObject.left = -1 * _value * canvasWidth;
          },
        }),
        restore,
      }
    }
    case "ERASE_RIGHT_OUT": {
      canvas.add(nextSceneObject);
      canvas.add(prevSceneObject);

      canvas.bringToFront(nextSceneObject);
      canvas.bringToFront(prevSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.opacity = 1 - _value;
            prevSceneObject.left = _value * canvasWidth;
          },
        }),
        restore,
      }
    }
    case "ERASE_UP_OUT": {
      canvas.add(nextSceneObject);
      canvas.add(prevSceneObject);

      canvas.bringToFront(nextSceneObject);
      canvas.bringToFront(prevSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onChange: (_value: number) => {
            prevSceneObject.opacity = 1 - _value;
            prevSceneObject.top = -1 * _value * canvasHeight;
          },
        }),
        restore,
      }
    }
    case "ERASE_DOWN_OUT": {
      canvas.add(nextSceneObject);
      canvas.add(prevSceneObject);

      canvas.bringToFront(nextSceneObject);
      canvas.bringToFront(prevSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.opacity = 1 - _value;
            prevSceneObject.top = _value * canvasHeight;
          },
        }),
        restore,
      }
    }
    case "PUSH_LEFT_OUT_IN": {
      canvas.add(prevSceneObject);
      canvas.add(nextSceneObject);
      
      nextSceneObject.left = canvasWidth;

      canvas.bringToFront(prevSceneObject);
      canvas.bringToFront(nextSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.left = -1 * _value * canvasWidth;
            nextSceneObject.left = (1 - _value) * canvasWidth;
          },
        }),
        restore,
      }
    }
    case "PUSH_RIGHT_OUT_IN": {
      canvas.add(prevSceneObject);
      canvas.add(nextSceneObject);

      nextSceneObject.left = -1 * canvasWidth;

      canvas.bringToFront(prevSceneObject);
      canvas.bringToFront(nextSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.left = _value * canvasWidth;
            nextSceneObject.left = (_value - 1) * canvasWidth;
          },
        }),
        restore,
      }
    }
    case "PUSH_UPDOWN_OUT_IN": {
      canvas.add(prevSceneObject);
      canvas.add(nextSceneObject);

      nextSceneObject.top = canvasHeight;

      canvas.bringToFront(prevSceneObject);
      canvas.bringToFront(nextSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.top = -1 * _value * canvasHeight;
            prevSceneObject.top = (1 - _value) * canvasHeight;
          },
        }),
        restore,
      }
    }
    case "PUSH_DOWNUP_OUT_IN": {
      canvas.add(prevSceneObject);
      canvas.add(nextSceneObject);

      nextSceneObject.top = -1 * canvasHeight;

      canvas.bringToFront(prevSceneObject);
      canvas.bringToFront(nextSceneObject);

      return {
        cancel: animate({
          startValue,
          endValue,
          duration,
          easing,
          onComplete,
          onChange: (_value: number) => {
            prevSceneObject.top = _value * canvasHeight;
            nextSceneObject.top = (_value - 1) * canvasHeight;
          },
        }),
        restore,
      }
    }
    default: {
      return {
        cancel: () => {},
        restore: () => {},
      };
    }
  }
}

export function setClipSize({ canvas, clips, align, mode }: {
  canvas: Fabric.Canvas;
  clips: Clip[];
  align: [("left" | "center" | "right"), ("top" | "center" | "bottom")];
  mode: "fit" | "fill" | "native";
}) {
  const canvasWidth = canvas.getWidth() / canvas.getZoom();
  const canvasHeight = canvas.getHeight() / canvas.getZoom();
  const canvasPoint = (() => {
    const pointX = (() => {
      if (align[0] === "left") {
        return 0;
      }
      if (align[0] === "center") {
        return canvasWidth / 2;
      }
      if (align[0] === "right") {
        return canvasWidth;
      }
      return 0;
    })();
    const pointY = (() => {
      if (align[1] === "top") {
        return 0;
      }
      if (align[1] === "center") {
        return canvasHeight / 2;
      }
      if (align[1] === "bottom") {
        return canvasHeight;
      }
      return 0;
    })();
    return new fabric.Point(pointX, pointY);
  })();

  canvas.getObjects()
    .forEach((obj) => {
      const targetObj = obj as Fabric.Image;
      const targetClip = clips.find((clip) => clip.id === targetObj.name);
      const targetWidth = targetObj.width;
      const targetHeight = targetObj.height;

      if (targetClip && targetWidth && targetHeight) {
        const scale = (() => {
          const scaleX = canvasWidth / targetWidth;
          const scaleY = canvasHeight / targetHeight;

          if (mode === "fit") {
            if (scaleX > scaleY) {
              return scaleY;
            }
            return scaleX;
          }
          if (mode === "fill") {
            if (scaleX > scaleY) {
              return scaleX;
            }
            return scaleY;
          }
          return 1;
        })();

        targetObj.set("scaleX", scale);
        targetObj.set("scaleY", scale);
        targetObj.setPositionByOrigin(canvasPoint, align[0], align[1]);
      }
    });

  canvas.renderAll();

  type ResultType = {
    [key: string]: {
      top?: number; left?: number; scaleX?: number; scaleY?: number;
    }
  }
  const result = canvas.getObjects().reduce((acc: ResultType, obj) => {
    if (clips.find((clip) => clip.id === obj.name) && obj.name) {
      return {
        ...acc,
        [obj.name]: {
          top: obj.top,
          left: obj.left,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY,
        }
      }
    }
    return acc;
  }, {});

  return result;
}

export async function resizeImage<T extends (File | string | Blob)>(params: {
  src: string;
  exportType?: "file" | "base64" | "blob";
  size: number;
  mode: "min" | "max";
}): Promise<{
  resized: boolean;
  result: T | null;
}> {
  const getTargetSize = (
    currentWidth: number,
    currentHeight: number,
    targetSize: number,
    mode: "min" | "max",
  ) => {
    const currentRatio = currentWidth / currentHeight;
  
    if (mode === "min") {
      if (targetSize > currentWidth || targetSize > currentHeight) {
        if (currentRatio > 1) {
          return {
            width: targetSize * currentRatio,
            height: targetSize,
          };
        }
        return {
          width: targetSize,
          height: targetSize / currentRatio,
        };
      }
    }
    if (mode === "max") {
      if (targetSize < currentWidth || targetSize < currentHeight) {
        if (currentRatio > 1) {
          return {
            width: targetSize,
            height: targetSize / currentRatio,
          };
        }
        return {
          width: targetSize * currentRatio,
          height: targetSize,
        };
      }
    }
    return {
      width: currentWidth,
      height: currentHeight,
    };
  };

  try {
    const { src, exportType, size, mode } = params;

    const fileName = new URL(src).pathname.split("/").pop();

    if (!fileName) {
      throw new Error("Invaild src.");
    }

    const canvas = createFabricCanvas(null, {}, true);
    canvas.name = "resizeImageCanvas";

    const { currentWidth, currentHeight, width, height } = await new Promise<{
      currentWidth: number | null;
      currentHeight: number | null;
      width: number | null;
      height: number | null;
    }>((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", (e: Event) => {
        const el = e.currentTarget as HTMLImageElement;
        resolve({
          currentWidth: el.width,
          currentHeight: el.height,
          ...getTargetSize(el.width, el.height, size, mode),
        });
      });
      image.addEventListener("error", () => {
        reject({
          currentWidth: null,
          currentHeight: null,
          width: null,
          height: null
        });
      });
      image.src = src;
    });

    if (
      currentWidth === null || currentHeight === null
      || width === null || height === null
    ) {
      throw new Error("Cannot found image width or height.");
    }

    canvas.setDimensions({ width, height });

    const scale = (width / currentWidth);
    const resized = scale !== 1;

    const imageObject = await new Promise<Fabric.Image | null>((resolve, reject) => {
      try {
        const object = new fabric.Image("");
        object.setSrc(src, (_image: Fabric.Image) => {
          _image.set("left", 0);
          _image.set("top", 0);

          if (resized) {
            _image.scaleToWidth(width);
          }
          resolve(_image);
        });
      } catch (e) {
        reject(null);
      }
    });

    if (imageObject === null) {
      throw new Error("Cannot create image object.");
    }

    canvas.add(imageObject);
    canvas.renderAll();

    const base64 = canvas.toDataURL({ format: "png" });
    disposeCanvas(canvas);

    if (exportType === "base64") {
      return<{
        resized: boolean;
        result: T;
      }> {
        resized,
        result: base64,
      };
    }

    const [contentType, decodeData] = base64
      .split(",")
      .map((splits: string, index: number) => {
        if (index === 0) {
          return splits.split(":")[1].split(";")[0];
        }
        if (index === 1) {
          return atob(splits);
        }
      }) as [string, string];
    
    const arraybuffer = new ArrayBuffer(decodeData.length);
    const view = new Uint8Array(arraybuffer);
    
    for (let i = 0; i < decodeData.length; i++) {
      view[i] = decodeData.charCodeAt(i) & 0xff;
    }
    
    const blob = new Blob([arraybuffer], { type: contentType });

    if (exportType === "blob") {
      return<{
        resized: boolean;
        result: T;
      }> {
        resized,
        result: blob,
      };
    }

    const file = new File([blob], fileName, {
      type: "image/png",
    });

    return<{
      resized: boolean;
      result: T;
    }> {
      resized,
      result: file,
    };
  } catch(e) {
    console.error("resizeImage: ", e);
    return {
      resized: false,
      result: null,
    }
  }
}

/**
 * 캔버스에서 이펙트 클립 오브젝트를 전부 찾아 반환
 *
 * canvas 파라미터를 넘기지 않으면 메인 캔버스에서 찾음
 */
export const findAllEffectClipObjects = <
  T extends fabric.Object = EffectFabricObjectClass,
>(
  canvas?: fabricModule.Canvas | fabricModule.StaticCanvas,
) => {
  return (canvas || canvasHolder.canvas)
    ?.getObjects()
    .filter((clip) => clip.type === 'effect') as T[] | undefined
}

/**
 * 캔버스에서 아이디를 이용해 이펙트 클립 오브젝트를 찾아 반환
 *
 * canvas 파라미터를 넘기지 않으면 메인 캔버스에서 찾음
 */
export const findEffectClipObjectById = <
  T extends fabric.Object = EffectFabricObjectClass,
>(
  clipId: string,
  canvas?: fabricModule.Canvas,
) => {
  return findAllEffectClipObjects<T>(canvas)?.find(
    (clip) => clip.name === clipId,
  )
}


export async function createWebLinkOgImage(params: {
  image: string;
  width: number;
  height: number;
  color?: string | string[];
  offset?: number; // 0 - 1
  rotate?: number; // -360 - 360
  shadow?: boolean;
}) {
  const { image, width, height, color, offset, rotate, shadow } = params;

  try {
    if (!image || !width || !height) {
      throw new Error("setLetterBoxImage: A required parameter is missing.")
    }

    const canvas = createFabricCanvas(null, {}, true);
    canvas.name = "setLetterBoxImageCanvas";

    canvas.setDimensions({ width, height });

    const imageObject = await new Promise<Fabric.Image | null>((resolve, reject) => {
      try {
        const object = new fabric.Image("");
        object.setSrc(image, (_image: Fabric.Image) => {
          const imageRatio = (_image.width || 0) / (_image.height || 0);
          const canvasRatio = width / height;
          const canvasPoint = new fabric.Point(width / 2, height / 2);

          if (Number.isNaN(imageRatio)) {
            throw new Error();
          }

          if (imageRatio > canvasRatio) {
            _image.scaleToWidth(width);
          } else {
            _image.scaleToHeight(height);
          }

          if (offset) {
            if (imageRatio > canvasRatio) {
              _image.scaleToWidth(width * (1 - offset));
            } else {
              _image.scaleToHeight(height * (1 - offset));
            }
          }

          if (rotate) {
            if (rotate < 0) {
              _image.rotate(360 + rotate);
            } else {
              _image.rotate(rotate);
            }
          }
          
          _image.setPositionByOrigin(canvasPoint, "center", "center");

          if (shadow) {
            const shadow = new fabric.Shadow({
              color: "rgba(0, 0, 0, 0.6)",
              blur: 10,
              offsetX: 0,
              offsetY: 10,
            });
            _image.set("shadow", shadow);
          }

          resolve(_image);
        });
      } catch (e) {
        reject(null);
      }
    });

    if (imageObject === null) {
      throw new Error("setLetterBoxImage: Cannot create image object.");
    }

    if (color) {
      if (Array.isArray(color)) {
        const backgroundRect = new fabric.Rect({
          left: 0,
          top: 0,
          width,
          height,
        });
        const backgroundGradient = new fabric.Gradient({
          type: "linear",
          gradientUnits: "percentage",
          coords: {
            x1: 0,
            y1: 0,
            x2: 1,
            y2: 1,
          },
          colorStops: color.map((value, index, array) => {
            return { offset: 1 / (array.length - 1) * index, color: value };
          })
        });
        backgroundRect.set("fill", backgroundGradient);
        canvas.add(backgroundRect);
      } else {
        canvas.backgroundColor = color;
      }
    }

    canvas.add(imageObject);
    canvas.renderAll();

    const base64 = canvas.toDataURL();
    disposeCanvas(canvas);

    return base64;
  } catch (e) {
    console.error(e);
    return "";
  }
}