import { createSlice } from "@reduxjs/toolkit";

export type AdminMenuItem = {
  key: string;
  isSelected: boolean;
  title: string;
  url: string;
  sub?: Array<AdminMenuItem>;
};

const defAdminMenuList: Array<AdminMenuItem> = [
  {
    key: "user",
    title: "Users",
    isSelected: true,
    url: "/admin/account-information",
  },
  {
    key: "project",
    title: "Projects",
    isSelected: false,
    url: "/admin/projects",
  },
  {
    key: "template",
    title: "Templates",
    isSelected: false,
    url: "/admin/templates",
  },
  {
    key: "export",
    title: "Export History",
    isSelected: false,
    url: "/admin/exports",
  },
  {
    key: "analysis",
    title: "Analysis",
    isSelected: false,
    url: "/admin/analysis/period",
    sub: [
      {
        key: "synth_period",
        title: "Synthesis by Period",
        isSelected: false,
        url: "/admin/analysis/period",
      },
      {
        key: "synth_model",
        title: "Synthesis by AI Model",
        isSelected: false,
        url: "/admin/analysis/model",
      },
    ],
  },
  {
    key: "nps",
    title: "NPS",
    isSelected: false,
    url: "/admin/nps",
  },
  {
    key: "payment",
    title: "PaymentList",
    isSelected: false,
    url: "/admin/payments",
    // sub: false,
    // top: true,
  },
  {
    key: "cube",
    title: "Cube History",
    isSelected: false,
    url: "/admin/points",
    // sub: false,
    // top: true,
  },
  {
    key: "credit",
    title: "Credit History",
    isSelected: false,
    url: "/admin/credit",
    // sub: false,
    // top: true,
  },
  {
    key: "creative",
    title: "Creative Templates",
    isSelected: false,
    url: "/admin/creativeTemplates",
    // sub: false,
    // top: true,
  },
  {
    key: "statistics",
    title: "Statistics",
    isSelected: false,
    url: "/admin/statistics",
    // sub: false,
    // top: true,
  },
];

const adminMenuSlice = createSlice({
  name: "adminmenu",
  initialState: defAdminMenuList,
  reducers: {
    selectAdminMenu: (state, action) => {
      state.forEach((row, index) => {
        row.isSelected = index == action.payload;
      });
    },
  },
});

export const { selectAdminMenu } = adminMenuSlice.actions;
export default adminMenuSlice.reducer;
