export const AUTOMATION_CATEGORY_TITLE = {
  FOR_YOU: 'For you',
  AVATARS: 'AI Avatars',
  VOICES: 'AI Voices',
  GENERATORS: 'AI Generators',
  SNS: 'Social Media',
  MARKETING: 'Marketing',
  TOOLS: 'Tools'
} as const

export type AutomationCategoryTitle = (typeof AUTOMATION_CATEGORY_TITLE)[keyof typeof AUTOMATION_CATEGORY_TITLE];


export const QUICK_START_TEMPLATE_TITLE = {
  CUSTOM_AVATAR: "Custom Avatar",
  VOICE_CLONING: "Voice Cloning",
  SCRIPT_GENERATOR: "Script Generator",
  VIDEO_HIGHLIGHTS: "Video Highlights",
  URL_TO_VIDEO: "URL to Video",
} as const

export type QuickStartTemplateTitle = (typeof QUICK_START_TEMPLATE_TITLE)[keyof typeof QUICK_START_TEMPLATE_TITLE]