/* eslint-disable @typescript-eslint/naming-convention */

/**
 * 불필요한 커넥션을 막기위해서 캐시한4ㄷ
 */

import _memoize from "lodash.memoize";
import moment from "moment";

/**
 * memoize + timeout
 * @param {*} func
 * @param {*} param1
 * @returns
 */
export default function memoize(func, { tm = 3, unit = "m" } = {}) {
  const memo_fn = _memoize<any>(func, (...args) => JSON.stringify(args));

  const result_fn = (...params: any[]) => {
    if (!memo_fn.update) {
      memo_fn.update = moment();
    }
    if (moment().diff(memo_fn.update, unit) >= tm) {
      delete memo_fn.cache;
      memo_fn.cache = new _memoize.Cache();
      memo_fn.update = null;
    }

    try {
      let result = memo_fn(...params);
      if (typeof result === "object" && typeof result.catch === "function") {
        result = result.catch((error: Error) => {
          console.log("----");
          delete memo_fn.cache;
          memo_fn.cache = new _memoize.Cache();
          memo_fn.update = null;
          throw error;
        });
      }

      return result;
    } catch (e) {
      delete memo_fn.cache;
      memo_fn.cache = new _memoize.Cache();
      memo_fn.update = null;
      throw e;
    }
  };

  result_fn.memo_fn = memo_fn;

  result_fn.reload = () => {
    delete memo_fn.cache;
    memo_fn.cache = new _memoize.Cache();
    memo_fn.update = null;
  };

  return result_fn;
}
