import {
  createApi,
  fetchBaseQuery,
  defaultSerializeQueryArgs,
} from "@reduxjs/toolkit/query/react";
import { runtimeEnv } from "@/lib/runtime-utils";
import { HYDRATE } from "next-redux-wrapper";
import type { RestApiResponse } from "@/types/common.api";
import type {
  GetAvatarsReq,
  GetAvatarsRes,
  TemplatePostData,
  AccountUserInfoType,
  FolderInfoList,
} from "@/types/dashboard.api";
import type { FetchArgs } from "@reduxjs/toolkit/query/react";
import type { QueryArgs } from "@/types/common.api";
import { DashboardQuickTemplate } from "@/lib/dashboard/db/dashboard-quick-template";
import { DashboardMyProject } from "@/lib/dashboard/db/dashboard-my-project";
import type { DashboardQuickTemplateType } from "@/constants/dashboard/template";
import { CategoryVideoTemplate } from "@/lib/dashboard/db/dashboard-category-template";
import { BaseInfoIdFilterKey, MoveProjectType, ProjectFilterKey } from "@/constants/dashboard/projects";
import { uniqBy } from "lodash";
import { ObjectId } from "mongodb";

export type GetTemplateResItem = {
  _id: string;
  name: string;
  type: "custom" | "user";
  category: string;
  projectId: string;
  screenType: "web" | "mobile";
  display_index: number;
  createdAt: string;
  thumbnailUrl: string;
  download_url: string;
};
export type GetTemplateResCategory = {
  value: string;
  label: string;
};
type GetTemplateRes = {
  templates: GetTemplateResItem[];
  categoryList: GetTemplateResCategory[];
  chatgptTemplates: (GetTemplateResItem & { category: "chatgpt" })[];
};


export type FolderThumbnail = {
  [key: string]: string[]
}

export type GetDashboardMyProjectRes = {
  projectResults: DashboardMyProject[];
  totalCount: number;
  folderIds: string[];
}

export type GetDashboardTemplateRes = {
  templateResults: CategoryVideoTemplate[];
  totalCount: number;
}

export type GetDashboardTemplateQuery = {
  page: number,
  limit?: number,
  type: DashboardQuickTemplateType,
  language: string,
  search?: string,
  category?: string,
  companyId?: string,
  orientation?: string,
};

export type GetDashboardExportingMyProjectRes = {
  exportingList: DashboardMyProject[];
  exportedList: DashboardMyProject[];
}

export type GetDashboardObserverMyProjectRes = {
  observeIntersectionList: DashboardMyProject[];
}

export type GetFolderProjectsRes = {
  folderProjects: DashboardMyProject[];
  totalCount: number;
}

export type GetFolderInfoListRes = {
  folderInfoList: FolderInfoList[];
}

export type GetProjectHistoryRes = {
  projectList: DashboardMyProject[];
  totalCount: number;
}

export type GetProjectEditInfoRes = {
  success: boolean;
}

export type GetFolderThumbnailsRes = {
  folderThumbnails: FolderThumbnail;
}

export type GetDashboardProjectJobIdsRes = {
  jobIds: string[];
}

export type GetDashboardTotalProjectRes = {
  projectResults: DashboardMyProject[];
  totalCount: number;
  folderIds: string[];
  folderThumbnailResults: FolderThumbnail;
}

export type DeleteProjectIds = {
  isFolder: boolean;
  projectId: string;
}

export type GetSelectedWorkspaceIdRes = {
  selectedWorkspaceId: ObjectId | string;
}

export const dashboardApi = createApi({
  reducerPath: "dashboard_data",
  refetchOnFocus: false,
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/dashboard`,
  }),
  tagTypes: ['folder', 'myProject', 'exportHistory', 'videoSet', 'totalProjects', 'dashboardProject', 'tts', 'folderInfo', 'selectedWorkspaceId'],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (build) => ({
    getStartTemplate: build.query<RestApiResponse<any>, {isMobile: boolean}> ({
      query: ({isMobile = false}) => {
        return `/startTemplates?isMobile=${isMobile}`
      },
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getTemplate: build.query<GetTemplateRes, { category: string | undefined }> ({
      query: ({ category }) => {
        const queryString = category ? `?category=${category}` : '';
        return `/templates${queryString}`;
      },
      transformResponse: async (res: RestApiResponse<GetTemplateRes>) => {
        if (!res.success || !res.data) {
          return {
            templates: [],
            categoryList: [],
            chatgptTemplates: [],
          };
        }
        return res.data;
      },
    }),    
    deleteTemplate: build.mutation<RestApiResponse<any>, {templateId: string}>({
      query: ({ templateId }) => ({
        url: `/templates-delete`,
        method: 'POST',
        body: { templateId }
      }),
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getTemplatePost: build.mutation<RestApiResponse<any>, {perPage: number, currentPage: number, category: string, automation?: boolean, screenType?: string, orientation?: string, language?: string, aiType?: string}>({
      query: ({perPage, currentPage, category, automation, screenType, orientation, language, aiType}) => ({
        url:  `/templates`,
        method: 'POST',
        body: {perPage, currentPage, category, automation, screenType, orientation, language, aiType}
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return {
            success: false,
            error: res.error,
          };
        }
        return {
          success: true,
          data: res.data,
        };
      },
    }),
    getTemplateProjectPost: build.mutation<RestApiResponse<{ template: TemplatePostData }>, { id: string }>({
      query: ({ id }) => ({
        url: `/templateProject`,
        method: 'POST',
        body: { id }
      }),
    }),
    getTemplateParentProjectPost: build.mutation<RestApiResponse<any>, { id: string }>({
      query: ({ id }) => ({
        url: `/templateProject`,
        method: 'POST',
        body: { id }
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return {
            success: false,
            error: res.error,
          };
        }
        return {
          success: true,
          data: res.data,
        };
      },
    }),
    getMyProject: build.query<RestApiResponse<any>, { page: number, searchVal?: string, workspaceId: string, isPrivate?: string, recentProject?: string }>({
      // query: ({page}) => `/project?page=${page}`,
      query: ({ page, searchVal, workspaceId, isPrivate, recentProject }) => ({
        url: `/project?page=${page}&search=${searchVal}&workspaceId=${workspaceId}&isPrivate=${isPrivate}&recentProject=${recentProject}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{type: 'myProject'}],
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getProjectInfo: build.query<any, {projectId: string | string[] | undefined}> ({
      query: ({projectId}) =>  `/projectInfo?projectId=${projectId}`,
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getFolder: build.query<RestApiResponse<any>, void> ({
      query: () => ({url: `/folder/folder`}),
      providesTags: (result, error, arg) => {
        return [{type: 'folder'}]
      }
    }),
    folderCreateAndUpdate: build.mutation<any, {folderName: string, modalType: string, folderDetail?: any, workspaceId?: string, isPremiumUser?: boolean, userWorkspaceId?: string}> ({
      query: ({folderName, modalType, folderDetail, workspaceId, isPremiumUser, userWorkspaceId}) => ({
        url: `/folder/folder`,
        method: 'POST',
        body: {folderName, modalType, folderDetail, workspaceId, isPremiumUser, userWorkspaceId}
      }),
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
      invalidatesTags: (result, error, arg) => {
        // result ? [{type: 'folder'}] : []
        return [{type: 'folder'}, { type: 'totalProjects'}, {type: 'folderInfo'}]
      }
    }),
    moveProjectToFolder: build.mutation<any, {selectedFolder: string, selectedProjectId: string, isTop?: boolean} >({
      query: ({selectedFolder, selectedProjectId, isTop = false}) => ({
        url: '/folder/moveFolders',
        method: 'POST',
        body: {selectedFolder, selectedProjectId, isTop}
      }),
    invalidatesTags: (result, error, arg) => result ? [{type: 'folder'}, {type: 'myProject'}, {type: 'dashboardProject'}, { type: 'totalProjects' }, {type: 'folderInfo'}] : []
    }),
    getFolderInfo: build.mutation<any, {folderId: string}>({
      query: ({folderId}) => ({
        url: '/folder/getFolderInfo',
        method: 'POST',
        body: {folderId}
      }),
      invalidatesTags: (result, error, arg) => result ? [{type: 'folder'}] : []
    }),
    duplicateProject: build.mutation<any, {projectId: string, workspaceId?: string, baseInfoId?: string}> ({
      query: ({ projectId, workspaceId, baseInfoId }) => ({
        url: '/duplicate-project',
        method: 'POST',
        body: {projectId, workspaceId, baseInfoId}
      }),
      invalidatesTags: (result, error, arg) => result ? [{type: 'myProject'}, {type: 'folder'}, {type: 'dashboardProject'}, {type: 'folderInfo'}] : []
    }),
    deleteProject: build.mutation<any, { projectId: string }> ({
      query: ({ projectId }) => ({
        url: '/delete-project',
        method: 'POST',
        body: { projectId }
      }),
      invalidatesTags: (result) => result ? [{type: 'myProject'}, {type: 'folder'}, {type: 'dashboardProject'}]: []
    }),
    exportHistory: build.query<any, {currentPage:number, perPage: number, workspaceId: string, searchText: string}> ({
      query: ({currentPage, perPage, workspaceId, searchText}) => ({
        url: `/exportHistory?currentPage=${currentPage}&perPage=${perPage}&workspaceId=${workspaceId}&searchText=${searchText}`
      }),
      providesTags: () => [{ type : "exportHistory" }],
    }),
    editWebLinkTargetUrl: build.mutation<RestApiResponse<void>, { projectId: string; path?: string | null; }> ({
      query: (body) => ({
        url: "/webLinkTargetUrl",
        method: "POST",
        body,
      }),
      invalidatesTags: () => [{ type : "exportHistory" }],
    }),
    videoSet: build.query<any, {currentPage:number, perPage: number, id: string, searchText: string}> ({
      query: ({currentPage, perPage, id, searchText}) => ({
        url: `/videoSet?currentPage=${currentPage}&perPage=${perPage}&id=${id}&searchText=${searchText}`
      }),
      providesTags: () => [{ type : "videoSet" }],
    }),
    getAvatars: build.query<GetAvatarsRes, (QueryArgs & GetAvatarsReq | void)>({
      query: (queryArgs?: QueryArgs & GetAvatarsReq) => {
        const query: FetchArgs = {
          url: "/avatars",
          method: "GET",
        };

        if (!queryArgs) {
          return query;
        }

        const { headers, ...args } = queryArgs;

        if (headers) {
          query.headers = headers;
        }
        if (Object.keys(args).length > 0) {
          query.url = `${query.url}?${new URLSearchParams(args).toString()}`
        }
 
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition
        })
      },
      transformResponse: async(res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    uploadAutomation: build.mutation<RestApiResponse<any>, File[]>({
      query(files) {
        const formData = new FormData();
        files.map((row, index) => {
          formData.append(`file_${index}`, row);
        })
        return {
          url: '/uploads-automation',
          method: "POST",
          body: formData,
          formData: true,
        }
      }
    }),
    uploadPublicAutomation: build.mutation<RestApiResponse<any>, File[]>({
      query(files) {
        const formData = new FormData();
        files.map((row, index) => {
          formData.append(`file_${index}`, row);
        })
        return {
          url: '/uploads-public-automation',
          method: "POST",
          body: formData,
          formData: true,
        }
      }
    }),

    makePPTProject: build.mutation<
      {
        projectId?: string
        success?: boolean
        error?: any
        pptError?: any
      },
      {
        type: 'ppt' | 'pdf'
        workspaceId?: string
        images?: string[]
        pptFile?: File
      }
    >({
      query: ({ type, workspaceId, images, pptFile }) => {
        const formData = new FormData()

        formData.append('type', type)

        if (!!workspaceId) {
          formData.append('workspaceId', workspaceId)
        }

        if (!!images) {
          for (const image of Array.isArray(images) ? images : [images]) {
            formData.append('images', image)
          }
        }

        if (!!pptFile) {
          formData.append('pptFile', pptFile)
        }

        return {
          url: '/makePPTProject',
          method: 'POST',
          body: formData,
          timeout: 5 * 60 * 1000,
        }
      },
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return []
        }
        return res.data
      },
    }),
    
    setCreateChatGPTLog: build.mutation<any, {log: chatGPTLog}> ({
      query: ({log}) => ({
        url: '/chatGPTLog',
        method: 'POST',
        body: { log }
      }),
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    exportTemplate: build.mutation<RestApiResponse<void>, { templateId: string; thumbnail?: string }>({
      query: (data) => {
        return {
          url: `/exportTemplate`,
          method: "POST",
          body: data,
        };
      },
    }),
    deployTemplate: build.mutation({
      query: (data) => {
        return {
          url: `/deployTemplate`,
          method: "POST",
          body: data,
        };
      },
    }),
    getUserInfo: build.mutation({
      query: () => {
        return {
          url: `/userInfo`,
          method: 'POST',
        }
      }
    }),
    getAccountUserInfo: build.query<AccountUserInfoType | null, QueryArgs | void>({
      query: (args?: QueryArgs) => {
        const query: FetchArgs = {
          url: "/userInfo",
          method: "GET",
        };
        if (args?.headers) {
          query.headers = args.headers;
        }
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition
        })
      },
      transformResponse: async(res: RestApiResponse<AccountUserInfoType | null>) => {
        if (!res.success || !res.data) {
          return null;
        }
        return res.data;
      },
    }),
    createBaseInfo: build.mutation<any, void>({
      query: () => ({
        url: "/create-baseInfo",
        method: "POST",
        body: {}
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getDashboardQuickTemplate: build.query<DashboardQuickTemplate[], {isMobile: boolean}>({
      query: ({ isMobile = false}) => `/quick-template?isMobile=${isMobile}`,
      transformResponse: async (res: RestApiResponse<DashboardQuickTemplate[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    fetchDashboardProject: build.query({
      query: ({
        page,
        workspaceId,
        isPrivate,
      }: {
        page: Number,
        workspaceId: string,
        isPrivate?: string,
      }) => ({
        url: `/myProject?page=${page}&workspaceId=${workspaceId}&isPrivate=${isPrivate}`,
        method: 'GET',
      }),
      transformResponse: (res: RestApiResponse<GetDashboardMyProjectRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get ProjectList`);
        } 
        return res.data;
      },
      providesTags: [{ type : "dashboardProject" }],
      serializeQueryArgs: ({ queryArgs }) => {
        const { workspaceId } = queryArgs;
        return workspaceId;
      }, 
      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName;
      // },
      /**
       * Cached Item 있을때 호출 됨
       * -> 제일 처음 rtk-query 호출할때는 실행되지 않음
       */
      merge: (currentCache, newItems, { arg }) => {
        const { page } = arg;
        const totalCount = newItems.totalCount;
        const folderIds = uniqBy([...newItems.folderIds, ...currentCache.folderIds], (folderId) => folderId.toString())
        const result = page === 0 
        ? [...newItems.projectResults] 
        : [...currentCache.projectResults, ...newItems.projectResults];
        const uniqueProjectResults = uniqBy(result, (projectResults) => projectResults._id.toString());

        return {
          totalCount,
          folderIds,
          projectResults: uniqueProjectResults,
        }

      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return previousArg === undefined || currentArg !== previousArg;
      }
    }),
    fetchTotalProjects: build.query({
      query: ({
        page,
        workspaceId,
        isPrivate,
        recentProject,
        search,
        projectType,
      }: {
        page: Number,
        workspaceId: string,
        isPrivate: string,
        recentProject: string,
        search?: string,
        projectType?: ProjectFilterKey | 'Project Type',
      }) => ({
        url: `/myProject/total`,
        params: {
          page,
          workspaceId,
          isPrivate,
          recentProject,
          search,
          projectType,
        }
      }),
      transformResponse: (res: RestApiResponse<GetDashboardTotalProjectRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get ProjectList`);
        } 
        return res.data;
      },
      providesTags: (result, error, arg) => {
        return [{type: 'totalProjects'}]
      },
    }),
    fetchFolderProjects: build.query({
      query: ({ page, perPage = 10, search = '', folderId, baseInfoIdFilterValue }: {
        page: number,
        perPage?: number,
        search?: string,
        folderId: string,
        baseInfoIdFilterValue: BaseInfoIdFilterKey
      }) => ({
        url: '/folder/projects',
        params: {
          page,
          perPage,
          search: search || undefined,
          folderId,
          baseInfoIdFilterValue,
        },
      }),
      providesTags: () => [{ type : "exportHistory" }],
      transformResponse: (res: RestApiResponse<GetFolderProjectsRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get folderProjects`);
        } 
        return res.data;
      },
    }),
    fetchFolderInfoList: build.query({
      query: ({
        workspaceId,
      }: {
        workspaceId: string,
      }) => `/folder/list?workspaceId=${workspaceId}`,
      transformResponse: (res: RestApiResponse<GetFolderInfoListRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get folderProjects`);
        } 
        return res.data;
      },
      providesTags: (result, error, arg) => {
        return [{type: 'totalProjects'}]
      }
    }),
    fetchDashboardTemplate: build.query({
      query: (params: GetDashboardTemplateQuery) => {
        const baseUrl = "/template";
        const queryString = Object.entries(params)
          .filter(([_, value]) => value !== undefined && value !== null)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
          )
          .join("&");

        return ({
          url: `${baseUrl}?${queryString}`,
          method: 'GET',
          timeout: 10000, // 10초 타임아웃
        })
      },
      transformResponse: (res: RestApiResponse<GetDashboardTemplateRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get ProjectList`);
        } 
        return res.data;
      },
    }),
    fetchDashboardTemplateWithId: build.query({
      query: ({ id }: { id: string }) => {
        return ({
          url: `/template/${id}`,
          method: 'GET',
          timeout: 10000, // 10초 타임아웃
        })
      },
      transformResponse: (res: RestApiResponse<{ templateResult: CategoryVideoTemplate }>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get ProjectList`);
        } 
        return res.data;
      },
    }),
    fetchProjectHistory: build.query({
      query: ({
        currentPage, 
        baseInfoId,
        perPage,
      }: {
        currentPage: Number,
        baseInfoId: string,
        perPage: Number,
      }) => {
        return({
          url: `/history/project?currentPage=${currentPage}&baseInfoId=${baseInfoId}&perPage=${perPage}`,
          method: 'GET',
          timeout: 10000,
        })
      },
      transformResponse: (res: RestApiResponse<GetProjectHistoryRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get ProjectList`);
        } 
        return res.data;
      },
    }),
    fetchProjectEditInfo: build.query({
      query: ({
        projectId,
        isFinal,
      }: {
        projectId: string,
        isFinal: string,
      }) => `/history/editInfo?projectId=${projectId}&isFinal=${isFinal}`,
      transformResponse: (res: RestApiResponse<GetProjectEditInfoRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get ProjectList`);
        } 
        return res.data;
      },
    }),
    getMyTemplate: build.query<GetTemplateRes, { category?: string; workspaceId: string }>({
      query: ({ category, workspaceId }) => {
        const queryString = category
          ? `?category=${category}&workspaceId=${workspaceId}`
          : `?workspaceId=${workspaceId}`;
        return `/userTemplates${queryString}`;
      },
      transformResponse: async (res: RestApiResponse<GetTemplateRes>) => {
        if (!res.success || !res.data) {
          return {
            templates: [],
            categoryList: [],
            chatgptTemplates: [],
          };
        }
        return res.data;
      },
    }),    
    getFolderThumbnails: build.query({
      query: ({
        folderIds,
      }: {
        folderIds: string[],
      }) => `/myProject/folderThumbnail?folderIds=${folderIds.join(',')}`,
      transformResponse: (res: RestApiResponse<GetFolderThumbnailsRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get folderThumbnails`);
        }
        return res.data
      }
    }),
    getDashboardProjectJobIds: build.query({
      query: ({
        workspaceId,
        isPrivate,
      }: {
        workspaceId: string,
        isPrivate: string,
      }) => `/myProject/jobIds?workspaceId=${workspaceId}&isPrivate=${isPrivate}`,
      transformResponse: (res: RestApiResponse<GetDashboardProjectJobIdsRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get folderThumbnails`);
        }
        return res.data
      }
    }),

    updateTemplate: build.mutation<RestApiResponse<any>, {templateId: string; tags?: string[]}>({
      query: (body) => ({
        url: `/templates`,
        method: 'PUT',
        body
      }),
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getWorkspaceAndFolderList: build.query({
      query: ({
        workspaceId,
      }: {
        workspaceId: string,
      }) => `/workspaceAndFolderList?workspaceId=${workspaceId}`,
      transformResponse: (res: RestApiResponse<GetFolderInfoListRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get folderThumbnails`);
        }
        return res.data
      },
      providesTags: [{type: 'folderInfo'}],
    }),
    moveProject: build.mutation({
      query:({
        moveFrom,
        moveWorkspaceId,
        moveFolderId,
        projectIds,
        projectBaseInfoIds,
        folderIds,
        isPremiumUser,
      }: {
        moveFrom: MoveProjectType,
        moveWorkspaceId?: string | null,
        moveFolderId?: string | null,
        projectIds: string[],
        projectBaseInfoIds?: string[],
        folderIds?: string[],
        isPremiumUser: boolean,
      }) => ({
        url: `/moveProject`,
        method: 'POST',
        body: { moveFrom, moveWorkspaceId, moveFolderId, projectIds, projectBaseInfoIds, folderIds, isPremiumUser },
      }),
    }),
    deleteProjects: build.mutation({
      query: ({
        deleteProjectIds,
        isPremiumUser,
        userWorkspaceId,
      }: {
        deleteProjectIds: DeleteProjectIds[],
        isPremiumUser: boolean,
        userWorkspaceId: string,
      }) => ({
        url: `deleteProjects`,
        method: 'POST',
        body: { deleteProjectIds, isPremiumUser, userWorkspaceId },
      })
    }),
    /**
     * SelectWorkspaceIndex 관리하기 위함 
     */
    getSelectedWorkspaceId: build.query({
      query: () => `/selectedWorkspaceId`,
      transformResponse: (res: RestApiResponse<GetSelectedWorkspaceIdRes>) => {
        if(!res.success || !res.data) {
          throw new Error(`Failed to get selectedWorkspaceId`);
        }
        return res.data;
      },
      providesTags: ['selectedWorkspaceId']
    }),
    updateSelectedWorkspaceId: build.mutation({
      query: ({
        selectedWorkspaceId = '',
      }: {
        selectedWorkspaceId: string,
      }) => ({
        url: `/selectedWorkspaceId`,
        method: 'POST',
        body: { selectedWorkspaceId },
      }),
      transformResponse: (res: RestApiResponse<void>) => {
        if(!res.success) {
          throw new Error(`Failed to update selectedWorkspaceId`);
        }
        return res.data;
      },
      invalidatesTags:  () => ['selectedWorkspaceId'],
    }),
  })
});

export const { 
  useGetStartTemplateQuery, 
  useGetTemplateQuery, 
  useDeleteTemplateMutation,
  useGetTemplatePostMutation,
  useGetTemplateProjectPostMutation,
  useGetTemplateParentProjectPostMutation,
  useGetMyProjectQuery, 
  useGetFolderQuery, 
  useFolderCreateAndUpdateMutation,
  useGetFolderInfoMutation,
  useMoveProjectToFolderMutation,
  useDuplicateProjectMutation, 
  useDeleteProjectMutation, 
  useGetProjectInfoQuery, 
  useExportHistoryQuery,
  useEditWebLinkTargetUrlMutation,
  useVideoSetQuery,
  useGetAvatarsQuery,
  useMakePPTProjectMutation,
  useSetCreateChatGPTLogMutation,
  useUploadAutomationMutation,
  useUploadPublicAutomationMutation,
  useExportTemplateMutation,
  useDeployTemplateMutation,
  useGetUserInfoMutation,
  useGetAccountUserInfoQuery,
  useCreateBaseInfoMutation,
  useGetDashboardQuickTemplateQuery,
  useFetchDashboardProjectQuery,
  useLazyFetchDashboardProjectQuery,
  useFetchTotalProjectsQuery,
  useLazyFetchTotalProjectsQuery,
  useFetchFolderProjectsQuery,
  useFetchFolderInfoListQuery,
  // useGetDashboardInfiniteProjectQuery,
  // useGetFetchingDashboardInfiniteProjectQuery,
  // useExportHistoryQuery,
  useFetchDashboardTemplateQuery,
  useLazyFetchDashboardTemplateQuery,
  useFetchDashboardTemplateWithIdQuery,
  useLazyFetchDashboardTemplateWithIdQuery,
  useFetchProjectHistoryQuery,
  useFetchProjectEditInfoQuery,
  useLazyFetchProjectEditInfoQuery,
  util: { getRunningQueriesThunk },
  useGetMyTemplateQuery,
  useGetFolderThumbnailsQuery,
  useGetDashboardProjectJobIdsQuery,
  useUpdateTemplateMutation,
  useGetWorkspaceAndFolderListQuery,
  useMoveProjectMutation,
  useDeleteProjectsMutation,
  useGetSelectedWorkspaceIdQuery,
  useUpdateSelectedWorkspaceIdMutation,
} = dashboardApi;
export default dashboardApi;

export const { getAvatars, getAccountUserInfo } = dashboardApi.endpoints;