import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RestApiResponse } from '@/types/common.api'
import { AiVoiceGeneratorGenerateState } from '@/constants/tools/ai-voice-generator'
import { VoiceCloneAsset } from '@/types/models'
import { Gender } from '@/constants/gender'

type AiVoiceGeneratorGetJobResponse = {
  script: string
  voice: string
  generatedVoice: {
    url: string
  }
  generateState: AiVoiceGeneratorGenerateState
  projectId?: string
  error?: string
}

type AiVoiceGeneratorCreateJobResponse = {
  jobId: string
  generatedVoice: {
    url: string
  }
}

type AiVoiceGeneratorCreateJobParams = {
  script: string
  voice: {
    modelId: string
    voiceId: string
    language: string
    gender: Gender
    isOriginalVoice: boolean
    type: string
  }
  generatedVoiceUrl: string
}

type AiVoiceGeneratorUpdateStateJobResponse = void

type AiVoiceGeneratorUpdateStateJobParams = {
  jobId: string
  state: AiVoiceGeneratorGenerateState
  projectId?: string
  error?: string
}

type AiVoiceGeneratorGetMyVoicesResponse = VoiceCloneAsset[]

type AiVoiceGeneratorExportVoiceParams = {
  script: string
  voice: {
    modelId: string
    voiceId: string
    language: string
    gender: Gender
    isOriginalVoice: boolean
    type: string
  }
  detectedLanguage: string
}

type AiVoiceGeneratorExportVoiceResponse = {
  backendTaskId: string
}

type AiVoiceGeneratorGetExportingVoiceParams = {
  backendTaskId: string
}
type AiVoiceGeneratorGetExportingVoiceResponse = {
  state: 'error' | 'queue' | 'process' | 'complete'
  progress?: number
  audio?: {
    url: string
    duration: number
  }
}

export const aiVoiceGeneratorApi = createApi({
  reducerPath: 'tools/aiVoiceGenerator',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/tools/ai-voice-generator',
  }),
  endpoints: (build) => ({
    getJob: build.query<AiVoiceGeneratorGetJobResponse, string>({
      query: (jobId) => `/jobs/${jobId}`,
      transformResponse: (
        res: RestApiResponse<AiVoiceGeneratorGetJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch AI voice job')
        }

        return res.data
      },
    }),

    createJob: build.mutation<
      AiVoiceGeneratorCreateJobResponse,
      AiVoiceGeneratorCreateJobParams
    >({
      query: ({ script, voice, generatedVoiceUrl }) => ({
        url: '/jobs',
        method: 'POST',
        body: {
          script,
          voice,
          generatedVoiceUrl,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiVoiceGeneratorCreateJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to create AI voice job')
        }

        return res.data
      },
    }),

    updateStateJob: build.mutation<
      AiVoiceGeneratorUpdateStateJobResponse,
      AiVoiceGeneratorUpdateStateJobParams
    >({
      query: ({ jobId, state, projectId, error }) => ({
        url: `/jobs/${jobId}`,
        method: 'PUT',
        body: {
          state,
          projectId,
          error,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiVoiceGeneratorUpdateStateJobResponse>,
      ) => {
        if (!res.success) {
          throw new Error('Failed to update AI voice job')
        }

        return res.data
      },
    }),

    getMyVoices: build.query<AiVoiceGeneratorGetMyVoicesResponse, void>({
      query: () => '/my-voices',
      transformResponse: (
        res: RestApiResponse<AiVoiceGeneratorGetMyVoicesResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch my voices')
        }

        return res.data
      },
    }),

    exportVoice: build.mutation<
      AiVoiceGeneratorExportVoiceResponse,
      AiVoiceGeneratorExportVoiceParams
    >({
      query: ({ script, voice, detectedLanguage }) => ({
        url: '/voices/export',
        method: 'POST',
        body: {
          script,
          voice,
          detectedLanguage,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiVoiceGeneratorExportVoiceResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to export AI voice')
        }

        return res.data
      },
    }),

    getExportingVoice: build.query<
      AiVoiceGeneratorGetExportingVoiceResponse,
      AiVoiceGeneratorGetExportingVoiceParams
    >({
      query: ({ backendTaskId }) => `/voices/${backendTaskId}`,
      transformResponse: (
        res: RestApiResponse<AiVoiceGeneratorGetExportingVoiceResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch exporting AI voice')
        }

        return res.data
      },
    }),
  }),
})
