export const BUCKET_NAME = "studio-v3-templates";

export const DEFAULT_TITLE = "Lorem Ipsum";

export const DEFAULT_PARAGRAPH =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

export const InflowTypes = [
  {
    label: "Twitter",
    value: "Twitter",
  },
  {
    label: "Youtube",
    value: "Youtube",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
  {
    label: "TikTok",
    value: "TikTok",
  },
  {
    label: "Facebook",
    value: "Facebook",
  },
  {
    label: "Instagram",
    value: "Instagram",
  },
  {
    label: "Google",
    value: "Google",
  },
  {
    label: "Friend, Coworker",
    value: "friend",
  },
  {
    label: "Blogs, Articles",
    value: "blog",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const UseCases = [
  {
    label: "Education & Training",
    value: "education",
  },
  {
    label: "News",
    value: "news",
  },
  {
    label: "Business",
    value: "business",
  },
  {
    label: "Commerce",
    value: "shopping",
  },
  {
    value: "social",
    label: "Youtube & Tiktok",
  },
  {
    label: "Marketing",
    value: "sales",
  },
  // {
  //   label: "ChatGPT",
  //   value: "chatgpt",
  // },

  {
    label: "All Use Cases",
    value: "all",
  },
];

export const VoiceCloneProvider = [
  "elevenlabs",
  "douyin",
  "volcengine",
];

export const MyAvatarTypes: (string | void)[] = [
  "customAvatar",
  "dreamAvatar",
  "generateAvatar",
];

export const SupportedLanguages = [
  "en",
  "ko",
  "zh",
  "ja",
  "es",
  "ru",
  "fr",
  "pt",
  "de",
  "it",
  "ar",
];

export const PAGE_TYPE = {
  SCREEN_RECORDER: "screen_recorder",
  VIDEO_HIGLIGHT: "video_highlight",
  VIDEO_MESSAGE: "video_message",
  SERIES_VIDEOS: "series_videos",
  BULK_SYNTHESIS: "bulk_synthesis",
  CHROMAKEY_VIDEO: "chromakey_video",
  PHOTO_AVATAR: "photo_avatar",
}
