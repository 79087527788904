import { runtimeEnv } from "@/lib/runtime-utils";
import { RestApiResponse } from "@/types/common.api";
import { GetUserInfoType } from "@/types/type";
import {
  createApi,
  fetchBaseQuery,
  defaultSerializeQueryArgs,
} from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import type { FetchArgs } from "@reduxjs/toolkit/query/react";
import type { QueryArgs } from "@/types/common.api";

export const userApi = createApi({
  reducerPath: "user_api",
  refetchOnFocus: false,
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/user`,
  }),
  tagTypes: ["user"],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getCredits: builder.query({
      query: () => `/credits`,
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return 0;
        }
        return res.data;
      },
    }),
    getSubscriptions: builder.query({
      query: () => "/subscriptions",
      transformResponse: async (
        res: RestApiResponse<GetSubscriptionsResponseType>
      ) => {
        if (!res.success || !res.data) {
          return "none";
        }
        return res.data;
      },
    }),
    getUserInfo: builder.query<GetUserInfoType | null, (QueryArgs | void)>({
      // query: () => `/`,
      query: (args?: QueryArgs) => {
        const query: FetchArgs = {
          url: "/",
          method: "GET",
        };
        if (args?.headers) {
          query.headers = args.headers;
        }
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition
        })
      },
      providesTags: ["user"],
      transformResponse: async (res: RestApiResponse<GetUserInfoType | void>) => {
        if (!res.success || !res.data) {
          return null;
        }
        return res.data;
      },
    }),
    postUserInfo: builder.mutation<RestApiResponse<any>, ProjectCreateGPTParam>(
      {
        query: (body) => ({
          url: "/",
          method: "POST",
          body,
        }),
        invalidatesTags: [{ type: "user" }],
      }
    ),
    unSubscribeUser: builder.mutation({
      query: ({ emailSubscribe, userId }) => ({
        url: "/emailSubscribe",
        method: "POST",
        body: { emailSubscribe, userId },
      }),
      invalidatesTags: [{ type: "user" }],
    }),
    postG2AccessKey: builder.mutation({
      query: (body) => ({
        url: "/g2AccessKey",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "user" }],
    }),
    updateCredit: builder.mutation({
      query: (body) => ({
        url: "/credit",
        method: "POST",
        body,
      }),
    }),
    getVideoCount: builder.query({
      query: () => `/videoCount`,
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return "0";
        }
        return res.data;
      },
    }),
    postVideoCount: builder.mutation(
      {
        query: (body) => ({
          url: "/videoCount",
          method: "POST",
          body,
        }),
      }
    ),

  }),
});
export const {
  useGetCreditsQuery,
  useGetSubscriptionsQuery,
  useLazyGetSubscriptionsQuery,
  useGetUserInfoQuery,
  usePostUserInfoMutation,
  useUnSubscribeUserMutation,
  usePostG2AccessKeyMutation,
  useUpdateCreditMutation,
  useGetVideoCountQuery,
  useLazyGetVideoCountQuery,
  usePostVideoCountMutation,
  util: { getRunningQueriesThunk },
} = userApi;

export const {
  getUserInfo,
} = userApi.endpoints;

export default userApi;
