/**
 * 상수를 정의한다.
 */

export const VIDEO_WIDTH = 1920;
export const VIDEO_HEIGHT = 1080;

/**
 * 스튜디오 서버 에러 코드
 */
export const ERR_CODE_9999 = "9999";
export const ERR_CODE_1100 = "1100";
export const ERR_CODE_1101 = "1101";
export const ERR_CODE_1102 = "1102";
export const ERR_CODE_1103 = "1103";
export const ERR_CODE_2000 = "2000";
export const ERR_CODE_2001 = "2001";
export const ERR_CODE_2002 = "2002";
export const ERR_CODE_2003 = "2003";
export const ERR_CODE_2004 = "2004";
export const ERR_CODE_3000 = "3000";
export const ERR_CODE_3001 = "3001";
export const ERR_CODE_3002 = "3002";
export const ERR_CODE_3010 = "3010";
export const ERR_CODE_4000 = "4000";
export const ERR_CODE_4001 = "4001";
export const ERR_CODE_4002 = "4002";
export const ERR_CODE_4003 = "4003";
export const ERR_CODE_4004 = "4004";
export const ERR_CODE_5000 = "5000";
export const ERR_CODE_6000 = "6000";

export const ERR_CODE_810 = "810";
export const ERR_CODE_811 = "811";
export const ERR_CODE_820 = "820";
export const ERR_CODE_830 = "830";
export const ERR_CODE_840 = "840";

/*
  자동화 서버 에러 코드
 */
export const ERR_CODE_900 = "900"; // GPT 관련 에러
export const ERR_CODE_910 = "910"; // 상품 이미지 스크래핑 에러
export const ERR_CODE_920 = "920"; // URL 스크래핑 에러
export const ERR_CODE_930 = "930"; // OCR 에러
export const ERR_CODE_940 = "940"; // 미디어 검색 에러
export const ERR_CODE_941 = "941"; // 미디어 생성 에러
export const ERR_CODE_950 = "950"; // 번역 에러
export const ERR_CODE_995 = "995"; // 알 수 없는 에러
/*
  자동화 서버 에러 코드 END
 */

export const ERR_CODE_1000 = "1000";
export const ERR_CODE_4007 = "4007";

export const ERR_CODE_9997 = "9997";
export const ERR_CODE_9996 = "9996";
export const ERR_CODE_9995 = "9995";
export const ERR_CODE_4010 = "4010";
export const ERR_CODE_4011 = "4011";

/*
 * 스크린 레코딩 에러 코드
 */

export const ERR_CODE_SR100 = "SR100"; // getServerSideProps 계정 관련 오류
export const ERR_CODE_SR101 = "SR101"; // getServerSideProps 내보내기 공통 오류
export const ERR_CODE_SR102 = "SR102"; // getServerSideProps 내보내기 쿠키 타입 오류
export const ERR_CODE_SR103 = "SR103"; // getServerSideProps 내보내기 쿠키 파싱 오류
export const ERR_CODE_SR104 = "SR104"; // getServerSideProps 내보내기 워크스페이스 오류
export const ERR_CODE_SR105 = "SR105"; // getServerSideProps 내보내기 요청 오류
export const ERR_CODE_SR106 = "SR106"; // getServerSideProps jobId 파라메터 오류
export const ERR_CODE_SR107 = "SR107"; // getServerSideProps jobId 권한 오류
export const ERR_CODE_SR108 = "SR108"; // getServerSideProps jobId 공통 오류
export const ERR_CODE_SR109 = "SR109"; // getServerSideProps 삭제된 작업
export const ERR_CODE_SR200 = "SR200"; // api - job/[...job] 계정 오류
export const ERR_CODE_SR201 = "SR201"; // api - job/[...job] DB 오류
export const ERR_CODE_SR202 = "SR202"; // api - job/[...job] job 조회 오류
export const ERR_CODE_SR203 = "SR203"; // api - job/[...job] job 권한 오류
export const ERR_CODE_SR204 = "SR204"; // api - job/[...job] 템플릿 조회 오류
export const ERR_CODE_SR205 = "SR205"; // api - job/[...job] 모델 조회 오류
export const ERR_CODE_SR206 = "SR206"; // api - job/[...job] 내보내기 오류
export const ERR_CODE_SR207 = "SR207"; // api - job/[...job] 기존 프로젝트 조회 오류
export const ERR_CODE_SR208 = "SR208"; // api - job/[...job] 기존 프로젝트 계정 오류
export const ERR_CODE_SR209 = "SR209"; // api - job/[...job] 워크스페이스 아이디 오류
export const ERR_CODE_SR300 = "SR300"; // api - file 계정 오류
export const ERR_CODE_SR301 = "SR301"; // api - file FormData 오류
export const ERR_CODE_SR302 = "SR302"; // api - file s3 오류
export const ERR_CODE_SR303 = "SR303"; // api - file DB 오류
export const ERR_CODE_SR304 = "SR304"; // api - file readFileSync 오류
export const ERR_CODE_SR305 = "SR305"; // api - file sharp 오류
export const ERR_CODE_SR400 = "SR400"; // api - background 계정 오류
export const ERR_CODE_SR401 = "SR401"; // api - background s3 오류
export const ERR_CODE_SR402 = "SR402"; // api - background DB 오류
export const ERR_CODE_SR403 = "SR403"; // api - background axios 오류
export const ERR_CODE_SR500 = "SR500"; // component - Editing/PreviewPlayer jobId 오류
export const ERR_CODE_SR501 = "SR501"; // component - Editing/PreviewPlayer 워크스페이스 오류
export const ERR_CODE_SR502 = "SR502"; // component - Editing/PreviewPlayer 내보내기 요청 오류
export const ERR_CODE_SR503 = "SR503"; // component - Editing/PreviewPlayer 공통 오류
export const ERR_CODE_SR504 = "SR504"; // component - Editing/PreviewPlayer 내보내기 권한 조회 오류
export const ERR_CODE_SR600 = "SR600"; // component - Recording ffmpeg 파일 타입 에러
export const ERR_CODE_SR601 = "SR601"; // component - Recording recentJob 엘레멘트 로딩 오류
export const ERR_CODE_SR602 = "SR602"; // component - Recording stopRecording jobId 오류
export const ERR_CODE_SR603 = "SR603"; // component - Recording stopRecording 필수 파일 누락 오류
export const ERR_CODE_SR604 = "SR604"; // component - Recording stopRecording job 섬네일 생성 오류
export const ERR_CODE_SR605 = "SR605"; // component - Recording stopRecording 업로드 오류
export const ERR_CODE_SR700 = "SR700"; // component - index job 이동 getJob 오류
export const ERR_CODE_SR701 = "SR701"; // component - index job 이동 initEditing 오류
export const ERR_CODE_SR702 = "SR702"; // component - index job 이동 공통 오류
export const ERR_CODE_SR800 = "SR800"; // api - custom-avatar 워크스페이스 아이디 오류

/* 
 * 미리듣기 오류 코드
 */

export const ERR_CODE_PA100 = "PA100"; // api - preview/audio POST 파라메터 오류
// export const ERR_CODE_PA101 = "PA101"; // api - preview/audio POST 언어감지 오류
export const ERR_CODE_PA102 = "PA102"; // api - preview/audio POST 백엔드 요청 오류
export const ERR_CODE_PA103 = "PA103"; // api - preview/audio GET 파라메터 오류
export const ERR_CODE_PA104 = "PA104"; // api - preview/audio GET 타임아웃
export const ERR_CODE_PA105 = "PA105"; // usePreviewAudio 요청 기타 오류

export const ERR_MSG: Record<string, { ko: string; en: string; zh: string }> = {
  [ERR_CODE_9999]: {
    ko: "알수 없는 오류가 발생하였습니다.",
    en: "Unknown Err.",
    zh: "Unknown Err.",
  },
  [ERR_CODE_1100]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해주세요. (code: 1100)",
    en: "Synthesizing failed. Please contact customer support. (code: 1100)",
    zh: "Synthesizing failed. Please contact customer support .(code: 1100)",
  },
  [ERR_CODE_1101]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요.(code : 1101)",
    en: "A problem occurred. Please contact customer service.  (code: 1101)",
    zh: "A problem occurred. Please contact customer service.  (code: 1101)",
  },
  [ERR_CODE_1102]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요.(code : 1102)",
    en: "A problem occurred. Please contact customer service.  (code: 1102)",
    zh: "A problem occurred. Please contact customer service.  (code: 1102)",
  },
  [ERR_CODE_1103]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요.(code : 1103)",
    en: "A problem occurred. Please contact customer service.  (code: 1103)",
    zh: "A problem occurred. Please contact customer service.  (code: 1103)",
  },
  [ERR_CODE_2000]: {
    ko: "사용자 정보를 확인할 수 없습니다. 재 접속 후 다시 이용해 주세요. (code: 2000)",
    en: "User not found. Please contact customer support. (code:2000)",
    zh: "User not found. Please contact customer support. (code:2000)",
  },
  [ERR_CODE_2001]: {
    ko: "유명인 사진은 업로드할 수 없습니다. 다른 파일로 시도해 주세요. 유명인이 아닌 경우, 고객센터로 문의해 주세요.(Code: 2001). ",
    en: "Celebrity photo upload detected. Please try a different file upload. If you are not, please contact customer service. (code: 2001)",
    zh: "Celebrity photo upload detected. Please try a different file upload. If you are not, please contact customer service. (code: 2001)",
  },
  [ERR_CODE_2002]: {
    ko: "로그인 후 사용가능 합니다. 로그인 후 이용하시기 바랍니다.",
    en: "User not found. Please contact customer support. (code: 2002)",
    zh: "User not found. Please contact customer support. (code: 2002)",
  },
  [ERR_CODE_2003]: {
    ko: "사용자 정보를 확인할 수 없습니다. 재 접속 후 다시 이용해 주세요. (code: 2003)",
    en: "User not found. Please contact customer support. (code: 2003)",
    zh: "User not found. Please contact customer support. (code: 2003)",
  },
  [ERR_CODE_2004]: {
    ko: "권한이 부족하여 이용이 불가합니다. 관리자에게 문의하시기 바랍니다. (code: 2004)",
    en: "You have no permission to access. (code: 2004)",
    zh: "You have no permission to access. (code: 2004)",
  },
  [ERR_CODE_3000]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요.(code: 3000)",
    en: "A problem occurred. Please contact customer service. (code: 3000)",
    zh: "A problem occurred. Please contact customer service. (code: 3000)",
  },
  [ERR_CODE_3001]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요.(code: 3001)",
    en: "A problem occurred. Please contact customer service. (code: 3001)",
    zh: "A problem occurred. Please contact customer service. (code: 3001)",
  },
  [ERR_CODE_3002]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요.(code: 3002)",
    en: "A problem occurred. Please contact customer service. (code: 3002)",
    zh: "A problem occurred. Please contact customer service. (code: 3002)",
  },
  [ERR_CODE_3010]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요.(code: 3010)",
    en: "A problem occurred. Please contact customer service. (code: 3010)",
    zh: "A problem occurred. Please contact customer service. (code: 3010)",
  },
  [ERR_CODE_4000]: {
    ko: "서비스 이용에 필요한 크레딧이 부족합니다.(code : 4000)",
    en: "Your credit is not sufficient. (code : 4000)",
    zh: "Your credit is not sufficient. (code : 4000)",
  },
  [ERR_CODE_4001]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요.(code : 4001)",
    en: "A problem occurred. Please contact customer service. (code : 4001)",
    zh: "A problem occurred. Please contact customer service. (code : 4001)",
  },
  [ERR_CODE_4002]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요.(code : 4002)",
    en: "A problem occurred. Please contact customer service. (code : 4002)",
    zh: "A problem occurred. Please contact customer service. (code : 4002)",
  },
  [ERR_CODE_4003]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요.(code : 4003)",
    en: "A problem occurred. Please contact customer service. (code : 4003)",
    zh: "A problem occurred. Please contact customer service. (code : 4003)",
  },
  [ERR_CODE_4004]: {
    ko: "구독 권한이 부족합니다. (code : 4004)",
    en: "Your plan is not enough(code : 4004)",
    zh: "Your plan is not enough(code : 4004)",
  },
  [ERR_CODE_4007]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해주세요. (code: 4007)",
    en: "Synthesizing timed out. Please contact customer service. (code: 4007)",
    zh: "Synthesizing timed out. Please contact customer service. (code: 4007)",
  },
  [ERR_CODE_5000]: {
    ko: "외부 연동에 실패하였습니다.",
    en: "can't downgrade subscription.",
    zh: "can't downgrade subscription.",
  },
  [ERR_CODE_6000]: {
    ko: "비디오 합성에 실패하였습니다.",
    en: "Video synthesis failed.",
    zh: "Video synthesis failed",
  },
  [ERR_CODE_810]: {
    ko: "음성합성에 실패하였습니다. 잠시 후 다시 시도해 주세요.(code: 810)",
    en: "Voice synthesis failed. Please duplicate and try again. (code: 810)",
    zh: "Voice synthesis failed. Please duplicate and try again. (code: 810)",
  },
  [ERR_CODE_811]: {
    ko: "자막 생성에 실패하였습니다. 잠시 후 다시 시도해 주세요.(code: 811)",
    en: "Subtitle creation failed. Please try again. (code: 811)",
    zh: "Subtitle creation failed. Please try again. (code: 811)",
  },
  [ERR_CODE_820]: {
    ko: "썸네일 생성에 실패하였습니다. 잠시 후 다시 시도해 주세요.(code: 820)",
    en: "Thumbnail creation failed. Please try again. (code: 820)",
    zh: "Thumbnail creation failed. Please try again. (code: 820)",
  },
  [ERR_CODE_830]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해주세요!(code: 830)",
    en: "Could not synthesize file. Please contact customer service. (code: 830)",
    zh: "Could not synthesize file. Please contact customer service. (code: 830)",
  },
  [ERR_CODE_840]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 빠른 시일 내 조치하도록 하겠습니다. 문제가 지속되면 고객센터로 문의해주세요!(code: 840)",
    en: "Could not synthesize file. Please contact customer service. (code: 840)",
    zh: "Could not synthesize file. Please contact customer service. (code: 840)",
  },
  [ERR_CODE_900]: {
    ko: "텍스트 생성에 실패하였습니다.  잠시 후 다시 잠시 후 다시 시도해  주세요. ",
    en: "Text creation failed. Please try again with a different script. ",
    zh: "Text creation failed. Please try again with a different script. ",
  },
  [ERR_CODE_910]: {
    ko: "입력하신 url에서 이미지를 가져올 수 없습니다. 잠시 후 다시 시도해  주세요.(code: 910)",
    en: "The image cannot be retrieved from the URL. Please try again with a different URL. (code: 910)",
    zh: "The image cannot be retrieved from the URL. Please try again with a different URL. (code: 910)",
  },
  [ERR_CODE_920]: {
    ko: "입력하신 url에서 데이터를 가져오는 데 실패하였습니다. 잠시 후 다시 시도해  주세요.(code: 920)",
    en: "Failed to retrieved data from the entered URL. Please try again with a different URL. (code: 920)",
    zh: "Failed to retrieved data from the entered URL. Please try again with a different URL. (code: 920)",
  },
  [ERR_CODE_930]: {
    ko: "문서에서 데이터를 가져오는 데 실패하였습니다.  잠시 후 다시 시도해  주세요. (code: 930)",
    en: "Failed to retrieved data from the uploaded document. Please try again with a different upload. (code: 930)",
    zh: "Failed to retrieved data from the uploaded document. Please try again with a different upload. (code: 930)",
  },
  [ERR_CODE_940]: {
    ko: "이미지 리소스를 가져올 수 없습니다.(code: 940)",
    en: "Failed to retrieved data from the selected image. Please try again with a different image. (code: 940)",
    zh: "Failed to retrieved data from the selected image. Please try again with a different image. (code: 940)",
  },
  [ERR_CODE_941]: {
    ko: "미디어 생성에 실패하였습니다. (code: 940)",
    en: "Media creation failed. Please try again. (code: 941)",
    zh: "Media creation failed. Please try again. (code: 941)",
  },
  [ERR_CODE_950]: {
    ko: "번역 작업에 문제가 발생하였습니다. 잠시 후 다시 시도해  주세요. (code: 950)",
    en: "A problem occurred with the translation. Please try again later. (code: 950)",
    zh: "A problem occurred with the translation. Please try again later. (code: 950)",
  },
  [ERR_CODE_995]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다.  문제가 지속되면 고객센터로 문의해 주세요. (code: 995)",
    en: "Could not synthesize file. Please contact customer service. (code: 995)",
    zh: "Could not synthesize file. Please contact customer service. (code: 995)",
  },

  [ERR_CODE_9997]: {
    ko: "아바타 생성에 실패하였습니다. 파일을 변경 후 다시 시도해 주세요. (code: 9997)",
    en: "Could not synthesize file. Please contact customer service. (code: 840) (code: 9997)",
    zh: "Could not synthesize file. Please contact customer service. (code: 840) (code: 9997)",
  },
  [ERR_CODE_9996]: {
    ko: "얼굴 인식에 실패하여 커스텀 아바타를 생성할 수 없습니다. 재촬영 혹은 파일을 변경 후 다시 시도해 주세요.(code: 9996)",
    en: "A problem occurred. Please contact customer service. (code : 4003)",
    zh: "A problem occurred. Please contact customer service. (code : 4003)",
  },
  [ERR_CODE_9995]: {
    ko: "서비스 이용량이 많아서 합성에 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요. (code: 9995)",
    en: "There was an error processing your speech. Please create a new avatar following the avatar creation guidelines. (code: 9995)",
    zh: "There was an error processing your speech. Please create a new avatar following the avatar creation guidelines. (code: 9995)",
  },
  [ERR_CODE_4010]: {
    ko: "경고: 업로드하려는 파일에는 유해하거나 불쾌감을 줄 수 있는 민감한 콘텐츠가 포함되어 있습니다. 업로드하기 전에 파일을 확인해 주세요. (code: 4010)",
    en: "WARNING: The file you are trying to upload contains sensitive content that may be harmful or offensive. Please correct. (code: 4010)",
    zh: "WARNING: The file you are trying to upload contains sensitive content that may be harmful or offensive. Please correct. (code: 4010)",
  },
  [ERR_CODE_4011]: {
    ko: "화면에서 유해한 문구가 감지되었습니다. 문구를 확인 후 수정해 주세요.(code: 4011)",
    en: "Inappropriate words are detected on the page. Please check the script and correct it. (code: 4011)",
    zh: "Inappropriate words are detected on the page. Please check the script and correct it. (code: 4011)",
  },
  [ERR_CODE_SR100]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR100)",
    en: "A problem occurred. Please contact customer service. (code: SR100)",
    zh: "A problem occurred. Please contact customer service. (code: SR100)",
  },
  [ERR_CODE_SR101]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR101)",
    en: "A problem occurred. Please contact customer service. (code: SR101)",
    zh: "A problem occurred. Please contact customer service. (code: SR101)",
  },
  [ERR_CODE_SR102]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR102)",
    en: "A problem occurred. Please contact customer service. (code: SR102)",
    zh: "A problem occurred. Please contact customer service. (code: SR102)",
  },
  [ERR_CODE_SR103]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR103)",
    en: "A problem occurred. Please contact customer service. (code: SR103)",
    zh: "A problem occurred. Please contact customer service. (code: SR103)",
  },
  [ERR_CODE_SR104]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR104)",
    en: "A problem occurred. Please contact customer service. (code: SR104)",
    zh: "A problem occurred. Please contact customer service. (code: SR104)",
  },
  [ERR_CODE_SR105]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR105)",
    en: "A problem occurred. Please contact customer service. (code: SR105)",
    zh: "A problem occurred. Please contact customer service. (code: SR105)",
  },
  [ERR_CODE_SR106]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR106)",
    en: "A problem occurred. Please contact customer service. (code: SR106)",
    zh: "A problem occurred. Please contact customer service. (code: SR106)",
  },
  [ERR_CODE_SR107]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR107)",
    en: "A problem occurred. Please contact customer service. (code: SR107)",
    zh: "A problem occurred. Please contact customer service. (code: SR107)",
  },
  [ERR_CODE_SR108]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR108)",
    en: "A problem occurred. Please contact customer service. (code: SR108)",
    zh: "A problem occurred. Please contact customer service. (code: SR108)",
  },
  [ERR_CODE_SR109]: {
    ko: "삭제된 작업입니다. (code: SR109)",
    en: "The job has been deleted. (code: SR109)",
    zh: "The job has been deleted. (code: SR109)",
  },
  [ERR_CODE_SR200]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR200)",
    en: "A problem occurred. Please contact customer service. (code: SR200)",
    zh: "A problem occurred. Please contact customer service. (code: SR200)",
  },
  [ERR_CODE_SR201]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR201)",
    en: "A problem occurred. Please contact customer service. (code: SR201)",
    zh: "A problem occurred. Please contact customer service. (code: SR201)",
  },
  [ERR_CODE_SR202]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR202)",
    en: "A problem occurred. Please contact customer service. (code: SR202)",
    zh: "A problem occurred. Please contact customer service. (code: SR202)",
  },
  [ERR_CODE_SR203]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR203)",
    en: "A problem occurred. Please contact customer service. (code: SR203)",
    zh: "A problem occurred. Please contact customer service. (code: SR203)",
  },
  [ERR_CODE_SR204]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR204)",
    en: "A problem occurred. Please contact customer service. (code: SR204)",
    zh: "A problem occurred. Please contact customer service. (code: SR204)",
  },
  [ERR_CODE_SR205]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR205)",
    en: "A problem occurred. Please contact customer service. (code: SR205)",
    zh: "A problem occurred. Please contact customer service. (code: SR205)",
  },
  [ERR_CODE_SR206]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR206)",
    en: "A problem occurred. Please contact customer service. (code: SR206)",
    zh: "A problem occurred. Please contact customer service. (code: SR206)",
  },
  [ERR_CODE_SR207]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR207)",
    en: "A problem occurred. Please contact customer service. (code: SR207)",
    zh: "A problem occurred. Please contact customer service. (code: SR207)",
  },
  [ERR_CODE_SR208]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR208)",
    en: "A problem occurred. Please contact customer service. (code: SR208)",
    zh: "A problem occurred. Please contact customer service. (code: SR208)",
  },
  [ERR_CODE_SR209]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR209)",
    en: "A problem occurred. Please contact customer service. (code: SR209)",
    zh: "A problem occurred. Please contact customer service. (code: SR209)",
  },
  [ERR_CODE_SR300]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR300)",
    en: "A problem occurred. Please contact customer service. (code: SR300)",
    zh: "A problem occurred. Please contact customer service. (code: SR300)",
  },
  [ERR_CODE_SR301]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR301)",
    en: "A problem occurred. Please contact customer service. (code: SR301)",
    zh: "A problem occurred. Please contact customer service. (code: SR301)",
  },
  [ERR_CODE_SR302]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR302)",
    en: "A problem occurred. Please contact customer service. (code: SR302)",
    zh: "A problem occurred. Please contact customer service. (code: SR302)",
  },
  [ERR_CODE_SR303]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR303)",
    en: "A problem occurred. Please contact customer service. (code: SR303)",
    zh: "A problem occurred. Please contact customer service. (code: SR303)",
  },
  [ERR_CODE_SR304]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR304)",
    en: "A problem occurred. Please contact customer service. (code: SR304)",
    zh: "A problem occurred. Please contact customer service. (code: SR304)",
  },
  [ERR_CODE_SR305]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR305)",
    en: "A problem occurred. Please contact customer service. (code: SR305)",
    zh: "A problem occurred. Please contact customer service. (code: SR305)",
  },
  [ERR_CODE_SR400]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR400)",
    en: "A problem occurred. Please contact customer service. (code: SR400)",
    zh: "A problem occurred. Please contact customer service. (code: SR400)",
  },
  [ERR_CODE_SR401]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR401)",
    en: "A problem occurred. Please contact customer service. (code: SR401)",
    zh: "A problem occurred. Please contact customer service. (code: SR401)",
  },
  [ERR_CODE_SR402]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR402)",
    en: "A problem occurred. Please contact customer service. (code: SR402)",
    zh: "A problem occurred. Please contact customer service. (code: SR402)",
  },
  [ERR_CODE_SR403]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR403)",
    en: "A problem occurred. Please contact customer service. (code: SR403)",
    zh: "A problem occurred. Please contact customer service. (code: SR403)",
  },
  [ERR_CODE_SR500]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR500)",
    en: "A problem occurred. Please contact customer service. (code: SR500)",
    zh: "A problem occurred. Please contact customer service. (code: SR500)",
  },
  [ERR_CODE_SR501]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR501)",
    en: "A problem occurred. Please contact customer service. (code: SR501)",
    zh: "A problem occurred. Please contact customer service. (code: SR501)",
  },
  [ERR_CODE_SR502]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR502)",
    en: "A problem occurred. Please contact customer service. (code: SR502)",
    zh: "A problem occurred. Please contact customer service. (code: SR502)",
  },
  [ERR_CODE_SR503]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR503)",
    en: "A problem occurred. Please contact customer service. (code: SR503)",
    zh: "A problem occurred. Please contact customer service. (code: SR503)",
  },
  [ERR_CODE_SR504]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR504)",
    en: "A problem occurred. Please contact customer service. (code: SR504)",
    zh: "A problem occurred. Please contact customer service. (code: SR504)",
  },
  [ERR_CODE_SR600]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR600)",
    en: "A problem occurred. Please contact customer service. (code: SR600)",
    zh: "A problem occurred. Please contact customer service. (code: SR600)",
  },
  [ERR_CODE_SR601]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR601)",
    en: "A problem occurred. Please contact customer service. (code: SR601)",
    zh: "A problem occurred. Please contact customer service. (code: SR601)",
  },
  [ERR_CODE_SR602]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR602)",
    en: "A problem occurred. Please contact customer service. (code: SR602)",
    zh: "A problem occurred. Please contact customer service. (code: SR602)",
  },
  [ERR_CODE_SR603]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR603)",
    en: "A problem occurred. Please contact customer service. (code: SR603)",
    zh: "A problem occurred. Please contact customer service. (code: SR603)",
  },
  [ERR_CODE_SR604]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR604)",
    en: "A problem occurred. Please contact customer service. (code: SR604)",
    zh: "A problem occurred. Please contact customer service. (code: SR604)",
  },
  [ERR_CODE_SR605]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR605)",
    en: "A problem occurred. Please contact customer service. (code: SR605)",
    zh: "A problem occurred. Please contact customer service. (code: SR605)",
  },
  [ERR_CODE_SR700]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR700)",
    en: "A problem occurred. Please contact customer service. (code: SR700)",
    zh: "A problem occurred. Please contact customer service. (code: SR700)",
  },
  [ERR_CODE_SR701]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR701)",
    en: "A problem occurred. Please contact customer service. (code: SR701)",
    zh: "A problem occurred. Please contact customer service. (code: SR701)",
  },
  [ERR_CODE_SR702]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR702)",
    en: "A problem occurred. Please contact customer service. (code: SR702)",
    zh: "A problem occurred. Please contact customer service. (code: SR702)",
  },
  [ERR_CODE_SR800]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요. (code: SR800)",
    en: "A problem occurred. Please contact customer service. (code: SR800)",
    zh: "A problem occurred. Please contact customer service. (code: SR800)",
  },
  [ERR_CODE_PA100]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요.",
    en: "A problem occurred. Please contact customer service.",
    zh: "A problem occurred. Please contact customer service.",
  },
  // [ERR_CODE_PA101]: {
  //   ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요.",
  //   en: "A problem occurred. Please contact customer service.",
  //   zh: "A problem occurred. Please contact customer service.",
  // },
  [ERR_CODE_PA102]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요.",
    en: "A problem occurred. Please contact customer service.",
    zh: "A problem occurred. Please contact customer service.",
  },
  [ERR_CODE_PA103]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요.",
    en: "A problem occurred. Please contact customer service.",
    zh: "A problem occurred. Please contact customer service.",
  },
  [ERR_CODE_PA104]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요.",
    en: "A problem occurred. Please contact customer service.",
    zh: "A problem occurred. Please contact customer service.",
  },
  [ERR_CODE_PA105]: {
    ko: "서비스 이용에 불편을 드려 죄송합니다. 문제가 지속되면 고객센터로 문의해 주세요.",
    en: "A problem occurred. Please contact customer service.",
    zh: "A problem occurred. Please contact customer service.",
  },
};
