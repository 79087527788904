/* eslint-disable no-await-in-loop */

globalThis.__SYNCHRONIZED_QUEUE__ = {};
globalThis.__SYNCHRONIZED_STARTED__ = {};

function isStarted(label:string) {
  return globalThis.__SYNCHRONIZED_STARTED__[label] === true;
}

function setStarted(label:string, isStated:boolean) {
  globalThis.__SYNCHRONIZED_STARTED__[label] = isStated;
}

function isEmptyQueue(label:string) {
  if (globalThis.__SYNCHRONIZED_QUEUE__[label] && globalThis.__SYNCHRONIZED_QUEUE__[label].length > 0) {
    return false;
  }
  return true;
}

function shiftQueue(label:string) {
  if (!globalThis.__SYNCHRONIZED_QUEUE__[label]) {
    globalThis.__SYNCHRONIZED_QUEUE__[label] = [];
  }
  
  if (globalThis.__SYNCHRONIZED_QUEUE__[label].length > 0) {
    return globalThis.__SYNCHRONIZED_QUEUE__[label].shift();
  }
  return null;
}

function addQueue(label:string, data: { args: any; resolve: (value: unknown) => void; reject: (reason?: any) => void; }) {
  if (!globalThis.__SYNCHRONIZED_QUEUE__[label]) {
    globalThis.__SYNCHRONIZED_QUEUE__[label] = [];
  }
  globalThis.__SYNCHRONIZED_QUEUE__[label].push(data);
}

const synchronized = (label:string, fun:any) => async (args: any) => {
  const startProcess = async () => {
    while (isStarted(label) === false && isEmptyQueue(label) === false) {
      setStarted(label, true);
      const myTern = shiftQueue(label);
      try {
        myTern.resolve(await fun(myTern.args));
      } catch (e) {
        console.error(e);
        myTern.reject(e);
      } finally {
        setStarted(label, false);
      }
    }
  }

  return new Promise((resolve, reject) => {
    try {
      addQueue(label, { args, resolve, reject });
      startProcess();
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export {
  synchronized
}
