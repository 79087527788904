import { runtimeEnv } from "@/lib/runtime-utils";
import {
  createApi,
  fetchBaseQuery,
  // defaultSerializeQueryArgs,
} from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import type { RestApiResponse } from "@/types/common.api";
import type {
  Job,
  BackgroundAsset,
  Model,
  CustomAvatar,
  TimelineItem,
  ExportType,
} from "@/types/screenRecorder";
import type { Scene } from "@/types/type";
// import type { FetchArgs } from "@reduxjs/toolkit/query/react";
// import type { QueryArgs } from "@/types/common.api";

export const screenRecorderApi = createApi({
  reducerPath: "tools/screenRecorderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/tools/screen-recorder`,
  }),
  tagTypes: ["DefaultBackgrounds"],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getJobs: builder.query<Job<string>[], void>({
      query: () => ({
        url: "/job",
        method: "GET",
      }),
      transformResponse: async (res: RestApiResponse<Job<string>[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    getJob: builder.mutation<Job<string> | null, string>({
      query: (body) => {
        return {
          url: `/job/${body}`,
          method: "GET",
        };
      },
      transformResponse: async (res: RestApiResponse<Job<string>>) => {
        if (!res.success) {
          return null;
        }
        return res.data;
      },
    }),
    createJob: builder.mutation<string | null, TimelineItem[]>({
      query: (body) => ({
        url: "/job",
        method: "POST",
        body,
      }),
      transformResponse: async (res: RestApiResponse<string>) => {
        if (!res.success) {
          return null;
        }
        return res.data;
      },
    }),
    updateJob: builder.mutation<
      boolean,
      Partial<Pick<Job<string>, "timeline" | "file" | "sts" | "state" | "_id">>
    >({
      query: (body) => {
        const { _id: jobId, ...rest } = body;

        return {
          url: `/job/${jobId}`,
          method: "PUT",
          body: rest,
        };
      },
      transformResponse: async (res: RestApiResponse<void>) => {
        if (!res.success) {
          return false;
        }
        return true;
      },
    }),
    uploadRecordings: builder.mutation<boolean, FormData>({
      query: (body) => ({
        url: "/file",
        method: "POST",
        body,
        formData: true,
      }),
      transformResponse: async (res: RestApiResponse<void>) => {
        if (!res.success) {
          return false;
        }
        return true;
      },
    }),
    getModels: builder.query<Model[], { locale?: string } | void>({
      query: (args) => {
        const query = {
          url: "/model",
          method: "GET",
        };

        if (args) {
          query.url = `${query.url}?${new URLSearchParams(args)}`;
        }

        return query;
      },
      transformResponse: async (res: RestApiResponse<Model[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    getCustomAvatars: builder.query<CustomAvatar[], { locale?: string } | void>({
      query: (args) => {
        const query = {
          url: "/custom-avatar",
          method: "GET",
        };

        if (args) {
          query.url = `${query.url}?${new URLSearchParams(args)}`;
        }

        return query;
      },
      transformResponse: async (res: RestApiResponse<CustomAvatar[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    searchBackgrounds: builder.query<
      (
        BackgroundAsset |
        { type: "button"; id: string } |
        { type: "loader"; id: string }
      )[],
      { q?: string } | void
    >({
      query: (args) => {
        const query = {
          url: "/background",
          method: "GET",
        };

        if (args) {
          query.url = `${query.url}?${new URLSearchParams(args)}`;
        }

        return query;
      },
      providesTags: ["DefaultBackgrounds"],
      transformResponse: async (
        res: RestApiResponse<BackgroundAsset[]>,
        meta,
        arg
      ) => {
        if (!res.success) {
          return [];
        }
        if (!arg?.q) {
          return [{ type: "button", id: "button-upload" }, ...res.data];
        }
        return res.data;
      },
    }),
    getBackground: builder.mutation<string | null, { image: BackgroundAsset }>({
      query: (body) => ({
        url: "/background",
        method: "POST",
        body,
      }),
      transformResponse: async (res: RestApiResponse<string>) => {
        if (!res.success) {
          return null;
        }
        return res.data;
      },
    }),
    uploadBackground: builder.mutation<BackgroundAsset | null, FormData>({
      query: (body) => ({
        url: "/file",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["DefaultBackgrounds"],
      transformResponse: async (res: RestApiResponse<BackgroundAsset>) => {
        if (!res.success) {
          return null;
        }
        return res.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          screenRecorderApi.util.updateQueryData("searchBackgrounds", {q: ""}, (draft) => {
            const foundIndex = draft.findIndex((item) => item.id === "button-upload");
            const insertIndex = foundIndex !== -1 ? foundIndex : 0;
            const removeAmount = foundIndex !== -1 ? 1 : 0;
            
            draft.splice(
              insertIndex,
              removeAmount,
              { type: "loader", id: `loader-${new Date().toString()}` }
            );
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    deleteBackground: builder.mutation<boolean, { backgroundId: string }>({
      query: (body) => ({
        url: "/background",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["DefaultBackgrounds"],
      transformResponse: async (res: RestApiResponse<void>) => {
        if (!res.success) {
          return false;
        }
        return true;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          screenRecorderApi.util.updateQueryData("searchBackgrounds", {q: ""}, (draft) => {
            const foundIndex = draft.findIndex((item) => item.id === args.backgroundId);
            const insertIndex = foundIndex !== -1 ? foundIndex : 0;
            const removeAmount = foundIndex !== -1 ? 1 : 0;
            
            draft.splice(insertIndex, removeAmount);
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    exportJob: builder.mutation<
      string | null,
      {
        jobId: string;
        exportType: ExportType;
        insertAt?: number;
        projectId?: string;
        fallbackUrl?: string;
      }
    >({
      query: (body) => {
        const { jobId, ...rest } = body;

        return {
          url: `/job/${jobId}`,
          method: "POST",
          body: rest,
        };
      },
      transformResponse: async (res: RestApiResponse<string | null>) => {
        if (!res.success) {
          return null;
        }
        return res.data;
      },
    }),
    createScenes: builder.mutation<
      Scene[],
      { jobId: string; }
    >({
      query: (body) => {
        const { jobId } = body;

        return {
          url: `/job/${jobId}`,
          method: "POST",
          body: { exportType: "scene" },
        };
      },
      transformResponse: async (res: RestApiResponse<Scene[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const {
  useGetJobsQuery,
  useGetJobMutation,
  useCreateJobMutation,
  useUpdateJobMutation,
  useUploadRecordingsMutation,
  useGetModelsQuery,
  useGetCustomAvatarsQuery,
  useSearchBackgroundsQuery,
  useGetBackgroundMutation,
  useUploadBackgroundMutation,
  useDeleteBackgroundMutation,
  useExportJobMutation,
  useCreateScenesMutation,
  // util: { getRunningQueriesThunk },
} = screenRecorderApi;

// export const {
//   getAiModels,
// } = screenRecorderApi.endpoints;
