import { sort } from "@/hooks/useFilterVoices";
import { runtimeEnv } from "@/lib/runtime-utils";
import { QueryArgs, RestApiResponse } from "@/types/common.api";
import {
  createApi,
  defaultSerializeQueryArgs,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export const modelApi = createApi({
  reducerPath: "modelApi",
  refetchOnFocus: false,
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/model`,
  }),
  tagTypes: ["Aimodel", "tts"],
  endpoints: (build) => ({
    getAiModel: build.query<any, any>({
      query: (params) => {
        return { url: `aimodel?${new URLSearchParams(params)}` };
      },
      providesTags: ["Aimodel"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getModelVoices: build.query({
      query: ({
        locale = "en",
        output = "list",
        sort: sortLanguage = "en",
        headers,
        workspaceId,
      }: QueryArgs & {
        locale?: string;
        output?: string;
        sort?: string;
        workspaceId?: string | string[]
      }) => {
        workspaceId = Array.isArray(workspaceId) ? workspaceId[0] : workspaceId;
        
        const query: FetchArgs = {
          url: `/voices?locale=${locale}&output=${output}&sort=${sortLanguage}`,
          params: {
            workspaceId,
          },
          method: "GET",
        };

        if (headers) {
          query.headers = headers;
        }
        return query;
      },
      providesTags: ["tts"],
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition,
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition,
        });
      },
      transformResponse: async (res: RestApiResponse<Tts[]>, _, args) => {
        if (!res.success || !res.data) {
          return [];
        }

        let result = res.data;

        // 딥브레인 ai / 보이스클론 tts 제외
        // result = result.filter(
        //   (voice: Tts) =>
        //     voice.type !== "deepbrainai" &&
        //     !VoiceCloneProvider.includes(voice.type)
        // );

        // 정렬 요청 시 정렬
        if (args?.sort) {
          const locale = args.sort;

          result = result
            .sort((a, b) => sort.locale(a, b, locale))
            .sort((a, b) => sort.language(a, b))
            .sort((a, b) => sort.englishPriority(a, b));
        }

        return result;
      },
    }),

    updateModelVoice: build.mutation<
      RestApiResponse<any>,
      { id: string; tags?: string[] }
    >({
      query: (body) => ({
        url: `/voices`,
        method: "PUT",
        body,
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const {
  useGetAiModelQuery,
  useGetModelVoicesQuery,
  useUpdateModelVoiceMutation,
  util: { getRunningQueriesThunk },
} = modelApi;

export const { getModelVoices } = modelApi.endpoints;
